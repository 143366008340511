import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { parse } from "query-string";
import _ from "lodash";
import toast from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { getPatientPastReport } from "../../store/actions/patientList";
import L_LabUpload from "../../components/LoadingEffectComponent/L_LabUpload";
import PastReportTable from "../../components/Practice/PastReportTable";
import { updatePatientReport } from "../../store/actions/patientReport";
import { useNavigate } from "react-router-dom";

const PastReport = ({ history }) => {

  // redux state variable

  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const loading = useSelector(state => state.patientList.isGettingPatientPastReport);
  const report = useSelector(state => state.patientList.patientPastReport);

  // redux actions

  const dispatch = useDispatch();
  const updatePatientReportAction = (data) => dispatch(updatePatientReport(data));
  const getPatientPastReportAction = (token, currentPracticeID, currentPractice, id) => dispatch(getPatientPastReport(token, currentPracticeID, currentPractice, id));

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const parsed = parse(window.location.search);
    if (_.isUndefined(parsed.patient_id)) {
      toast.error("Invalid URL");
      return navigate("/practice/patients");
    } else
      getPatientPastReportAction(
        token,
        currentPracticeID,
        currentPractice,
        parsed.patient_id
      );
    console.log("fetched data", report);
  }, []);

  return (
    <div className="container-fluid">
      <Helmet>
        <title>
          {i18n.language === "en_gb" ? "PureGenomics®" : "PureInsight™"} - Past Report
        </title>
      </Helmet>
      {loading ? (
        <L_LabUpload />
      ) : (
        <div className="container-xxl px-lg-0 px-1">
          <div className="pg-link my-4  mt-5" onClick={() => navigate(-1)}>
            <i className="fas fa-chevron-left"></i> {t('patient')}
          </div>
          <div className="pg-title my-3 mt-5">{t('REPORTS')}</div>
          {/* <div className="pg-text__bold">Patient Name</div> */}
          <div className="pg-text my-2" />
          <PastReportTable
            pastReport={report}
            t={t}
            updatePatientReport={updatePatientReportAction}
          />
        </div>
      )}
      <div className="my-5 py-5"></div>
    </div>
  );
};

export default PastReport;
