import React from "react";
import { useSelector } from 'react-redux'
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PgLogo from "../assets/pg_logo_1.svg";
import PiLogo from "../assets/PureInsight_Logo_KO_u2.svg";
import "../styles/footer.css";

export default function Footer({ userType }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const inRialto = useSelector(state => state.ui.inRialto);
  const enableECommerce = useSelector(state => state.auth.enableECommerce);
  const isUK = window.location.origin.endsWith('.co.uk');
  const isCA = window.location.origin.endsWith('.ca');
  return (
    <footer
      className="container-fluid no-print py-5"
      style={{
        background: userType === "Patient" ? "#2470C2" : "var(--pg_primary)",
      }}
    >
      {
        (inRialto && enableECommerce)
          ? <div className="container-xl px-0">
            <div className="row text-white">
              <div className="col-12 col-md-12 col-lg-3 ">
                <div className="mt-1 mt-lg-0 pg-text__bold text-white">Help</div>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={userType === "Patient" ? "patient-faq" : "/practitioner-faq"}
                >
                  {userType === "Patient" ? "Patient FAQ" : "Practitioner FAQ"}
                </Link>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/patient/customer-service"
                >
                  Customer Service
                </Link>
              </div>
              <div className="col-12 col-md-12 col-lg-3">
                <div className="mt-3 mt-lg-0 pg-text__bold text-white">About</div>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </div>
              <div className="col-12 col-md-12 col-lg-3">
                <div className="mt-3 mt-lg-0 pg-text__bold text-white">Legal</div>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={i18n.language === "ca" ? "/disclaimer" : "/legal-disclaimer"}
                >
                  Disclaimer
                </Link>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={i18n.language === "ca" ? "/privacy-policy" : "/privacy"}
                >
                  Privacy Policy
                </Link>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/dispensary-terms-use"
                >
                  Dispensary Terms of Use
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-12 my-5 mt-5 pg-text__small text-white">
                <div className="pg-text__small text-white my-2">
                  ‡These statements have not been evaluated by the Food & Drug Administration. These products are not intended to diagnose, treat, cure or prevent any disease.
                </div>
                <div className="pg-text__small text-white my-2">
                  †Provides weight management support as part of a healthy lifestyle with a reduced calorie diet and regular exercise.
                </div>
                <div className="pg-text__small text-white my-2">

                  {i18n.language === "en_gb" ? 'PureGenomics®' : 'PureInsight™' } nutritional information is not intended for use in the diagnosis, cure, mitigation, treatment or prevention of disease.
                </div>

                <div className="row mt-4">
                  <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0 ">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.facebook.com/PureEncapsulations" className="text-white fw-bold">Facebook</a>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/PureEncaps"
                      className="text-white fw-bold">Twitter</a>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.linkedin.com/company/pure-encapsulations/"
                      className="text-white fw-bold">	LinkedIn</a>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.instagram.com/pureencapsulations/"
                      className="text-white fw-bold">Instagram</a>
                  </div>
                  <div className="col-6 col-md-4 col-lg-2 my-2 my-lg-0">
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.youtube.com/user/pureencapsulations"
                      className="text-white fw-bold">Youtube</a>
                  </div>
                </div>
                <div className="pg-text__small text-white mt-2">
                  © {new Date().getFullYear()} <span dangerouslySetInnerHTML={{ __html: t('puregemonics') }} />. All
                  Rights Reserved
                </div>
              </div>
            </div>
            <div>
              {/* <PgLogo fill={"#fff"} fontSize="100px" /> */}
              {i18n.language === "en_gb" ? <img src={PgLogo} alt="logo" style={{ width: 172 }} /> : <img src={PiLogo} alt="logo" style={{ width: 172 }} />}

            </div>
          </div>
          : <div className="container-xl px-0">
            <div className="row text-white">
              <div className="col-12 col-md-12 col-lg-3 ">
                <div className="mt-1 mt-lg-0 pg-text__bold text-white">{t("help")}</div>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={ i18n.language === "ca" ? "hcp-faqs" : "/hcp/faqs" }
                >
                  {t('footer_link_1')}
                </Link>
                {i18n.language === "en_gb" &&
                  <Link
                    className="my-2 text-white text-decoration-none d-block"
                    to="/patient/gdpr-faqs"
                  >
                    GDPR FAQs
                  </Link>
                }
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={i18n.language === "ca" ? "/patient-faqs" : "/patient/faqs"}
                >
                  {t('footer_link_2')}
                </Link>
                <a
                  className="my-2 text-white text-decoration-none d-block"
                  href="https://www.youtube.com/playlist?list=PLfpPUB-xUWePWGSPdaZHTxam9qeg9REKD"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('footer_link_3')}
                </a>
              </div>
              <div className="col-12 col-md-12 col-lg-3">
                <div className="mt-3 mt-lg-0 pg-text__bold text-white">{t("about")}</div>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/contact-us"
                >
                  {t('footer_link_4')}
                </Link>
                {i18n.language === "en_gb" ?  <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/our-team"
                >
                  {t('footer_link_5')}
                </Link> : ""}
              </div>
              <div className="col-12 col-md-12 col-lg-3">
                <div className="mt-3 mt-lg-0 pg-text__bold text-white">Legal</div>
                {(isUK || isCA) && <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/cookies-notice"
                >
                  Cookies Notice
                </Link>}                
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={i18n.language === "ca" ? "/disclaimer" : "/legal-disclaimer"}
                >
                  {t('footer_link_6')}
                </Link>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={i18n.language === "ca" ? "/privacy-policy" : "/privacy"}
                >
                  {t('footer_link_7')}
                </Link>
                <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to={i18n.language === "ca" ? "/terms-of-service" : "/terms-use"}
                >
                  {t('footer_link_9')}
                </Link>
                {i18n.language == "en" && <Link
                  className="my-2 text-white text-decoration-none d-block"
                  to="/health-data-notice"
                >
                  {t('footer_link_10')}
                </Link>}
              </div>
            </div>
            <div className="row">
              <div className="col-12 my-5 mt-5 pg-text__small text-white">
                <div dangerouslySetInnerHTML={{ __html: t('footer_content') }} />
                <div className="pg-text__small text-white my-2">
                  © {new Date().getFullYear()} <span dangerouslySetInnerHTML={{ __html: t('footer_content_2') }} />
                </div>
              </div>
            </div>
            <div>
              {/* <PgLogo fill={"#fff"} fontSize="100px" /> */}
              {i18n.language === "en_gb" ? <img src={PgLogo} alt="logo" style={{ width: 172 }} /> : <img src={PiLogo} alt="logo" style={{ width: 172 }} />}
            </div>
          </div>}
    </footer>
  );
}
