import React, { Component, useEffect, useState } from "react";
import _ from "lodash";
import { Modal } from "react-bootstrap";
import PgSelect from "./PgSelect";


function DiscountVd({ showModal, onCancel, setDiscount, discount, discountError, submitDiscountForm }) {
  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className="add-practitioner-modal"
    >
      <Modal.Body>
        <div
          style={{
            position: "absolute",
            right: 25,
            top: 15,
            fontSize: 20,
            cursor: "pointer",
            color: "var(--pg_primary)",
          }}
          onClick={onCancel}
        >
          <div className="fas fa-times"></div>
        </div>
        <div>

          <PgSelect
            label="Enter an optional patient discount here (choose an increment of 5%)*"
            showLabel={true}
            required={true}
            optional={false}
            id="discount__adddiscount"
            setSelectedItem={setDiscount}
            selectedItem={
              discount.toString().includes("%") ? discount : discount + "%"
            }
            placeholder="Enter Discount"
            options={["0%", "5%", "10%", "15%", "20%", "25%", "30%", "35%"]}
            error={discount != "" ? "" : discountError}
          />
          <p className="" style={{fontSize: 12 }}>*Discount exclusions for certain products may apply.</p>
          <div>
            <button
              className="btn bgc-primary text-light px-4"
              onClick={() => {submitDiscountForm(); onCancel();}}
            >
              Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
export default DiscountVd;
