import { useCallback, useEffect, useLayoutEffect, useState } from "react";

const ScrollToTopFAB = () => {
    const [showButton, setshowButton] = useState(false);
    //  const callback = (entries, observer) => {
    //   entries.forEach(entry => {
    // if (entry.isIntersecting) {
    //   console.log('Element is in the viewport');
    // } else {
    //   console.log('Element is not in the viewport');
    // }
    //   });
    useEffect(() => {

        let options = {
            root: null,
            rootMargin: "0px",
            threshold: 1.0,
        };
        console.log('dom loadd')
        let observer = new IntersectionObserver((option1, option2) => {
            console.log('viewed', option1[0]?.isIntersecting)
            if (!option1[0]?.isIntersecting) {
                setshowButton(true)
            } else setshowButton(false)
        }, options);

        let target = document.querySelector("#stt-ref");
        observer.observe(target);

    }, []);

    const scrollTotop = useCallback(() => {
        window.scroll({ behavior: 'smooth', top: 0 })
    }, [])
    return (
        <>
            {showButton && <div className="stt-fab d-flex flex-column" onClick={scrollTotop}>
                <i className="fas fa-arrow-up"></i>
                Scroll <br />
                to top
            </div>}
        </>
    );
}

export default ScrollToTopFAB;