import Popup from 'reactjs-popup'
import i_blue from '../assets/i_blue.png';
import { ReactComponent as InfoIcon } from '../assets/info.svg'

const PgInfoTooltip = ({ children, position = 'top center', ...props }) => {
    return <Popup
        on="hover"
        trigger={(open) => (
            <InfoIcon
                stroke="var(--primary)"
                height={30}
                width={30}
                className="cursor-pointer"
                style={{
                    marginLeft: "4px",
                    cursor: "pointer",
                }}
                {...props}
            />)}
        // contentStyle={{ height: 'fit-content', width: 'fit-content' }}
        position={position}
        closeOnDocumentClick
    >
        <div className="p-" style={{ padding: '12px', ...(props.style && props.style) }}>
            {children}
        </div>
    </Popup>
}

export default PgInfoTooltip;