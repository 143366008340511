import React, { Component, useEffect, useState, useRef } from "react";
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { connect } from "react-redux";
import { getStaffList } from "../../../store/actions/practiceDashboard";
import { useLocation, useNavigate } from "react-router-dom";
import StaffTable from "./StaffTable";
import { parse } from "query-string";
import { useSelector, useDispatch } from 'react-redux';

function StaffList() {

  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const token = useSelector(state => state.auth.token);
  const getStaffsAction = (url, token, currentPracticeID, currentPractice) => dispatch(getStaffList(url, token, currentPracticeID, currentPractice));
  const [practitioners, setPractitioners] = useState([]);

  const params = parse(location.search);
  const selectedRegion = params["region"];
  const dispatch = useDispatch();
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
  const lang = useSelector((state) => state.auth.language);
  const is_staff = useSelector(state => state.auth.isStaff);

  const updateStaffTable = (url = "per_page=10&page=1") => {
    getStaffsAction(url, token, currentPractice, currentPracticeID).then((response) => {
      // console.log(response.payload.staffs)
      setPractitioners(response.payload.staffs)
      setLoading(false)
    });

  };

  useEffect(() => {
    if (is_staff) {
      navigate("/practice/dashboard")
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="container-xxl py-5 px-0">
        <div
          className="cursor-pointer textc-primary pg-link"
          onClick={() => navigate("/admin/dashboard")}
        >
          <i className="fas fa-chevron-left mr-2"></i> Dashboard
        </div>
        <div className=" my-4 pg-title">Staff Members</div>

        <div>
          <StaffTable
            token={token}
            practitioners={practitioners}
            updateStaffTable={updateStaffTable}
            loading={loading}
            selectedRegion={selectedRegion}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    getStaffsAction: (url, token, currentPractice, currentPracticeID) =>
      dispatch(getStaffList(url, token, currentPractice, currentPracticeID)),
  };
};

export default connect(mapStateToProps, mapDispatchtoProps)(StaffList);
