import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import imageFile from "../assets/iris-large.png";
import CellsSmall from "../assets/cells-small.jpg";
import TabletImage from "../assets/static/tablet_image.png";
import ExternalSitePrompt from "../components/StaticPages/ExternalSitePrompt";
import { useNavigate } from "react-router-dom";

// Rebranding changes
import HeroImage from "../assets/pg4/rebranding/HomepageHero.jpg"
import HPImage2 from "../assets/pg4/rebranding/HP_Image2.jpg"
import HPImage3 from "../assets/pg4/rebranding/HP_Image3.jpg"
import "../styles/homepage.css";

function HomePage(props) {
  const { t, i18n } = useTranslation();

  const bgImage = {
    backgroundImage: `url("${i18n.language === "en_gb" ? imageFile : HeroImage }")`,
  };
  const isUK = window.location.hostname.endsWith('.co.uk')
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState(t('pure_url'));
  const navigate = useNavigate();
  return (
    <>
      {/* <Header /> */}
      {showExternalSiteModal && (
        <ExternalSitePrompt
          userType="Patient"
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
        />
      )}
      <div className="banner-img" style={{ marginTop: -60 }}>
        <div className={`row pgs-image ${i18n.language === "en" ? 'rb_hp_bg' : ''}`} style={bgImage}>
          <div className="d-none d-md-block col-md-6 col-lg-7"></div>

          {i18n.language === "en"
            ? <div className="rb_hp_bg col-12 col-md-6 col-lg-5 mt-auto mb-2 my-xl-auto ps-5">
              <div className="mt-md-5 pgs-title text-center text-md-start ms-xxl-5 ">
                <div dangerouslySetInnerHTML={{ __html: t('prelogin_headline_1') }} />
              </div>
              <div className="row ms-xxl-5 px-xxl-0">
                <div className="col-lg-10 col-xxl-9 px-xxl-0">
                  <div className="pgs-heading textc-primary d-none d-md-block d-lg-block my-3">
                    <div dangerouslySetInnerHTML={{ __html: t('prelogin_headline_2') }} />
                  </div>
                  <div className="pgs-text d-none d-md-block d-lg-block">
                    <span dangerouslySetInnerHTML={{ __html: t('prelogin_textline_1') }} />
                  </div>
                </div>
              </div>
            </div>
            : <div className="offset-lg-7 col-12 col-md-6 col-lg-5 my-auto">
              <div className="pgs-title pgs-title__xl text-center text-md-start mt-md-5">
                <div dangerouslySetInnerHTML={{ __html: t('prelogin_headline_1') }} />
              </div>
              <div>
                <div className="pgs-heading textc-primary d-none d-md-block d-lg-block my-3">
                  <div dangerouslySetInnerHTML={{ __html: t('prelogin_headline_2') }} />
                </div>
                <div className="pgs-text d-none d-md-block d-lg-block">
                  <span dangerouslySetInnerHTML={{ __html: t('prelogin_textline_1') }} />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {/* <img src={image.src} alt={image.alt} className="" /> */}

      {/*content section*/}
      {/* <div className="" style={{ zIndex: -999, borderRadius: '50%', background: `url(${CellsSmall})`, height: 1000, width: 900, position: 'fixed', right: 100, bottom: 20 }}></div> */}

      {<div className="container-xxl bg-white">

        <div className="d-md-none">
          {i18n.language === "en" && <div className="pgs-title pgs-title__xl text-md-start my-3">
            <div dangerouslySetInnerHTML={{ __html: t('prelogin_headline_1') }} />
          </div>}
          <div className="pgs-title my-3">
            <span dangerouslySetInnerHTML={{ __html: t('prelogin_headline_2') }} />
          </div>
          <div className="pgs-text">
            <span dangerouslySetInnerHTML={{ __html: t('prelogin_textline_1') }} />
          </div>
        </div>

        {/* section 1 */}

        <div className="row justify-content-around overflow-y-hidden">
          {i18n.language === "en_gb"
            ? <div className="col-12 col-md-10 col-lg-6 position-relative my-lg-auto my-3 order-lg-last">
            <div className="position-lg-absolute homepage-parallax">
              <div className="w-100 overflow-hidden homepage-parallax__img">
                <ParallaxProvider>
                  <Parallax
                    className="custom-class"
                    y={["-155px", "10px"]}
                    tagOuter="figure"
                  >
                    <img src={CellsSmall} className="w-100 h-100" />
                  </Parallax>
                </ParallaxProvider>
              </div>
            </div>
          </div> : <div className="col-12 col-md-10 col-lg-6 position-relative  order-lg-last">
              <div className="">
                <div className="mt-n5">
                  {/* <ParallaxProvider>
                  <Parallax
                    className="custom-class"
                    y={["-155px", "10px"]}
                    tagOuter="figure"
                  > */}
                  <img src={HPImage2} className="w-100" />
                  {/* </Parallax> */}
                  {/* </ParallaxProvider> */}
                </div>
              </div>
            </div>}

          <div className="col-12 col-lg-5 my-auto">
            <div className="position-relative" dangerouslySetInnerHTML={{ __html: t('prelogin_headline_4') }} style={{ zIndex: 1 }} />
            <div className="pgs-text my-3">
              <span dangerouslySetInnerHTML={{ __html: t('prelogin_textline_2') }} />
            </div>
          </div>
        </div>

        {/* section 2 */}

        <div className="row justify-content-around my-3">
          <div className="col-12 col-lg-5 my-auto">
            <img src={i18n.language === "en_gb" ? TabletImage : HPImage3 } alt="" className="w-100" />
          </div>
          <div className="col-12 col-lg-6">
            <div className="pgs-title" dangerouslySetInnerHTML={{ __html: t("prelogin_headline_5") }} />
            <div className="pe-xl-5">
              <div className="pgs-text">
                <span dangerouslySetInnerHTML={{ __html: t('prelogin_textline_3') }} />
              </div>
              <button
                className="btn btn-primary py-4 w-100 my-3 text-light"
                onClick={() => navigate(i18n.language === "en_gb" ?  "/hcp/about-puregenomics" : i18n.language === "ca" ? "/hcp/discover-pureinsight" : "/hcp/about-pureinsight" )}
              >
                {t("prelogin_btn_1")}
              </button>

            </div>
          </div>
        </div>

        {/* section 3 */}
        <div className="my-3">
          <div className="row justify-content-center justify-content-lg-evenly">
            <div className="col-12">
              <div className="row  justify-content-evenly">
                <div className="col-12 col-xl-5 mt-lg-5 mt-3 text-start">
                  <div className="pgs-text__sm textc-primary">
                    {t('prelogin_headline_6')}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: t("prelogin_textline_5") }} />
                </div>
                <div className="col-12 col-md-6 col-xl-5 mt-lg-5 mt-3 text-start d-none d-lg-block">
                  <div className="pgs-text__sm textc-primary__p">{t('prelogin_headline_7')}</div>
                  <div dangerouslySetInnerHTML={{ __html: t("prelogin_textline_6") }} />
                </div>
              </div>
            </div>
            <div className="col-12  col-xl-5">
              <button
                className="btn btn-primary py-4 w-100 my-auto"
                onClick={() => navigate("/hcp/getting-started")}
              >
                {t("prelogin_btn_2")}
              </button>
            </div>
            <div className="col-5 d-none d-lg-block">
              <button
                className="btn btn-primary__p py-4 w-100 my-auto"
                onClick={() => navigate("/patient/how-to-get-started")}
              >
                {t("prelogin_btn_3")}
              </button>
            </div>
            <div className="d-lg-none mt-5">
              <div className="col-12 col-xl-5 mt-lg-5 mt-3 text-start">
                <div className="pgs-text__sm textc-primary__p">{t('prelogin_headline_7')}</div>
                <div dangerouslySetInnerHTML={{ __html: t("prelogin_textline_6") }} />
                <button
                  className="btn btn-primary__p py-4 w-100 my-auto"
                  onClick={() => navigate("/patient/how-to-get-started")}
                >
                  {t("prelogin_btn_3")}
                </button>
              </div>
              <div className="">
              </div>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}
export default HomePage;
