import React from 'react'
import FilePicker from './FilePicker'
import { useTranslation } from "react-i18next";
export default function UploadErrorComponent({onChooseAnotherFile, history}) {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="row">
        <div className="col-12">
          <hr />
          <div className="my-5">
            <FilePicker
              onChange={onChooseAnotherFile}
              label="Choose file..."
              type={1}
              acceptFiles=".txt,.zip"
            />
            <div style={{
              marginTop: 10,
              color: "#888888",
              fontFamily: "Mulish",
              fontSize: 12,
              fontWeight: 400,
              lineHeight: "24px",
              textAlign: "left",
            }}>
              { t('patient_afsnp_txt2') }
      </div>
          </div>
        </div>
      </div>
    </div>
  )
}