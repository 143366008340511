import React from 'react'
import { QuestionnaireProgressBar } from '../Patient/QuestionnaireProgressBar'
import { useTranslation } from "react-i18next";

function AfterQuestionnaireConfirmed({
    onTryAgain,
    error,
    status,
    uploadType,
    errorText,
    isInvited,
    onCompleteQuest
}) {

    let _status = status?.toLowerCase() === "processing" ? "analyzing" : status?.toLowerCase();
    _status = status?.toLowerCase() === "error" ? "uploading" : status?.toLowerCase();
    const { t, i18n } = useTranslation();
    return (
        <div className="container-xl">
            <div className="row justify-content-center my-5">
                <div className="col-12 col-lg-12 border progress-status-wrapper" style={{ height: 300 }}>
                    <div className="row pg-heading__sub text-center textc-primary progress-status-title">
                        
                        <div
                            className="col-6 border py-2"
                            style={{
                                background:
                                    (_status === "analyzing")
                                        ? '#2470C2'
                                        : (_status === "analyzing" || _status === "complete") ? "#e9f1f9" : '#fff',
                                color: _status === "analyzing" ? "#fff" : "#2470C2",
                            }}>analyzing</div>
                        <div
                            className="col-6 border py-2"
                            style={{ backgroundColor: _status === "complete" ? '#2470C2' : '#fff', color: _status === "complete" ? "#fff" : "#2470C2" }}>{ t('patient_snp_txt1') }</div>
                    </div>
                    {
                        error
                            ? <div className="text-center">
                                <div className="pg-text__bold text-center text-color__p my-lg-4 my-1 mt-lg-5" >
                                    <div>{errorText}</div>
                                    <div className="my-5" />
                                </div>
                                <button className="btn btn-primary w-50 mt-lg-4" onClick={onTryAgain}>{ t('patient_snp_txt2') }</button>
                            </div>
                            : <div className="row p-3">
                                <div className="col-12">
                                    <QuestionnaireProgressBar status={_status} error={error} color='progress_bar__patient' />
                                    <div className="pg-text__small my-2 text-center">
                                        {_status === "analyzing" &&
                                            "Your questionnaire will continue getting analyzed if you navigate away from this page or close your browser."}
                                    </div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AfterQuestionnaireConfirmed
