import React, { useState, useEffect } from "react";

import { Carousel } from "react-responsive-carousel";
import BlockUi from "react-block-ui";
import peLogo from "../../../assets/carousal.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";

export default function SupplementCatalogTile({ content, loading }) {
const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(1);
  const [mainContent, setmainContent] = useState([
    {
      content: "Getting content....",
      image:
        "https://tukuz.com/wp-content/uploads/2019/07/pure-encapsulations-logo-vector.png",
      link: "/",
      link_text: "Loading link",
      title: "Getting Supplement tile",
    },
  ]);

  useEffect(() => {
    if (!loading) setmainContent(content);
    setCurrentSlide(currentSlide + 1);
  }, [loading]);

  let onSlideChange = (e) => {
    setCurrentSlide(e);
  };

  let prevSlide = () => {
    setCurrentSlide(currentSlide - 1);
  };

  let nextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };
  return (
    <BlockUi tag="div" blocking={loading}>
      <div className="pg-card__content-height position-relative">
        <div className="my-3 pg-heading">Supplements Catalog</div>
        <div className="mx-4">
          <Carousel
            autoPlay={true}
            useKeyboardArrows={true}
            onChange={onSlideChange}
            showThumbs={false}
            showStatus={false}
            showIndicators={false}
            showArrows={false}
            selectedItem={currentSlide}
            infiniteLoop={true}
          >
            {mainContent?.map((data) => {
              return <Slide content={data} key={data.link_text} />;
            })}
          </Carousel>
        </div>
        <div className="text-end">
          <button
            className="btn bgc-primary px-4 text-light m-md-0 m-1"
            onClick={() =>navigate("/practice/patient/catalog")}
            id="browse-catalog-btn__dashboard"
          >
            Browse Catalog
          </button>
        </div>
        <div
          className="cursor-pointer"
          style={{ position: "absolute", top: "45%", right: "30px" }}
          id="cat-next-slide-btn__dashboard"
          onClick={nextSlide}
        >
          <i className="fas fa-chevron-right"></i>
        </div>
        <div
          className="cursor-pointer"
          style={{ position: "absolute", top: "45%", left: "30px" }}
          onClick={prevSlide}
          id="cat-prev-slide-btn__dashboard"
        >
          <i className="fas fa-chevron-left"></i>
        </div>
      </div>
    </BlockUi>
  );
}

function Slide({ content }) {
  return (
    <div className="row gx-5 bgc-secondary">
      <div className="col-12 col-sm-12 col-md-4 ">
        <div className="d-flex py-5 flex-column justify-content-center h-100 w-50 m-auto">
          <img src={content.image} alt="" />
        </div>
      </div>
      <div className="col-12 col-sm-12 col-md-7 text-start">
        <div className="pg-heading">{content.title}</div>
        <div className="pg-text" style={{ minHeight: "200px" }}>
          {content.content}
        </div>
        <a href={content.link} className="pg-link">
          {content.link_text} <i className="fas fa-chevron-right ms-2"></i>
        </a>
      </div>
    </div>
  );
}
