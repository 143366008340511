import { useCallback } from "react";
import { useSelector } from "react-redux";

const BottomNav = ({ onNext, onPrev }) => {
    const isLoadingQuestions = useSelector(state => state.pg4.questionsLoading);
    const isLoadingAnswers = useSelector(state => state.pg4.answersLoading);
    const currentSection = useSelector(state => state.pg4.q_current_section);

    // <BottomNav
    // action1={{ onclick: onPrevClick, disable: isLoadingQuestions || isLoadingAnswers || currentSection?.previous_section_id === -1 }}
    // action2={{ onclick: handleNextSection, disable: isLoadingQuestions || isLoadingAnswers || currentSection?.next_section_id === -1 }}
    // />
    const scrollToTop = useCallback(() => window.scroll({ top: 0, behavior: 'smooth' }), [])
    return (
        <div className="pg4-bottom-nav d-flex justify-content-between align-items-center">
            <div>
                {/* <button className="pg4-bottom-nav__btn"
                onClick={onPrev}
                disabled={isLoadingQuestions || isLoadingAnswers || currentSection?.previous_section_id === -1}
            >
                Previous
            </button> */}
            </div>
            <div className="pg4-bottom-nav__text" onClick={scrollToTop}>
                <span className="position-absolute" style={{
                    transform: 'translate(-50%,-50%)',
                    left: "50%",
                    top: '50%'
                }}>Scroll to top</span>
            </div>
            <div>
                <button className="pg4-bottom-nav__btn bg_primary text_secondary"
                    onClick={onNext}
                    disabled={isLoadingQuestions || isLoadingAnswers}
                >{currentSection?.next_section_id !== -1 ? 'Next' : 'Complete'}
                </button>
            </div>

        </div>);
}

export default BottomNav;