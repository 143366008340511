import React from "react";
import { useTranslation } from "react-i18next";

export default function OrderHistory({ content, navigateToOrders }) {
	
	const { t, i18n } = useTranslation();

	return (
		<div
			className="pq-card p-4 px-5"
			style={{
				backgroundColor: "#f6f6f6 !important",
				background: "#f6f6f6 !important",
				height: "390 !important",
			}}
		>
			<div className="pd-pg-heading text-center">
        Order History
			</div>
			<div className=" justify-content-between">
				<div className="my-4">
					<div className="pg-heading__sub">{content?.title}</div>
					<div className="pg-text my-2 py-2">
            View your order history, track order status and refill orders quickly and easily anytime.
					</div>
					<a
						className="pg-link text-color__p cursor-pointer pg-text d-block my-3 text-center"
						onClick={() => navigateToOrders()}
					>
						View Order History <i className="fas fa-chevron-right"></i>
					</a>
				</div>
			</div>
		</div>
	);
}