

export const genotype = [
  {
    gene: 'SOD2',
    snp: ' Ex3-631C>G',
    rs_number: 'rs1799895',
    alleles: 'CC',
    results: '- / -'
  },
  {
    gene: 'IRS1',
    snp: ' ',
    rs_number: 'rs2943641',
    alleles: 'TT',
    results: '- / -'
  },
  {
    gene: 'FABP2',
    snp: ' ',
    rs_number: 'rs231775',
    alleles: 'GG',
    results: '+ / +'
  },
  {
    gene: 'ACVR1B',
    snp: 'G5465A',
    rs_number: 'rs7946',
    alleles: 'TT',
    results: '+ / +'
  },
  {
    gene: 'Gene',
    snp: 'SNP',
    rs_number: 'RS NUmber',
    alleles: 'Alleles',
    results: '- / -'
  },
  {
    gene: 'Gene',
    snp: 'SNP',
    rs_number: 'RS NUmber',
    alleles: 'Alleles',
    results: '- / -'
  },
]

export const supplements = [
  {
    title: "PureGenomics® UltraMultivitamin",
    desc: `3-per-day comprehensive, high-potency multivitamin/mineral targeting common genetic polymorphisms.`,
  },
  {
    title: "Nrf2 Detox",
    desc: "Targets Nrf2 to support detoxification and antioxidant defenses.",
  },
  {
    title: "Resveratrol EXTRA",
    desc: `Combines resveratrol with polyphenols from red wine and
        grape seed for enhanced support of healthy cellular and
        cardiovascular function.`,
  },
  {
    title: "Ligament Restore",
    desc: "Helps maintain healthy tendons, ligaments and joints.",
  },
  {
    title: "5-HTP (5-Hydroxytryptophan) 50 mg",
    desc:
      "Promotes brain serotonin levels to support emotional wellbeing, healthy eating behavior, and healthy sleep.",
  },
  {
    title: "Rhodiola Rosea",
    desc: `Broad-spectrum adaptogen support for occasional mental and
        physical stress, and cardiovascular function.`,
  },
  {
    title: "EPA/DHA essentials",
    desc: `Ultra-pure, microfiltered fish oil concentrate; support for
      cardiovascular health and daily wellness.`,
  },
  {
    title: "Potassium (citrate)",
    desc: `Essential mineral to support vascular function, bone
      mineralization, alkaline balance and overall health.`,
  },
];

export const traitCategory = [{
  title: 'Vitamins, Minerals & Omega-3s',
  traitsLength: '11',
  traits: {
    categorization: false, items: [
      { title: 'Vitamin A (Retinol)', call_to_action: true, link: '/', new: false },
      { title: 'Vitamin B (Riboflavin)', call_to_action: false, link: '/', new: false },
      { title: 'Vitamin B6 (Pyridoxine)', call_to_action: true, link: '/', new: false },
      { title: 'Folate', call_to_action: true, link: '/', new: false },
      { title: 'Choline', call_to_action: true, link: '/', new: false },
      { title: 'Vitamin B12 (Cobalamin)', call_to_action: true, link: '/', new: false },
      { title: 'Vitamin D', call_to_action: true, link: '/', new: false },
      { title: 'Vitamin E (Alpha-tocopherol)', call_to_action: true, link: '/', new: false },
      { title: 'Iron Overload', call_to_action: true, link: '/', new: false },
      { title: 'Zinc', call_to_action: true, link: '/', new: false },
      { title: 'Omega-3 Fatty Acids', call_to_action: true, link: '/', new: false },
    ],
  },
  considerableTraits: 8,
  color: '#963c9e',

}, {

  title: 'Cardiovascular Health',
  traitsLength: '9',
  traits: {
    categorization: false, items: [
      { title: 'Vitamin A (Retinol)', call_to_action: true, link: '/' },
    ],
  },
  considerableTraits: 5,
  color: '#be4a2b'

}, {
  color: '#30815e',
  title: 'Cognitive Health & Memory',
  traitsLength: '6',
  traits: {
    categorization: false, items: [
      { title: 'Vitamin A (Retinol)', call_to_action: true, link: '/' },
    ],
  },
  considerableTraits: 4
}, {
  color: '#7257b5',
  title: 'Detoxification',
  traitsLength: '4',
  traits: {
    categorization: false, items: [
      { title: 'Vitamin A (Retinol)', call_to_action: true, link: '/' },
    ],
  },
  considerableTraits: 3
}, {
  color: '#3fa386',
  title: 'Gastrointestinal Health',
  traitsLength: '4',
  traits: {
    categorization: false, items: [
      { title: 'Vitamin A (Retinol)', call_to_action: true, link: '/' },
    ],
  },
  considerableTraits: 2
}, {
  color: '#ae406b',
  title: 'Immune Health',
  traitsLength: '2',
  traits: {
    categorization: false, items: [
      { title: 'Vitamin A (Retinol)', call_to_action: true, link: '/' },
    ],
  },
  considerableTraits: 2
}, {
  color: '#4482bc',
  title: 'Metabloic & Weight Management',
  traitsLength: '5',
  traits: {
    categorization: false, items: [
      { title: 'Vitamin A (Retinol)', call_to_action: true, link: '/' },
    ],
  },
  considerableTraits: 1
}, {
  color: '#c44e4c',
  title: 'Energy & Fitness',
  traitsLength: '10',
  considerableTraits: 0,
  traits: {
    categorization: true, items: {
      performance_type: [
        { title: 'Strength & Power', call_to_action: true, link: '/', new: true },
      ],
      injury_risk: [
        { title: 'Tendon or Ligament Injury', call_to_action: false, link: '/', new: true },
      ],
      exercise_response: [
        { title: 'Endurance Potential', call_to_action: false, link: '/', new: true },
      ]
    },
  },
}, {
  color: '#367ea0',
  title: 'Glucose Metabolism',
  traitsLength: '1',
  considerableTraits: 0,
  traits: {
    categorization: false, items: [
      { title: 'Vitamin A (Retinol)', call_to_action: true, link: '/' },
    ],
  },
},];




export const patientInfoData = {
  name: 'Jennifer Roosevelt',
  age: '45',
  gender: 'Female',
  type: 'Caucasian',
  genetics: [],
  questionnaire: [],
  labs: []
}