import React, { useMemo, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import toastr from "react-hot-toast";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { setGenotypeFilters } from "../../../store/actions/patientReport";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";
import PgCheckbox from "../../PgCheckbox";
import TextInputWithIcon from "../../TextInputWithIcon";
import StickyTable from "react-sticky-table-thead";

export default function GenotypeFilterTable({
  error,
  genotype,
  onCancel,
  updateTable,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // redux states
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const token = useSelector((state) => state.auth.token);
  const loading = useSelector((state) => state.auth.isGettingGenotypeFilters);
  // redux action
  const setGenotypeFiltersAction = (data) =>
    dispatch(
      setGenotypeFilters(currentPracticeID, currentPractice, token, data)
    );

  const [filters, setfilters] = useState([]);
  const [oFilters, setoFilters] = useState({});
  const [localData, setlocalData] = useState([]);
  const [reRenderTable, setReRenderTable] = useState(Math.random());

  useEffect(() => {
    setoFilters(() => {
      let abcd = {};
      genotype.forEach((__d) => {
        if (__d.hide) abcd[__d.rs_number] = __d.gene;
      });
      return abcd;
    });
  }, []);

  const toggleSelectAll = () => {
    console.log(_.size(oFilters));
    if (_.size(oFilters) === genotype.length) {
      setoFilters({});
    } else {
      setoFilters((ob) => {
        let dd = {};
        genotype.forEach((d) => {
          dd[d.rs_number] = d.gene;
        });
        return dd;
      });
    }
  };

  const column = [
    {
      Header: (
        <div className="position-relative practitioner-batch-checkbox">
          <PgCheckbox
            checked={_.size(oFilters) === 0}
            onCheck={toggleSelectAll}
          />
        </div>
      ),
      accessor: "id",
      sortable: false,
      filterable: false,
      Cell: (data) => {
        // console.log({ a: data.row.original })
        return (
          <div className="position-relative practitioner-batch-checkbox">
            <PgCheckbox
              checked={!oFilters[data.row.original.rs_number]}
              onCheck={() => {
                setoFilters((_of) => {
                  let __of = { ..._of };
                  if (!__of[data.row.original.rs_number])
                    __of[data.row.original.rs_number] = data.row.original.gene;
                  else delete __of[data.row.original.rs_number];
                  return __of;
                });
              }}
            />
          </div>
        );
      },
    },
    { Header: t('genotype_th_1'), accessor: "gene" },
    { Header: t('genotype_th_2'), accessor: "snp" },
    { Header: t('genotype_th_3'), accessor: "rs_number" },
    { Header: t('genotype_th_4'), accessor: "alle" },
  ];

  const columns = useMemo(() => column, [reRenderTable]);
  const data = useMemo(() => genotype, [genotype, reRenderTable]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy
  );

  useEffect(() => {
    setReRenderTable(Math.random());
  }, [oFilters]);

  const onSave = async () => {
    console.log(oFilters);
    let data = { hidden_gene: _.isEmpty(oFilters) ? [] : oFilters };
    const response = await setGenotypeFiltersAction(data);
    if (!response.error) {
      updateTable();
      toastr.success("Filter saved successfully!!");
      onCancel();
    }
  };

  return (
    <>
      <div className="row justify-content-center my-4">
        <div className="col-8">
          <TextInputWithIcon
            value={globalFilter}
            inputHandler={(e) => setGlobalFilter(e.target.value)}
            placeholder={t('genotype_line_4')}
            Icon={
              <i className="fa fa-search cursor-pointer text-secondary"></i>
            }
          />
        </div>
      </div>
      <div className="genotype-filter__container">
        <StickyTable height={300}>
          <table
            {...getTableProps()}
            className="pg-table practitioners-table genotype-filter__table "
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th>
                      {column.render("Header")}
                      {/* <span className="fs-4">
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                    </span> */}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {error && (
              <tbody>
                <tr>
                  <td colSpan="3">
                    <div className="w-100 text-center">
                      {t('genotype_no_data')}
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
            {genotype.length < 1 && !error && (
              <tbody>
                <tr>
                  <td colSpan="3">
                    <div className="w-100 text-center">{t('genotype_no_data')}</div>
                  </td>
                </tr>
              </tbody>
            )}
            {genotype.length > 0 && !error && (
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </StickyTable>
      </div>

      <hr className="my-4" />

      <div className="text-end">
        <button className="btn bg-none textc-primary px-5" onClick={onCancel}>
          {t('cancel')}
        </button>
        <button className="btn bgc-primary text-light px-5" onClick={onSave}>
          {loading ? "Saving..." : t('save')}
        </button>
      </div>
    </>
  );
}
