import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SupportAndResources() {

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  return (
    <div
      className="pg-card p-4 px-5"
      style={{
        backgroundColor: "#f6f6f6 !important",
        background: "#f6f6f6 !important",
      }}
    >
      <div className="pd-pg-heading text-center">
        <span dangerouslySetInnerHTML={{ __html: t('patient_res1') }} />
      </div>
      <div className=" justify-content-between">
        <div className="my-4">
          <div className="pg-heading__sub">
            <span dangerouslySetInnerHTML={{ __html: t('pat_card1_subtitle') }} />
          </div>
          <span dangerouslySetInnerHTML={{ __html: t('patient_res2') }} />
        </div>
        <div className="my-4">
          <div className="pg-heading__sub">{t('pracd_btn_7')}</div>
          <span dangerouslySetInnerHTML={{ __html: t('patient_res3') }} />
          <div className="text-center px-5 btn-px-0">
            <button
              className="btn pd-btn px-5 my-3 w-75"
              onClick={() => navigate(i18n.language === "ca" ? "/patient-faqs" : "/patient/faqs")}
            >
              {t('pracd_btn_7')}
            </button>
          </div>
        </div >
        <div>
          <div className="pg-heading__sub">{t('patient_res4')}</div>
          <div className="pg-text">
            <span dangerouslySetInnerHTML={{ __html: t('pat_card1_support') }} />
            <div className="my-1 mt-3">
              {t('patient_res5')} <br />
              <a href={t('contact_mail_to_email')}>
                {t('contact_email')}
              </a>{" "}
              <br />
              <div className="pg-text__bold">{t('contact_number')}</div>
            </div>
            <span dangerouslySetInnerHTML={{ __html: t('patient_res6') }} />
          </div>
        </div>
      </div >
    </div >
  );
}
