import React, { useState, useEffect, useMemo } from "react";

import _ from "lodash";
import { geneAnalysis } from "../../../Pages/Report/report_data";
import PgRatings from "../../PgRatings";
import GeneAnalysisContent from "./GeneAnalysisContent";
import { pg3_report_icons } from "../../Patient/PG4/constant";

function GeneAnalysisListItem({ trait, expandAll, filter, index, psStatus }) {
  const [showContent, setShowContent] = useState(expandAll || index == 0);

  useEffect(() => {
    setShowContent(expandAll || index == 0);
  }, [expandAll]);

  const imageName = trait.title
    .split(" ")
    .join("_")
    .split(",")
    .join("")
    .toLowerCase();
  const image = require("../../../assets/" + imageName + ".png");
  return (
    <div>
      <div
        className="d-flex justify-content-between py-2 cursor-pointer"
        onClick={() => setShowContent(!showContent)}
      >
        <div>
          <div className="d-inline-block">
            <img src={pg3_report_icons[trait.title]} alt="" style={{ height: 50, width: 50 }} />
          </div>
          <div
            className="pg-heading d-inline-block mx-3"
            style={{ color: `${trait.color}`, height: 40, verticalAlign: "bottom" }}
          >
            {trait.title}
          </div>
        </div>
        <div className="my-auto">
          <i
            className="fa fa-chevron-right  transition-all"
            aria-hidden="true"
            style={{
              transform: showContent ? "rotate(90deg)" : "rotate(0deg)",
            }}
          ></i>
        </div>
      </div>
      {showContent && (
        <div className="my-3">
          <GeneAnalysisContent traitContent={trait} filter={filter} psStatus={psStatus} />
        </div>
      )}
      <hr />
    </div>
  );
}

export default GeneAnalysisListItem;

