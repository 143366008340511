import RadioVariantSelection from "../QuestionTypes/RadioVariantSelection";
import SingSelectDropdown from "../QuestionTypes/SingleSelectDropdown";
import DateInput from "./DateInput";

const AccountInputsContainer = ({ inputValues, setinputValues }) => {
    return (<div>
        <div className="">
            <DateInput
                onInput={setinputValues}
                inputValues={inputValues}
                question={{ type: "dob" }}
            />
            <RadioVariantSelection question={{
                question_id: "gender",
                type: "gender",
                question_text: "Please share your gender assigned when you were born",
                choices: ["Male", "Female", "Intersex"]
            }}
                onInput={setinputValues}
                inputValues={inputValues}
            />
            <SingSelectDropdown
                question={{
                    type: "race",
                    question_id: "race",
                    question_text: "Which of the following five racial designation best describes you?"
                }}
                onInput={setinputValues}
                inputValues={inputValues} />
            <RadioVariantSelection
                question={{
                    type: "ethnic",
                    question_id: "ethnic",
                    question_text: "Do you consider yourself Hispanic / Latino?",
                    choices: ["Yes", "No"]
                }}
                onInput={setinputValues}
                inputValues={inputValues}
            />
            <RadioVariantSelection
                question={{
                    type: "locality",
                    question_id: "locality",
                    question_text: "Please select the term that best describes the area where you live.",
                    choices: ["Urban", "Suburban", "Rural"]
                }}
                onInput={setinputValues}
                inputValues={inputValues}
            />
        </div>
    </div>);
}

export default AccountInputsContainer;