import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash";
import toast from "react-hot-toast";

import {
  getPatientReportDashboardData,
  updatePatientSkipQuestionnaire,
} from "../../../store/actions/patientDashboard";
import { generateReport, updateReportWITHPRS } from "../../../store/actions/patientList";
import {
  closeWhatsNew,
  getPatientScriptStatus,
} from "../../../store/actions/patientDashboard";
import { toggleLoading } from "../../../store/actions/ui";

import { updateQuestionSession } from "../../../store/actions/questions";
import SkipQuestionnarie from "./SkipQuestionnaire";
import QuestionnarieWithoutGene from "./QuestionnarieWithoutGene";
import { updatePatientReport } from "../../../store/actions/patientReport";
import NewBadgeIcon from "../../../assets/newBadgeIcon.png";
import { useNavigate } from "react-router-dom";

import SupportAndResources from "./SupportAndResources";
import Questionnaire from "./Questionnaire";
import DownloadReport from "./DownloadReport";
import UploadReport from "./UploadReport";
import NewInformation from "./NewInformation";
import ShowSupplementRecomds from "./ShowSupplementRecomds";
import OrderHistory from "./OrderHistory";

import { updateGeneticUpload } from "../../../store/actions/genetics";
import L_PatientDashboard from "../../../components/LoadingEffectComponent/Patient/L_PatientDashboard";

import GeneticUploadErrorModal from '../../../components/Report/GeneticUploadErrorModal'
import qs, { parseUrl } from "query-string";
import GDPRStatus from "./GDPRStatus";

import { useTranslation } from "react-i18next";
import PatientTAC from "../../../components/Patient/PatientTAC";

const PatientReportDashboard = ({ history, loading, content }) => {


  //redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const patientReport = useSelector(state => state.patientDashboard.reportData);
  const geneticData = useSelector(state => state.patientDashboard.geneticData);
  const questionnaireData = useSelector(state => state.patientDashboard.questionnaireData);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const geneticUploadBy = useSelector(state => state.patientDashboard.geneticUploadBy);
  const recentQuestionnaireData = useSelector(state => state.patientDashboard.recentQuestionnaireData);
  const appToken = useSelector(state => state.auth.appToken);

  const dispatch = useDispatch();

  //redux actions
  const toggleLoadingAction = (data) => dispatch(toggleLoading(data));
  const generatePG2PatientReport = (token, currentPracticeID, currentPractice, id) => dispatch(generateReport(token, currentPracticeID, currentPractice, id));
  const getPatientReportData = (token, currentPracticeID, currentPractice) => dispatch(getPatientReportDashboardData(token, currentPracticeID, currentPractice));
  const updateSkipQuestionnaire = (token, currentPracticeID, currentPractice) => dispatch(updatePatientSkipQuestionnaire(token, currentPracticeID, currentPractice));
  const updatePatientReportAction = (data) => dispatch(updatePatientReport(data));
  const updateQuestionSessionAction = (token, currentPracticeID, currentPractice, data) => dispatch(updateQuestionSession(token, currentPracticeID, currentPractice, data));
  const updateGeneticUploadAction = (data) => dispatch(updateGeneticUpload(data));
  const closeWhatsNewAction = (token, currentPracticeID, currentPractice) => dispatch(closeWhatsNew(token, currentPracticeID, currentPractice));
  const getPatientScriptStatusAction = (patientId, profileId, tenantId, appToken) => dispatch(getPatientScriptStatus(patientId, profileId, tenantId, appToken));

  //local states
  const [showQuestionarieModal, setshowQuestionarieModal] = useState(false);
  const [questionarieWithoutGeneModal, setQuestionarieWithoutGeneModal] =
    useState(false);
  const [questionnnaireStatus, setQuestionnnaireStatus] = useState("no data");
  const [questionnnaireSkipped, setQuestionnnaireSkipped] = useState("");
  const [patientCurrentReport, setPatientCurrentReport] = useState("");
  const [patientReportStatus, setPatientReportStatus] = useState("");

  const [showWhatsNew, setShowWhatsNew] = useState(true);
  const [whatsNewText, setWhatsNewText] = useState({});
  const [showRecommeds, setShowRecommeds] = useState(false);
  const [showGeneticUploadErrorModal, setShowGeneticUploadErrorModal] = useState(false);

  // report update with prs
  const [polygenicScoreStatus, setPolygenicScoreStatus] = useState(null);

  const updateReportWITHPRSAction = (pld) => dispatch(updateReportWITHPRS({ token, currentPracticeID, currentPractice, pld }))


  const navigate = useNavigate();

  useEffect(() => {
    let qp = parseUrl(window.location.href).query
    if (qp.scriptId) {
      const obj = qs.parse(window.location.search, { decode: false });
      return navigate("/patient/supplement_suggestions", {
        state: {
          path: "/supplement_suggestions",
          script: obj,
        }
      });
    }
    if (qp.orderId) {
      const obj = qs.parse(window.location.search, { decode: false });
      return navigate("/patient/order/" + obj.orderId, {
        state: {
          path: `/order/${obj.orderId}`,
          orderId: obj.orderId,
        }
      });
    }
    getPatientReportData(token, currentPracticeID, currentPractice).then(
      (res) => {
        if (!res.error) {
          console.log(res.payload, res.payload.show_whats_new);
          setShowWhatsNew(res.payload?.current_report_data?.show_whats_new);
          setWhatsNewText(
            res.payload?.current_report_data?.whats_new_text?.top_1
          );
          setQuestionnnaireStatus(res.payload?.questionnaire_data?.status);
          setQuestionnnaireSkipped(res.payload?.questionnaire_data?.skipped);
          setPatientCurrentReport(res.payload?.current_report_data);
          setPatientReportStatus(res.payload?.patient_report_status)
          setPolygenicScoreStatus(res.payload?.polygenic_score_status)
          let patientId = res.payload.current_report_data?.genetics?.patient_id;
          let profileId = res.payload.current_report_data?.genetics?.report_id;

          patientId && getPatientScriptStatusAction(
            patientId,
            profileId,
            currentPracticeID,
            appToken
          ).then((res) => {
            setShowRecommeds(!_.isEmpty(_.trim(res.payload?.script_id)));
          });
        }
      }
    );
  }, []);

  const closeWhats = (closeType) => {
    console.log("asd");
    closeWhatsNewAction(
      token,
      currentPracticeID,
      currentPractice,
      closeType
    ).then((response) => {
      if (!response.error) {
        setShowWhatsNew(false);
      }
    });
  };

  const skipQuestionnaire = () => {
    setshowQuestionarieModal(false);
    updateSkipQuestionnaire(token, currentPracticeID, currentPractice).then(
      (response) => {
        console.log(response);
        getPatientReportData(token, currentPracticeID, currentPractice);
        toast.success(response.payload.message);
      }
    );
  };

  const handleQuestionnaire = () => {
    // if (geneticUploadBy && _.isEmpty(geneticData)) {
    //   setQuestionarieWithoutGeneModal(true);
    // } else {
    startSmartQuestionnaire();
    // }
  };

  const handleResumeQuestionnaire = () => {
    if (questionnnaireStatus.toLowerCase() === "started") {
      navigate("/patient/questionnaire");
    } else {
      navigate("/patient/review_answer", { state: { viewOnly: false } });
    }
  };

  const startSmartQuestionnaire = () => {
    updateQuestionSessionAction(token, currentPracticeID, currentPractice, {
      patient_id: currentUserId,
      status: "no_data",
    });
    navigate("/patient/questionnaire");
  };

  const goToReportPage = (patientId, reportId) => {
    updatePatientReportAction({
      currentPatientId: patientId,
      currentReportId: reportId,
    });
    navigate("/patient/report");
  };

  const handleGeneticsNav = (fromStart, docID, customStatus) => {
    console.log("fromstart--", fromStart);
    if (fromStart)
      updateGeneticUploadAction({
        fileSelected: false,
        fileStatus: "Uploading",
        fileUploadStarted: false,
        uploadError: false,
        documentId: null,
      });
    else
      updateGeneticUploadAction({
        fileSelected: true,
        fileStatus: customStatus || geneticData.report_status,
        fileUploadStarted: true,
        uploadError: false,
        documentId: docID || geneticData.genetic_document_ids,
      });
    navigate("/patient/snp", {
      state: {
        patientsReport: patientCurrentReport,
      }
    });
  };

  const __generatePGPatientReport = async (pId, rId) => {
    toggleLoadingAction(true);
    const response = await generatePG2PatientReport(
      currentPracticeID,
      currentPractice,
      token,
      currentUserId
    );
    if (!response.error) {
      updateGeneticUploadAction({
        fileSelected: true,
        fileStatus: "uploading",
        fileUploadStarted: true,
        uploadError: false,
        documentId: geneticData.genetic_document_ids,
      });
      navigate("/patient/snp", {
        state: {
          patientsReport: patientCurrentReport,
        }
      });
    }
    toggleLoadingAction(false);
  };

  const navigateToPatientScripts = () => {
    navigate("/patient/supplement_catalog", {
      state: {
        path: "/pg_patient_recommendations",
        browseCatalog: false,
      }
    });
  };

  const navigateToPatientOrders = () => {
    navigate("/patient/order_history", {
      state: {
        path: "/pg_patient_orders",
        browseCatalog: false,
      }
    });
  };

  const { t, i18n } = useTranslation();

  const updateReportFn = () => {
    // geneticData.report_id
    updateReportWITHPRSAction({ report_id: geneticData.report_id }).then(response => {
      console.log('update report response', response);
      if (response.error) {
        toast.error(response?.payload?.message)
        return;
      }
      let docID = response?.payload?.document_id
      if (docID) {
        handleGeneticsNav(false, docID, 'uploading');
      }
    })
  }

  return (
    <div className="container-xl py-5 px-md-2 px-3">
      <GDPRStatus />
      <PatientTAC />
      <SkipQuestionnarie
        showModal={showQuestionarieModal}
        onAccept={() => skipQuestionnaire()}
        onCancel={() => setshowQuestionarieModal(false)}
      />
      <QuestionnarieWithoutGene
        showModal={questionarieWithoutGeneModal}
        onAccept={() => setQuestionarieWithoutGeneModal(false)}
      />
      {loading ? (
        <L_PatientDashboard />
      ) : (
        <>
          <div className="container-xl py-5">
            <div className="mt-3 my-2 pd-pg-title ">
              {t('pracd_welcome')}, {patientCurrentReport?.patient_full_name}
            </div>
            {_.isEmpty(geneticData) && (
              <div
                className="row"
                style={{ padding: 41, backgroundColor: "#f9f9f9" }}
              >
                <span dangerouslySetInnerHTML={{ __html: t('patient_dashboard_wel') }} />
              </div>
            )}

            {!_.isEmpty(geneticData) && (
              <div className="row justify-content-center justify-content-lg-between my-4">
                <div className="col-6 col-lg-6">
                  <div className="pd-pg-report">{t('patient_dashboard_txt1')}</div>
                </div>
                <div className="col-6 col-lg-6 text-right">
                  <div
                    className="pg-link text-color__p"
                    onClick={() => navigate("/patient/past_reports")}
                  >
                    {t('pracpl_btn_5')} <i className="fas fa-chevron-right"></i>
                  </div>
                </div>
              </div>
            )}
            <div className="my-5">
              {(!_.isEmpty(geneticData) ||
                (!_.isEmpty(questionnnaireStatus) &&
                  questionnnaireStatus.toLowerCase() !== "no data")) && (
                  <div className="row clone-table table-column-clone justify-row">
                    {!_.isEmpty(geneticData) && (
                      <div className="col-auto col-md-3">
                        <h5>{t('patient_dashboard_txt2')}</h5>
                      </div>
                    )}
                    {!_.isEmpty(geneticData) && (
                      <div className="col-auto col-md-3">
                        <h5>{t('genetics')}</h5>
                      </div>
                    )}
                    {!_.isEmpty(questionnnaireStatus) &&
                      questionnnaireStatus.toLowerCase() !== "" && (
                        <div className="col-auto col-md-4">
                          <h5>{t('questionnaire')}</h5>
                        </div>
                      )}
                    <div className="col-auto col-md-2 d-none"></div>
                  </div>
                )}

              {/* {_.isEmpty(geneticData) && <div className="row py-3 table-column-clone ml-0">
                {_.isEmpty(geneticData) && questionnnaireStatus != "No Data" && (<div className="col-4">
                  <p className="status-align-right">{questionnnaireStatus}</p>
                </div>)}
              </div>} */}

              {!_.isEmpty(geneticData) && (
                <div className="row py-3 table-column-clone ml-0 align-items-center">
                  {!_.isEmpty(geneticData) && (
                    <div className="col-auto col-md-3">
                      <span className="align-top">
                        <img
                          src={NewBadgeIcon}
                          alt=""
                          className="mb-1 new-tag"
                        />
                      </span>
                      {patientCurrentReport.report_completed && (
                        <div className="d-md-inline-block px-3">
                          {geneticData.uploaded_at}
                        </div>
                      )}
                    </div>
                  )}
                  {!_.isEmpty(geneticData) && (
                    <div className="col-auto col-md-3">
                      {
                        geneticData.report_status === "Error" ?
                          <div
                            className="pg-link"
                            onClick={() => setShowGeneticUploadErrorModal(true)}
                          >
                            {geneticData.report_status}
                          </div>
                          :
                          <div
                            dangerouslySetInnerHTML={{
                              __html: geneticData.report_name,
                            }}
                          />
                      }
                    </div>
                  )}
                  {
                    <div className="col-auto col-md-4">
                      <div>{questionnnaireStatus}</div>
                    </div>
                  }
                  {!_.isEmpty(geneticData) && (
                    <div className="col-12 col-md-2 text-md-right text-center report-btn ">
                      {!polygenicScoreStatus && <button
                        className="btn btn-primary__p text-light my-1 py-0 me-2 "
                        onClick={updateReportFn}
                      >
                        Update Report
                      </button>}
                      {geneticData.show_generate_report ? (
                        <button
                          className="btn btn-primary__p text-light my-0 py-0 me-2 "
                          onClick={() =>
                            __generatePGPatientReport(
                              geneticData.patient_id,
                              geneticData.report_id
                            )
                          }
                        >
                          {t('pracpl_btn_1')}
                        </button>
                      ) : (
                        geneticData?.report_status?.toLowerCase() ===
                        "complete" && (
                          <button
                            className="btn btn-primary__p text-light my-0 py-0 me-2 "
                            onClick={() =>
                              goToReportPage(
                                geneticData.patient_id,
                                geneticData.report_id
                              )
                            }
                          >
                            {t('pracpl_btn_2')}
                          </button>
                        )
                      )}
                    </div>
                  )}
                </div>
              )}

              {_.isEmpty(geneticData) &&
                patientCurrentReport?.questionnaire &&
                questionnnaireStatus != "No Data" && (
                  <div className="row py-3 table-column-clone ml-0">
                    {
                      <div className="col-2 text-center move-status-left">
                        <div className="status-align-right">
                          {questionnnaireStatus}
                        </div>
                      </div>
                    }
                  </div>
                )}
            </div>

            <div className="container-xl mt-2 px-0">
              <div className="row g-5">
                {((patientReportStatus === "archived" &&
                  patientCurrentReport?.snp_upload_patient) ||
                  (_.isEmpty(geneticData) &&
                    patientCurrentReport?.genetic_upload_by)) && (
                    <div className="col-12 col-sm-12 col-lg-6">
                      <DownloadReport loading={loading} />
                    </div>
                  )}
                {((patientReportStatus === "archived" &&
                  patientCurrentReport?.snp_upload_patient) ||
                  (_.isEmpty(geneticData) &&
                    patientCurrentReport?.genetic_upload_by)) && (
                    <div className="col-12 col-sm-12 col-lg-6">
                      <div
                        className="pg-card p-3 px-4"
                        style={{ minHeight: 390, backgroundColor: "#2470c2" }}
                      >
                        <div>
                          <span dangerouslySetInnerHTML={{ __html: t('patient_dashboard_step2') }} />
                          <div className="mt-4 pt-1 w-100 text-center">
                            <button
                              className="btn btn-light px-5 mx-auto my-4 step-2-btn"
                              onClick={() => handleGeneticsNav(true)}
                            >
                              {t('patient_dashboard_txt3')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {geneticData && !_.isEmpty(geneticData) && geneticUploadBy && (
                  <div className="col-12 col-sm-12 col-lg-6 mt-1 genetics-div">
                    {geneticUploadBy && (
                      <div className="col mt-4 hello">
                        {geneticData && !_.isEmpty(geneticData) && (
                          <div
                            className="d-flex p-4 bgc-secondary text-white rounded"
                            style={{ minHeight: "450px" }}
                          >
                            <div className="col-12 p-2 position-relative text-center">
                              <div className="pg-heading text-color__p">
                                {t('genetics')}
                              </div>
                              <br />
                              <div className="col-12">
                                {geneticData?.report_status?.toLowerCase() ===
                                  "complete" ? (
                                  <p className="pg-text">
                                    {t('patient_dashboard_txt4')}
                                    <span
                                      style={{ marginLeft: 4 }}
                                      dangerouslySetInnerHTML={{
                                        __html: geneticData.vendor,
                                      }}
                                    />
                                    {t('patient_dashboard_txt5')}
                                  </p>
                                ) : (
                                  <p className="pg-text text-center">
                                    {t('patient_dashboard_txt6')}
                                  </p>
                                )}
                                <a
                                  href={geneticData.file_url}
                                  className="pg-link text-color__p"
                                  target="_blank"
                                >
                                  {t('patient_dashboard_txt7')}{" "}
                                  <i className="fas fa-chevron-right"></i>
                                </a>
                              </div>

                              {patientCurrentReport?.genetic_upload_by && (
                                <div className="btn-actions text-center w-100">
                                  <button
                                    type="button"
                                    className="btn my-4 pd-btn px-8"
                                    onClick={() =>
                                      handleGeneticsNav(
                                        geneticData.report_status === "Complete"
                                      )
                                    }
                                  >
                                    {t('patient_dashboard_txt8')}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {patientCurrentReport?.invite_questionnaire &&
                  !recentQuestionnaireData.skipped && (
                    <div className="col-12 col-sm-12 col-lg-6 mt-4 questionnaire-div">
                      {
                        <div
                          className="d-flex p-4 bgc-secondary text-white rounded"
                          style={{ minHeight: "450px" }}
                        >
                          <div className="col-12 p-2 position-relative text-center">
                            <div className="pg-heading text-color__p">
                              {t('questionnaire')}
                            </div>
                            <br />
                            <div className="col-12">
                              {questionnnaireStatus.toLowerCase() ===
                                "completed" && (
                                  <p className="pg-text">
                                    {t('patient_dashboard_txt9')}
                                  </p>
                                )}
                              {questionnnaireStatus.toLowerCase() !==
                                "completed" && (
                                  <p className="pg-text">
                                    {t('patient_dashboard_p1')}
                                  </p>
                                )}
                              {questionnnaireStatus.toLowerCase() ===
                                "completed" && (
                                  <div
                                    className="pg-link"
                                    onClick={() =>
                                      navigate("/patient/review_answer", {
                                        state: {
                                          viewOnly: true,
                                        }
                                      })
                                    }
                                  >
                                    {t('patient_dashboard_p2')}{" "}
                                    <i className="fas fa-chevron-right"></i>
                                  </div>
                                )}
                            </div>
                            <div className="btn-actions btn-res text-center w-100">
                              {(questionnnaireStatus.toLowerCase() ===
                                "started" ||
                                questionnnaireStatus.toLowerCase() ===
                                "to be reviewed") && (
                                  <button
                                    className="btn my-4 pd-btn px-8"
                                    onClick={handleResumeQuestionnaire}
                                  >
                                    {t('patient_dashboard_p3')}
                                  </button>
                                )}
                              {(questionnnaireStatus.toLowerCase() ===
                                "no data" ||
                                questionnnaireStatus.toLowerCase() ===
                                "pending") && (
                                  <div>
                                    <div className="mt-1 w-100 text-center">
                                      <button
                                        className="btn my-4 pd-primary-btn"
                                        onClick={handleQuestionnaire}
                                      >
                                        {t('patient_dashboard_p4')}
                                      </button>
                                      <button
                                        className="btn my-2 pd-btn"
                                        onClick={() =>
                                          setshowQuestionarieModal(true)
                                        }
                                      >
                                        {t('patient_dashboard_p5')}
                                      </button>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      }
                      {questionnnaireSkipped && (
                        <NewInformation
                          onDismiss={closeWhats}
                          content={whatsNewText}
                        />
                      )}
                    </div>
                  )}
                {showRecommeds && (
                  <div className="col-12 col-sm-12 col-lg-6 mt-4 information-div">
                    <div className="col-12">
                      <ShowSupplementRecomds
                        navigateToScripts={navigateToPatientScripts}
                      />
                    </div>
                  </div>
                )}
                {showRecommeds && (
                  <div className="col-12 col-sm-12 col-lg-6 mt-4 information-div">
                    <div className="col-12">
                      <OrderHistory
                        navigateToOrders={navigateToPatientOrders}
                      />
                    </div>
                  </div>
                )}
                {showWhatsNew && (
                  <div className="col-12 col-sm-12 col-lg-6 mt-4 information-div">
                    <div className="col-12">
                      <NewInformation
                        onDismiss={closeWhatsNew}
                        content={whatsNewText}
                      />
                    </div>
                  </div>
                )}
                {
                  showGeneticUploadErrorModal &&
                  <GeneticUploadErrorModal
                    showModal={showGeneticUploadErrorModal}
                    onSubmit={() => handleGeneticsNav(true)}
                    onCancel={() => setShowGeneticUploadErrorModal(false)}
                    context="patient"
                    uploadSnp={patientCurrentReport?.snp_upload_patient}
                  />
                }
                <div className="col-12 col-sm-12 col-lg-6 mt-4 support-div">
                  <SupportAndResources />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};


export default PatientReportDashboard;