import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";
import { useTranslation } from 'react-i18next'
import { validateEmail } from "../methods";

import PasswordInput from "../components/PasswordInput ";
import { changeUserPassword } from '../store/actions/practitionerAccount';
import { changePatientPassword } from '../store/actions/patientAccount';

function SetNewPasswordModal({
  onCancel,
  showModal,
  currentUserId,
  currentPractice,
  currentPracticeID,
  userType
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);

  const [isValidForm, setIsValidForm] = useState(false);
  //form fields
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");

  //for validation
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [confirmationPasswordError, setConfirmationPasswordError] = useState(false);

  const onDone = () => {
    setNewPasswordError(true);
    setConfirmationPasswordError(true);
    var requiredPasswordLength = 16
    if(userType == 'Patient'){
      requiredPasswordLength = 10
    }
    else if(userType == 'Practitioner'){
      requiredPasswordLength = 10
    }

    if (newPassword.length >= requiredPasswordLength &&
      (/(?=.*[A-Z])/.test(newPassword)) &&
      (/(?=.*[a-z])/.test(newPassword)) &&
      (/(?=.*[\W_])/.test(newPassword)) &&
      (/(?=.*\d)/.test(newPassword))) {
      setNewPasswordError(false);
    }
    if (newPassword === confirmationPassword) {
      setConfirmationPasswordError(false);
    }
    if (newPassword.length >= requiredPasswordLength &&
      (/(?=.*[A-Z])/.test(newPassword)) &&
      (/(?=.*[a-z])/.test(newPassword)) &&
      (/(?=.*[\W_])/.test(newPassword)) &&
      (/(?=.*\d)/.test(newPassword)) &&
      (newPassword === confirmationPassword)) {
      handleDone();
    }
  };

  const handleDone = async () => {
    let data = {
      old_password: oldPassword,
      password: newPassword,
      confirm_password: confirmationPassword
    };
    var response;
    if (userType === 'Practitioner') {
      response = await dispatch(changeUserPassword(token, currentPracticeID, currentPractice, currentUserId, data));
    }
    else {
      response = await dispatch(changePatientPassword(token, currentPracticeID, currentPractice, currentUserId, data));
    }
    if (!response.error) {
      onCancel();
      setIsValidForm(true);
      toastr.success("Password updated successfully");
    }
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onCancel}
      contentClassName="border-radius"
      backdrop="static"
      className="edit-patient-modal"
    >
      <Modal.Header closeButton>
        <div className="pg-text text-center">
          <div className="pg-heading my-3 mx-4 text-center">{t('npm_line_1')}</div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PasswordInput
                label={t('npm_line_2')}
                className="my-0 py-0"
                placeholder={t('npm_line_3')}
                showLabel={true}
                required={true}
                value={oldPassword}
                error={oldPasswordError}
                errorMessage={t('npm_line_4')}
                inputHandler={(e) => setOldPassword(e.target.value)}
              />

              <PasswordInput
                label={t('npm_line_5')}
                className="my-0 py-0"
                placeholder={t('npm_line_6')}
                showLabel={true}
                required={true}
                value={newPassword}
                error={newPasswordError}
                errorMessage= {
                  userType === 'Patient'
                    ? "Password must be minimum of 10 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 special character and 1 number"
                    : userType === 'Practitioner'
                    ? "Password must be minimum of 10 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 special character and 1 number"
                    : "Password must be minimum of 16 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 special character and 1 number"
                }
                inputHandler={(e) => setNewPassword(e.target.value)}
              />

              <PasswordInput
                label={t('prp_line_7')}
                className="my-0 py-0"
                placeholder={t('npm_line_6')}
                showLabel={true}
                required={true}
                value={confirmationPassword}
                error={confirmationPasswordError}
                errorMessage={t('prp_line_8')}
                inputHandler={(e) => setConfirmationPassword(e.target.value)}
              />
              <button
                className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                onClick={onDone}
              >
                {t('update')}
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SetNewPasswordModal;
