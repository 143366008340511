import { motion } from 'framer-motion';
function RecommendationCardHeader({
    context = "Diet",
    focusAreas = [],
    recommendationLength = 0,
    isEditing = false,
    setIsEditing,
    selectedItemLength = 0,
    onDeleteRecommendation,
    is_shared = false,
    setSelectedItem,
    isEditable
}) {
    const onChangeEditMode = (event) => {
        if (event.type === 'click' || (event.type === 'keydown' && (event.key === 'Enter' || event.key === ' '))) {
            setSelectedItem([]);
            setIsEditing(!isEditing);
        }
    }
    return (<>
        <div className="d-flex justify-content-between">
            <h3 className="pg4-text__subheading text-capitalize">{context}</h3>
            {isEditable && <div
                role="button"
                tabIndex="0"
                aria-label={isEditing ? "Cancel editing" : "Edit item"}
                onClick={onChangeEditMode}
                onKeyDown={onChangeEditMode}
            >
                {isEditing
                    ? <span className="cursor-pointer">
                        Done
                    </span>
                    : <i className="fas fa-pen cursor-pointer text_primary"></i>
                }
            </div>}
        </div >
        <div className="d-flex flex-wrap">
            {focusAreas?.map((fa, idx) => (
                <span className={`pill pill-${fa?.toLowerCase()}`} key={idx}>{fa}</span>
            ))}
        </div>
        <motion.div
            className="border-bottom border-top text-center mt-3 text-muted overflow-hidden"
            initial={{ height: 0 }}
            animate={{ height: (isEditing && recommendationLength > 0) ? 'auto' : 0 }}
        // transition={{ delay: 0.2 }}
        >
            <button
                className="pg4-btn pg4-btn_link  text-capitalize text_primary w-100"
                onClick={onDeleteRecommendation}
                disabled={selectedItemLength < 1}
                aria-label="Delete selected recommendations"
                aria-disabled={selectedItemLength < 1}
            >
                <span className={selectedItemLength < 1 ? 'text-muted' : ''}>
                    Delete Selected
                </span>
            </button>
        </motion.div>
    </>);
}

export default RecommendationCardHeader;