import React, { Component, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';

import { getLifeStyleSuggestionDetails } from "../../../store/actions/patientReport";
import "../../../styles/lifestyle.css";
import _ from "lodash";
import Popup from "reactjs-popup";
import i_blue from "../../../assets/i_blue.png";
import i from "../../../assets/i.png";
import increase from "../../../assets/increase.png";
import add from "../../../assets/add.png";
import decrease from "../../../assets/decrease.png";
import avoid from "../../../assets/avoid.png";
import down from "../../../assets/down.png";
import right from "../../../assets/right.png";

function NutritionDietItem({ lifestyleKey }) {

  // redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const currentReportId = useSelector(state => state.patientReport.currentReportId);


  const dispatch = useDispatch();

  // redux actions
  const getLifeStyleSuggestionDetailsAction = (token, currentPracticeID, currentPractice, reportId, lifestyleKey) => dispatch(getLifeStyleSuggestionDetails(token, currentPracticeID, currentPractice, reportId, lifestyleKey));



  const { t, i18n } = useTranslation();
  const [lifestyleDetails, setLifestyleDetails] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [increaseItems, setIncreaseItems] = useState([]);
  const [decreaseItems, setDecreaseItems] = useState([]);
  const [addItems, setAddItems] = useState([]);
  const [avoidItems, setAvoidItems] = useState([]);

  useEffect(() => {
    getLifeStyleSuggestionDetailsAction(
      token,
      currentPracticeID,
      currentPractice,
      currentReportId,
      lifestyleKey
    ).then((res) => {
      setLifestyleDetails(res.payload);
      setAdditionalConsiderations(
        "increase",
        res.payload.additional_considerations?.increase
      );
      setAdditionalConsiderations(
        "decrease",
        res.payload.additional_considerations?.decrease
      );
      setAdditionalConsiderations(
        "add",
        res.payload.additional_considerations?.add
      );
      setAdditionalConsiderations(
        "avoid",
        res.payload.additional_considerations?.avoid
      );
    });
  }, []);

  const setAdditionalConsiderations = (itemType, items) => {
    switch (itemType) {
      case "increase":
        var increseArr = [];
        for (var k in items) increseArr.push(k);
        setIncreaseItems(increseArr);
        break;
      case "decrease":
        var decreaseArr = [];
        for (var k in items) decreaseArr.push(k);
        setDecreaseItems(decreaseArr);
        break;
      case "add":
        var addArr = [];
        for (var k in items) addArr.push(k);
        setAddItems(addArr);
        break;
      case "avoid":
        var avoidArr = [];
        for (var k in items) avoidArr.push(k);
        setAvoidItems(avoidArr);
        break;
    }
  };

  return (
    <div>
      {"patient_details" in lifestyleDetails &&
        lifestyleDetails.patient_details !== "" && (
          <div className="row pt-1">
            <div className="col-lg-3 col-12 mb-3">
              <div className="pg-text__bold">{t('lifestyle_line_1')}</div>
            </div>
            <div className="col-lg-9 col-12 mb-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: lifestyleDetails.patient_details,
                }}
              />
            </div>
          </div>)}
      <div className="row pt-1">
        <div className="col-lg-3 col-12 mb-3">
          <div className="pg-text__bold">{t('lifestyle_line_2')}</div>
        </div>
        <div className="col-lg-9 col-12 mb-3">
          <div
            dangerouslySetInnerHTML={{
              __html: lifestyleDetails.primary_suggestion,
            }}
          />
        </div>
      </div>
      <div
        className="row pt-5 pt-4"
        style={{ borderTop: "0.4px solid #d8d8d8" }}
      >
        <div className="col-lg-3 col-12 mb-3">
          <div className="pg-text__bold">{t('lifestyle_line_3')}</div>
        </div>
        <div className="col-lg-9 col-12 mb-3">
          <div className="row">
            {Object.entries(
              lifestyleDetails.suggested_macronutrient_sources || {}
            ).map((data, idx) => {
              return (
                <div className="col-lg-4 col-12" key={idx}>
                  <div className="pg-text__bold">{data[0]}</div>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data[1],
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className="row pt-5 pt-4"
        style={{ borderTop: "0.4px solid #d8d8d8" }}
      >
        <div className="col-lg-3 col-12 mb-3">
          <div className="pg-text__bold">
            {t('lifestyle_line_4')}
          </div>
        </div>
        <div className="col-lg-9 col-12 mb-3">
          <div
            dangerouslySetInnerHTML={{
              __html: lifestyleDetails.suggested_macronutrient_distribution,
            }}
          />
        </div>
      </div>
      <div
        className="row pt-5 pt-4"
        style={{ borderTop: "0.4px solid #d8d8d8" }}
      >
        <div className="col-lg-3 col-12 mb-3">
          <div className="pg-text__bold">{t('lifestyle_line_5')}</div>
        </div>
        <div className="col-lg-9 col-12 mb-3">
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className="pg-text__bold mb-2">
                <img
                  src={increase}
                  style={{ marginRight: "10px", marginBottom: "7px" }}
                />
                {t('lifestyle_line_6')}
              </div>
              {increaseItems.map((increaseItem, index) => {
                return (
                  <div index={index} key={index}>
                    <div className="mb-2">
                      {increaseItem}
                      <Popup
                        trigger={(open) =>
                          open ? (
                            <img
                              src={i_blue}
                              style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                                width: "16px",
                              }}
                            />
                          ) : (
                            <img
                              src={i}
                              style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                                width: "16px",
                              }}
                            />
                          )
                        }
                        position="top center"
                        closeOnDocumentClick
                      >
                        <div style={{ padding: "12px" }}>
                          <p className="mb-0">
                            {t("trait")}:
                            {_.map(
                              lifestyleDetails.additional_considerations
                                .increase[increaseItem],
                              "trait"
                            ).join("; ")}
                          </p>
                          <p className="mb-0">
                            {t('snp')}:
                            {_.map(
                              lifestyleDetails.additional_considerations
                                .increase[increaseItem],
                              "snp"
                            ).join("; ")}
                          </p>
                        </div>
                      </Popup>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-lg-6">
              <div className="pg-text__bold mb-2">
                <img
                  src={add}
                  style={{ marginRight: "10px", marginBottom: "7px" }}
                />
                {t('lifestyle_line_7')}
              </div>
              {addItems?.map((addItem, index) => {
                return (
                  <div index={index} className="mb-2" key={index}>
                    <div>
                      {addItem}
                      <Popup
                        trigger={(open) =>
                          open ? (
                            <img
                              src={i_blue}
                              style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                                width: "16px",
                              }}
                            />
                          ) : (
                            <img
                              src={i}
                              style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                                width: "16px",
                              }}
                            />
                          )
                        }
                        position="top center"
                        closeOnDocumentClick
                      >
                        <div style={{ padding: "12px" }}>
                          <p className="mb-0">
                            Trait:{" "}
                            {_.map(
                              lifestyleDetails.additional_considerations.add[
                              addItem
                              ],
                              "trait"
                            ).join("; ")}
                          </p>
                          <p className="mb-0">
                            SNP:{" "}
                            {_.map(
                              lifestyleDetails.additional_considerations.add[
                              addItem
                              ],
                              "snp"
                            ).join("; ")}
                          </p>
                        </div>
                      </Popup>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-lg-6">
              <div className="pg-text__bold mb-2">
                <img
                  src={decrease}
                  style={{ marginRight: "10px", marginBottom: "7px" }}
                />
                {t('lifestyle_line_8')}
              </div>
              {decreaseItems?.map((decreaseItem, index) => {
                return (
                  <div index={index} className="mb-2" key={index}>
                    <div>
                      {decreaseItem}
                      <Popup
                        trigger={(open) =>
                          open ? (
                            <img
                              src={i_blue}
                              style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                                width: "16px",
                              }}
                            />
                          ) : (
                            <img
                              src={i}
                              style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                                width: "16px",
                              }}
                            />
                          )
                        }
                        position="top center"
                        closeOnDocumentClick
                      >
                        <div style={{ padding: "12px" }}>
                          <p className="mb-0">
                            {t('trait')}:{" "}
                            {_.map(
                              lifestyleDetails.additional_considerations
                                .decrease[decreaseItem],
                              "trait"
                            ).join("; ")}
                          </p>
                          <p className="mb-0">
                            {t('snp')}:{" "}
                            {_.map(
                              lifestyleDetails.additional_considerations
                                .decrease[decreaseItem],
                              "snp"
                            ).join("; ")}
                          </p>
                        </div>
                      </Popup>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-lg-6">
              <div className="pg-text__bold mb-2">
                <img
                  src={avoid}
                  style={{ marginRight: "10px", marginBottom: "7px" }}
                />
                {t('lifestyle_line_9')}
              </div>
              {avoidItems?.map((avoidItem, index) => {
                return (
                  <div index={index} className="mb-2" key={index}>
                    <div>
                      {avoidItem}
                      <Popup
                        trigger={(open) =>
                          open ? (
                            <img
                              src={i_blue}
                              style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                                width: "16px",
                              }}
                            />
                          ) : (
                            <img
                              src={i}
                              style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                                width: "16px",
                              }}
                            />
                          )
                        }
                        position="top center"
                        closeOnDocumentClick
                      >
                        <div style={{ padding: "12px" }}>
                          <p className="mb-0">
                            {t('trait')}:{" "}
                            {_.map(
                              lifestyleDetails.additional_considerations.avoid[
                              avoidItem
                              ],
                              "trait"
                            ).join("; ")}
                          </p>
                          <p className="mb-0">
                            {t('snp')}:{" "}
                            {_.map(
                              lifestyleDetails.additional_considerations.avoid[
                              avoidItem
                              ],
                              "snp"
                            ).join("; ")}
                          </p>
                        </div>
                      </Popup>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}



export default NutritionDietItem;
