import { useSelector } from "react-redux";

const NumberInput = ({ question, onInput, inputValues }) => {
    const answers = useSelector(state => state.pg4.q_answers);
    const errors = useSelector(state => state.pg4.q_errors);

    return (<div className="pg4-card">
        <div className="pg-heading__sub textc-primary__p">
            {question?.question_text}
        </div>
        <div className="pg-text">
            {question?.placeholder_en}
        </div>
        <input
            type="number"
            className="my-2 msq-input"
            onInput={(e) => onInput({ [question.label]: Number(e.target.value) })} />
        {errors[question.question_id] && <div className="text-danger my-2">
            <span>
                <i className="fas fa-info-circle me-1"></i>
                {errors[question.question_id]}
            </span>
        </div>}
    </div>);
}

export default NumberInput;