import { useSelector } from "react-redux";
import "../../../styles/PG4/pg4table.scss";
// /home/rashid/Project/pg-3.0-web/src/styles/pg4/pg4table.scss
import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { reportTableData } from "./constant";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "reactjs-popup";
import { ReactComponent as InfoIcon } from "../../../assets/info.svg";
import ReportTableFooter from "./ReportTableFooter";
import moment from "moment/moment";

const DashboardReportTable = ({ _data }) => {
    const [showFooter, setshowFooter] = useState(false);
    const tableData = Array.isArray(_data?.data) ? _data?.data : [];
    const navigate = useNavigate();
    // const tableData = useSelector(state => state.pg4.dashboard.report_table || []);

    console.log('db', { _data },)

    const columns = useMemo(() => [
        {
            Header: "Date Generated",
            accessor: "date_generated",
            Cell: (value) => {
                return (
                    //   <a className="pg-text" onClick={() => {}}>
                    <div className="position-relative">
                        {moment(value.value).format('MMM Do, YYYY')}
                    </div>
                    //   </a>
                );
            },
        },
        {
            Header: <div className="">
                MSQ Score
                <Popup
                    offsetY={10}
                    className="dashboard-report-table__popover"
                    on={['hover', 'focus']}
                    trigger={(open) => (
                        <InfoIcon
                            stroke="#fff"
                            height={30}
                            width={30}
                            className=""
                            style={{
                                marginLeft: "4px",
                                cursor: "pointer",
                            }}
                        />)
                    }
                    position="bottom center"
                    closeOnDocumentClick
                >
                    <div style={{ padding: "12px" }}>
                        The <b>Multi Symptom Questionnaire <br /> (MSQ) Score</b> is calculated
                        based on your responses. <br />
                    </div>
                </Popup>
            </div>, accessor: "msq_score"
        },
        {
            Header: "Current Status",
            accessor: "current_status",
            sortable: false,
            filterable: false,
            Cell: (value) => {
                return (
                    //   <a className="pg-text" onClick={() => {}}>
                    <div className="position-relative">
                        {String(value?.value).toLocaleLowerCase() === "completed"
                            ? <button className="pg4-btn pg4-btn_primary" onClick={() => navigate(`/patient/report/new/${value?.row?.original?.report_id}`)}>View  Report</button>
                            : value?.value}
                    </div>
                    //   </a>
                );
            },
        },
    ], []);
    const data = useMemo(() => tableData, [columns, tableData]);


    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        nextPage,
        canNextPage,
        previousPage,
        canPreviousPage,
        pageOptions,
        pageCount,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: 3,
                // sortBy: [{ id: 'date_generated', desc: true }]
            }
        },
        useSortBy,
        usePagination
    );

    return (

        <div>
            <div className="w-100 pg4-card p-0 rounded-0">


                <table table {...getTableProps()} className="w-100 pg4-table pg4-table-responsive pg4-table-striped pg4-w-100 " >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render("Header")}
                                        <span className="fs-4">
                                            {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}{" "}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                            // {rows?.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                    {/* {error && (
        <tbody>
            <tr>
                <td colSpan="3">
                    <div className="w-100 text-center">
                        {t('pracpl_error')}
                    </div>
                </td>
            </tr>
        </tbody>
    )}*/}
                    {tableData.length < 1 && (
                        <tbody>
                            <tr>
                                <td colSpan="3">
                                    <div className="w-100 text-center">No Data</div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                </table >
                {showFooter && <ReportTableFooter
                    pageSize={pageSize}
                    canNextPage={canNextPage}
                    canPreviousPage={canPreviousPage}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    setPageSize={setPageSize}
                    pageIndex={pageIndex}
                    pageOptions={pageOptions}
                />}
            </div >
            {(tableData.length > 3 && !showFooter) && <div className="d-flex">
                <div className="pg4-text textc-primary__p cp" onClick={
                    () => {
                        setshowFooter(true);
                        setPageSize(6);
                    }}>
                    View More {">"}
                </div>
            </div>}
        </div>);
}

export default DashboardReportTable;