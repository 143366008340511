import React, { useState, useEffect } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

// Action
import {
  getPractitionerDetails,
  editPractitioner,
  getPractitionerTypes,
  getPractitionerTerms,
} from "../../store/actions/practitionerAccount";
import { getCountries } from "../../store/actions/addPatient";
import { Loading } from "../../components/Loading";
import toastr from "react-hot-toast";

import { validateEmail } from "../../methods";
import L_EditPractitioner from "../../components/LoadingEffectComponent/Admin/L_EditPractitioner";
import { PgRadioButton } from "../../components/PgRadioButton";
import PgSelect from "../../components/PgSelect";
import PgSelectTooltip from "../../components/PgSelectTooltip";
import TextInput from "../../components/TextInput";
import SetNewPasswordModal from "../NewPasswordModal";
import TermsAndConditions from "../TermsAndConditions";
import PracticeTermsTable from "./PracticeTermsTable";
import { licenseNotMandatoryTypes, licenseNotNeededTypes, stateList, caStateList } from "../../constant";

function Account({ }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const practitionerCountry = useSelector((state) => state.auth.country);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const practitionersTypeList = useSelector(
    (state) => state.adminPractitioner.practitioner_types
  );
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [showTermsAndConditionsModal, setShowTermsAndConditionsModal] =
    useState(false);

  const [gettingPracInfo, setgettingPracInfo] = useState(true);
  const [countryList, setCountryList] = useState([]);
  //form fields
  const [practitionerId, setPractitionerId] = useState("");
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [healthcareProviderType, setHealthcareProviderType] = useState("");
  const [otherHealthcareProviderType, setOtherHealthcareProviderType] =
    useState("");
  const [license, setLicense] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [address3, setAddress3] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("US");
  const [userState, setUserState] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [phone, setPhone] = useState("");
  const [fax, setFax] = useState("");
  const [status, setStatus] = useState("");
  const [practiceName, setPracticeName] = useState("");

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [healthcareProviderTypeError, setHealthcareProviderTypeError] =
    useState("");
  const [
    otherHealthcareProviderTypeError,
    setOtherHealthcareProviderTypeError,
  ] = useState("");
  const [address1Error, setAddress1Error] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [disableLicense, setDisableLicense] = useState(false);
  const [requireLicense, setRequireLicense] = useState(false);
  const [licenseError, setLicenseError] = useState(false);

  const [practititionerTypesList, setPractititionerTypesList] = useState([]);
  const [practitionerTypeId, setPractitionerTypeId] = useState("");
  const [healthcareTypes, setHealthcareTypes] = useState([]);
  const [practititionerTermsList, setPractititionerTermsList] = useState([]);
  const [practititionerTermsListTable, setPractititionerTermsListTable] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(
      getPractitionerTypes(token, currentPracticeID, currentPractice)
    ).then((response) => {
      if (!response.error) {
        const practitionerTypes = response.payload.practitioner_types;
        if (practitionerCountry !== "US" && practitionerCountry !== "CA") {
          setPractititionerTypesList(
            _.filter(practitionerTypes, ['regions', ["UK/EU"]]).map((a) => a.practitioner_type)
          );
        } else if (practitionerCountry === "CA"){
          setPractititionerTypesList(
            _.filter(practitionerTypes, ['regions', ["CA"]]).map((a) => a.practitioner_type)
          );
        }
        else {
          setPractititionerTypesList(
            _.filter(practitionerTypes, ['regions', ["US"]]).map((a) => a.practitioner_type)
          );
        }
      }
    });
    dispatch(getCountries(token, currentPracticeID, currentPractice)).then(response => {
      if (!response.error) {
        var availableCountries = response.payload?.countries
        console.log(practitionerCountry, "practitionerCountry")
        if (practitionerCountry !== "US" && practitionerCountry !== "CA") {
          setCountry("")
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          _.remove(availableCountries, function (n) {
            return n === "Canada";
          });
        } else if (practitionerCountry === "CA"){
          setCountry("")
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          setCountry("Canada")
        }
        else {
          setCountry("United States")
        }        
        setCountryList(availableCountries);
      }
    });
    dispatch(
      getPractitionerDetails(
        token,
        currentPracticeID,
        currentPractice,
        currentUserId
      )
    ).then((response) => {
      if (!response.error) {
        const data = response.payload;
        setgettingPracInfo(false);
        setPractitionerId(data.id);
        setEmail(data.email || "");
        setfName(data.first_name || "");
        setlName(data.last_name || "");
        setPhone(data.phone_number || "");
        updateProviderType(data.practitioner_type_name || "");
        setPractitionerTypeId(data.practitioner_type_id || "");
        setOtherHealthcareProviderType(data.other_healthcare_provider || "");
        setLicense(data.license || "");
        setAddress1(data.address_1 || "");
        setAddress2(data.address_2 || "");
        setAddress3(data.address_3 || "");
        setCity(data.city || "");
        setCountry(data.country || "US");
        setUserState(data.state || "");
        setZipcode(data.zipcode || "");
        setFax(data.fax || "");
        setStatus(data.aasm_state || "");
        setPracticeName(data.practice_name || "");
      }
    });

    dispatch(
      getPractitionerTerms(token, currentPracticeID, currentPractice)
    ).then((response) => {
      if (!response.error) {
        setPractititionerTermsListTable(response?.payload?.terms_and_agreements);
      }
    });
  }, []);

  const onDone = () => {
    setfNameError(false);
    setlNameError(false);
    setEmailError(false);
    setHealthcareProviderTypeError(false);
    setAddress1Error(false);
    setCityError(false);
    setStateError(false);
    setCountryError(false)
    setZipcodeError(false);
    setOtherHealthcareProviderTypeError(false);
    setLicenseError(false);

    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (healthcareProviderType.length === 0)
      setHealthcareProviderTypeError(true);
    if (address1.length === 0) setAddress1Error(true);
    if (city.length === 0) setCityError(true);
    if (country.length === 0) setCountryError(true);
    if (userState.length === 0 && (practitionerCountry === "US" || practitionerCountry === "CA")) setStateError(true);
    if (zipcode.length === 0) setZipcodeError(true);
    if (
      healthcareProviderType?.length !== 0 &&
      otherHealthcareProviderType?.length === 0
    )
      setOtherHealthcareProviderTypeError(true);
    if (
      (healthcareProviderType === "Other"
        ? otherHealthcareProviderType !== ""
        : true) &&
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      healthcareProviderType.length !== 0 &&
      (requireLicense ? license.length !== 0 : true) &&
      address1.length !== 0 &&
      city.length !== 0 &&
      country.length !== 0 &&
      (userState.length !== 0 || (practitionerCountry !== "US" && practitionerCountry !== "CA")) &&
      zipcode.length !== 0
    ) {
      handleDone();
    } else {
      window.scrollTo({ top: 300, behavior: "smooth" });
    }
  };

  const handleDone = async () => {
    let data = {
      user: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email.toLowerCase(),
        other_healthcare_provider: otherHealthcareProviderType,
        license: license,
        address_1: address1,
        address_2: address2,
        address_3: address3,
        city: city,
        country: country,
        state: userState,
        zipcode: zipcode,
        phone_number: phone,
        fax: fax,
        practice_name: practiceName,
        practitioner_type_id: practitionerTypeId,
      },
    };
    dispatch(
      editPractitioner(
        token,
        currentPracticeID,
        currentPractice,
        currentUserId,
        data
      )
    ).then((response) => {
      if (response.error) {
        toastr.error(t('patient_err1'));
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
        toastr.success(t('patient_suc1'));
      }
    });
  };

  const getPractitionerTypeId = (type) => {
    const practitionerType = _.find(healthcareTypes, [
      "practitioner_type",
      type,
    ]);
    setPractitionerTypeId(practitionerType?.id);
  };

  const updateProviderType = (option) => {
    getPractitionerTypeId(option);
    setHealthcareProviderType(option);
    if (licenseNotNeededTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(true);
      setLicense("");
    } else if (licenseNotMandatoryTypes.includes(option)) {
      setRequireLicense(false);
      setDisableLicense(false);
    } else {
      setRequireLicense(true);
      setDisableLicense(false);
    }
  };

  return (
    <div className="container" id="edit-practitioner-modal">
      <Helmet>
        <title>{t('pract_account_h1')}</title>
      </Helmet>
      <div
        className="cursor-pointer textc-primary pg-link"
        onClick={() => navigate("/practice/dashboard")}
        style={{ marginTop: "20px", float: "left", width: "100%" }}
      >
        <i className="fas fa-chevron-left mr-2"></i> {t('dashboard')}
      </div>
      <div className="text-center pg-title mt-5">{t('patient_account_txt1')}</div>
      <div className="text-center pg-text__bold textc-primary">
        {t('pract_account_txt1')}
      </div>
      {gettingPracInfo ? (
        <L_EditPractitioner />
      ) : (
        <div className="row p-5 justify-content-md-center account-form">
          <div className="col-md-6">
            <TextInput
              label={t('prac_register_first_name')}
              showLabel={true}
              required={true}
              id="f-name__addpractitioner"
              placeholder={t('prac_reg_fname')}
              value={fName}
              error={fName?.trim() === ""}
              type="text"
              errorMessage={t('prac_reg_fname_req')}
              inputHandler={(e) => {
                setfName(e.target.value);
              }}
            />

            <TextInput
              label={t('prac_register_last_name')}
              showLabel={true}
              required={true}
              id="l-name__addpractitioner"
              placeholder={t('prac_reg_lname')}
              value={lName}
              error={lName?.trim() === ""}
              errorMessage={t('prac_reg_lname_req')}
              inputHandler={(e) => {
                setlName(e.target.value);
              }}
            />

            <TextInput
              label={t('prac_register_email')}
              showLabel={true}
              required={true}
              emailField={true}
              isDisable={status === "awaiting" || status === "pending"}
              id="email-input__addpractitioner"
              placeholder={t('prac_reg_email')}
              value={email}
              error={email === "" || !validateEmail(email)}
              errorMessage={t('prac_reg_email_req')}
              inputHandler={(e) => {
                setEmail(e.target.value);
              }}
            />

            <PgSelect
              label={t('prac_register_type')}
              showLabel={true}
              required={true}
              id="providerType__addpractitioner"
              setSelectedItem={updateProviderType}
              selectedItem={healthcareProviderType}
              placeholder={t('prac_reg_hp')}
              errorMessage={t('pract_account_hp_req')}
              options={practititionerTypesList}
              error={healthcareProviderType === ""}
            />
            {healthcareProviderType === "Other" && (
              <TextInput
                label={t('prac_register_other')}
                showLabel={true}
                required={healthcareProviderType === "Other"}
                id="other-type__addpractitioner"
                placeholder={t('prac_reg_ohp')}
                errorMessage={t('pract_account_hp_req')}
                value={otherHealthcareProviderType}
                error={otherHealthcareProviderType === ""}
                inputHandler={(e) => {
                  setOtherHealthcareProviderType(e.target.value);
                }}
              />
            )}

            <TextInput
              label={t('prac_register_lic')}
              showLabel={true}
              required={requireLicense}
              isDisable={disableLicense}
              id="l-license__addpractitioner"
              placeholder={t('prac_reg_lic')}
              error={requireLicense && license?.trim() === ""}
              errorMessage={t('prac_reg_lic_req')}
              value={license}
              inputHandler={(e) => setLicense(e.target.value)}
            />

            <TextInput
              label={t('prac_register_name')}
              showLabel={true}
              optional={true}
              id="practice-name__addpractitioner"
              placeholder={t('prac_reg_pn')}
              value={practiceName}
              inputHandler={(e) => {
                setPracticeName(e.target.value);
              }}
            />

            <TextInput
              label={t('prac_register_adr_1')}
              showLabel={true}
              required={true}
              id="l-address1__editpractitioner"
              placeholder={t('prac_reg_adr1')}
              value={address1}
              error={address1?.trim() === ""}
              errorMessage={t('prac_reg_adr1_req')}
              inputHandler={(e) => setAddress1(e.target.value)}
            />
            <TextInput
              label={t('prac_register_adr_2')}
              showLabel={true}
              id="l-address2__editpractitioner"
              placeholder={t('prac_reg_adr2')}
              value={address2}
              inputHandler={(e) => {
                setAddress2(e.target.value);
              }}
            />
            <TextInput
              label={t('prac_register_adr_3')}
              showLabel={true}
              id="l-address3__editpractitioner"
              placeholder={t('prac_reg_adr3')}
              value={address3}
              inputHandler={(e) => {
                setAddress3(e.target.value);
              }}
            />
            <TextInput
              label={t('prac_register_city')}
              showLabel={true}
              required={true}
              id="l-city__editpractitioner"
              placeholder={t('prac_reg_city')}
              value={city}
              error={cityError}
              errorMessage={t('prac_reg_city_req')}
              inputHandler={(e) => {
                setCity(e.target.value);
              }}
            />
            {
              (practitionerCountry === "US" || practitionerCountry === "CA") ?
                <TextInput
                  label={t('prac_register_country')}
                  showLabel={true}
                  id="l-country__addpractitioner"
                  placeholder={t('prac_reg_country')}
                  value={practitionerCountry === "CA" ? "Canada" : "United States"}
                  isDisable={true}
                  error={countryError}
                  inputHandler={(e) => {
                    setCountry(e.target.value);
                  }}
                />
                :
                <PgSelect
                  label={t('prac_register_country')}
                  showLabel={true}
                  required={true}
                  optional={false}
                  id="country__addpatient"
                  setSelectedItem={setCountry}
                  selectedItem={country}
                  placeholder={t('prac_reg_country')}
                  options={countryList}
                  error={countryError}
                />
            }
            {
              (practitionerCountry === "US" || practitionerCountry === "CA") ?
                <PgSelect
                  label={t('state')}
                  showLabel={true}
                  required={true}
                  id="state__addpractitioner"
                  setSelectedItem={setUserState}
                  selectedItem={userState}
                  placeholder={t('prac_reg_state')}
                  options={practitionerCountry === "CA" ? caStateList : stateList }
                  error={userState !== "" ? "" : stateError}
                />
                :
                <TextInput
                  label="County"
                  showLabel={true}
                  optional={true}
                  id="state__addpractitioner"
                  placeholder="Enter County"
                  value={userState}
                  inputHandler={(e) => {
                    setUserState(e.target.value);
                  }}
                />
            }
            <TextInput
              label={t('code')}
              showLabel={true}
              required={true}
              id="zipcode__addpractitioner"
              placeholder={t('prac_reg_zip')}
              value={zipcode}
              error={zipcodeError}
              errorMessage={t('prac_reg_zip_req')}
              inputHandler={(e) => {
                setZipcode(e.target.value);
              }}
            />
            <TextInput
              label={t('prac_register_phone')}
              showLabel={true}
              id="phone__addpractitioner"
              placeholder={t('prac_reg_phone')}
              value={phone}
              inputHandler={(e) => {
                setPhone(e.target.value);
              }}
            />
            <TextInput
              label={t('prac_register_fax')}
              showLabel={true}
              id="fax__addpractitioner"
              placeholder={t('prac_reg_fax')}
              value={fax}
              inputHandler={(e) => {
                setFax(e.target.value);
              }}
            />
            <button
              className="btn btn-outline px-5 my-3 w-100"
              onClick={() => setShowNewPasswordModal(true)}
            >
              {t('patient_account_txt3')}
            </button>
            {showNewPasswordModal && (
              <SetNewPasswordModal
                showModal={showNewPasswordModal}
                onCancel={() => setShowNewPasswordModal(false)}
                currentPractice={currentPractice}
                currentPracticeID={currentPracticeID}
                currentUserId={currentUserId}
                userType="Practitioner"
              />
            )}
            {showTermsAndConditionsModal && (
              <TermsAndConditions
                showModal={showTermsAndConditionsModal}
                onCancel={() => setShowTermsAndConditionsModal(false)}
              />
            )}
            <button
              className="btn btn-primary text-light px-5 my-3 w-100"
              onClick={onDone}
            >
              {t('patient_account_txt4')}
            </button>
          </div>
        </div>
      )}
      <div className="text-center pg-title mt-5">{t('pract_account_txt2')}</div>
      <div className="row p-5 justify-content-md-center">
        <div className="col-6">
          <PracticeTermsTable
            termsData={practititionerTermsListTable}
            error={false}
          />
        </div>
      </div>
    </div>
  );
}
export default Account;
