const SingSelectDropdown = ({ question, onInput, inputValues }) => {

    const onSelect = (e) => {
        let value = e.target.value;
        onInput(prevState => ({ ...prevState, [question.type]: value }))
    }

    return (<div className="pg4-card">
        <div className="pg4-text__subheading textc-primary__p">
            {question.question_text}
        </div>

        <div className="d-flex flex-column">
            <label htmlFor="select" className="text-muted mt-3">Racial Designation</label>
            <select name="pg-select" id="" className="pg4-card p-2 w-25 mt-0" onInput={onSelect} value={inputValues[question?.type]}>
                <option value="Asian">Asian</option>
                <option value="African">African</option>
                <option value="American">American</option>
                <option value="Asian">Asian</option>
                <option value="Asian">Asian</option>
            </select>
        </div>

    </div>);
}

export default SingSelectDropdown;