import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LabReviewUpdateSuccess from "./LabReviewUpdateSuccess";
import {
  getLabUploadReview,
  postLabUploadReview,
  deleteManualEntry,
  getLabTests,
  updateLabUpload,
} from "../../../store/actions/labUpload";
import L_LabReviewPage from "../../LoadingEffectComponent/L_LabReviewPage";
import DeleteConfirnationModal from "./DeleteConfirmationModal";
import PgTypeAhead from "../../PgTypeAhead";
import ExampleRow from "./ExampleRow";

const PatientReviewLabResult = ({ context = "route", modalData = {} }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [stateData, setStateData] = useState(location.state);
  const [disableDoneButton, setDisableDoneButton] = useState(true);
  const [showPop, setShowPop] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [dataValid, setDataValid] = useState(false);
  const [showExampleRow, setshowExampleRow] = useState(false);
  const [deleteRow, setDeleteRow] = useState("");
  const [inputValues, setInputValues] = useState();
  const [rowIncrement, setrowIncrement] = useState(3);
  const [data, setData] = useState(
    context === "modal" ? modalData : stateData
  );
  const userType = useSelector(state => state.auth.userType);

  // store data
  const token = useSelector((state) => state.auth.token);
  const labTests = useSelector((state) => state.labUpload.tests);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const resultReview = useSelector((state) => state.labUpload.resultReview);
  const loading = useSelector((state) => state.labUpload.isGettingResultReview);
  const currentPatientId = useSelector(
    (state) => state.auth.currentUserId
  );
  const labFileStatus = useSelector((state) => state.labUpload.fileStatus);

  // store action
  const dispatch = useDispatch();
  const postLabUploadReviewAction = (data) =>
    dispatch(
      postLabUploadReview(token, currentPracticeID, currentPractice, data)
    );
  const getLabUploadReviewAction = (patient_id, documentIds, history) =>
    dispatch(
      getLabUploadReview(token, currentPracticeID, currentPractice, patient_id, documentIds, history)
    );
  const updateLabFileStatus = (data) => dispatch(updateLabUpload(data));
  const deleteManualEntryAction = (lab_report_result_id) =>
    dispatch(
      deleteManualEntry(
        token,
        currentPracticeID,
        currentPractice,
        lab_report_result_id
      )
    );
  const getLabTestsAction = () =>
    dispatch(getLabTests(token, currentPracticeID, currentPractice));

  useEffect(() => {
    if (!data.documentIds) {
      navigate("/patient/dashboard");
    } else {
      getLabUploadReviewAction(data.patientId, data?.documentIds, context == "modal").then((response) => {
        if (!response.error) {
          setInputValues(response.payload);
          setDisableDoneButton(false);
        }
      });
      getLabTestsAction();
    }
  }, []);

  const onInput = (id, key, value) => {
    setInputValues((input_values) => {
      let c_input_values = [...input_values];
      c_input_values[_.findIndex(input_values, { id: id })][key] = value;
      c_input_values[_.findIndex(input_values, { id: id })]["edited"] = true;
      return [...c_input_values];
    });
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    let c_input_values = [...inputValues];
    if (
      c_input_values[_.findIndex(inputValues, { id: index })][
      "is_manual_entry"
      ] == false ||
      c_input_values[_.findIndex(inputValues, { id: index })]["edited"]
    ) {
      setDeleteRow(index);
      setshowDeletePopup(true);
    } else {
      removeRow(index);
    }
  };

  const removeRow = (id) => {
    const list = [...inputValues];
    let index = _.findIndex(list, { id: id });
    if (inputValues[index]["is_manual_entry"] == false) {
      deleteManualEntryAction(id).then((response) => {
        if (!response.error) {
          list.splice(index, 1);
          setInputValues(list);
        }
      });
    } else {
      list.splice(index, 1);
      setInputValues(list);
    }

    setshowDeletePopup(false);
    if (list.length <= 0) {
      setshowExampleRow(false)
    }
  };

  const generateValueRows = (count) => {
    let rows = [];
    _.times(count, (index) => {
      rows.push({
        id: `${Math.floor(Math.random() * 1000) + 1}_${index}`,
        test: "",
        result: "",
        unit: "",
        reference_range: "",
        is_manual_entry: true,
        patient_id: currentPatientId,
        patient_lab_document_id: data?.documentIds[0],
        edited: false,
      });
    });

    return rows;
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputValues([...inputValues, ...generateValueRows(rowIncrement)]);
    setrowIncrement(1);
    setshowExampleRow(true);
  };

  const validateInputValues = () => {
    setDataValid((prevValue) => {
      const list = [...inputValues];
      let valid = true;

      list.forEach((eachValue) => {
        eachValue["error_test"] = false;
        eachValue["error_result"] = false;
        if (eachValue.test === "") {
          eachValue["error_test"] = true;
          valid = false;
        }
        if (eachValue.result === "") {
          eachValue["error_result"] = true;

          valid = false;
        }
      });

      setInputValues(list);
      if (valid) {
        postData();
      } else {
        toast.error("Please enter all required fields");
      }
      return valid;
    });
    return;
  };

  const postData = () => {
    if (!data.completed)
      postLabUploadReviewAction({
        lab_session_id: data?.data?.document_id,
        data: { lab_report_results: inputValues },
      }).then((response) => {
        if (!response.error) {
          setShowPop(true);
        }
      });
    else {
      data?.from === "report"
        ? navigate(-1)
        : navigate("/patient/dashboard");
    }
  };
  const onConfirm = () => {
    validateInputValues();
  };


  const goToLabUploadPage = () => {
    navigate('/patient/lab', { state: { isOldFile: false } });
  };

  const goToPatientList = () => {
    let list_url = "/patient/dashboard";
    navigate(list_url);
  };

  let modal_text = t("dashboard");

  return (
    <div className="">
      <LabReviewUpdateSuccess
        toPatientList={() => goToPatientList()}
        toUploadPage={() => goToLabUploadPage()}
        modalText={modal_text}
        onCancel={() => setShowPop(false)}
        showModal={showPop}
      />
      <DeleteConfirnationModal
        message="Are you sure?"
        onAccept={() => removeRow(deleteRow)}
        onCancel={() => setshowDeletePopup(false)}
        showModal={showDeletePopup}
      />
      {loading ? (
        <div>
          <L_LabReviewPage />
        </div>
      ) : (
        <div>
          <div className="container-xxl">
            {context !== "modal" && (
              <div
                className="pg-link my-5"
                onClick={() => {
                  data?.from === "report"
                    ? navigate(-1)
                    : navigate("/practice/patients");
                }}
              >
                <i className="fa fa-chevron-left me-1" aria-hidden="true"></i>{" "}
                {data?.from === "report" ? "Report" : "Patients"}
              </div>
            )}
            <div className="pg-title">Lab Results</div>
            <hr className="my-4" />
            {context !== "modal" && (
              <div className="my-5">
                <div className="pg-heading d-flex justify-content-between">
                  <div>
                    {data?.completed ? "Reviewed" : "Review"} lab results
                  </div>
                  <div>
                    {!data?.completed && (
                      <button
                        className="btn btn-outline px-4 ms-2"
                        onClick={handleAddClick}
                      >
                        <i className="fas fa-plus"></i> Add Result
                      </button>
                    )}
                  </div>
                </div>
                {!data?.completed && (
                  <div className="pg-text">
                    Review the lab results and update your labs at
                    anytime either by updating the fields below or uploading a
                    new file.
                  </div>
                )}
              </div>
            )}
            <div>
              <div className="row pg-text__bold justify-content-between">
                <div className="col-3">Lab Test</div>
                <div className="col-3">Result</div>
                <div className="col-3">Unit</div>
                <div className="col-3">Reference Interval</div>
                {/* <div className="col-2">Range (max)</div> */}
              </div>
              <hr />
              {inputValues?.length < 1 && resultReview?.length < 1 && (
                <div>
                  <div className="d-flex justify-content-center w-100 pg-text__bold my-3 ">
                    No data to review
                  </div>
                  <hr />
                </div>
              )}
              {/* {(showExampleRow || inputValues?.length > 1) && !data.completed && (
                <div>
                  <ExampleRow/>
                  <hr/>
                </div>
              )} */}
              {inputValues?.map((labdata, i) => (
                <div key={i}>
                  <div className="row ">
                    {!data?.completed && labdata.is_manual_entry ? (
                      <div className="col-3 my-auto">
                        <PgTypeAhead
                          label="Lab Test Name"
                          showLabel={false}
                          required={false}
                          optional={false}
                          textBoxName="test"
                          objectKey="name"
                          index={labdata.id}
                          id="test_"
                          onSelect={onInput}
                          selectedItem={labdata.test}
                          placeholder="Test Name"
                          options={labTests}
                          isDisable={data?.completed}
                          error={labdata?.error_test}
                          errorMessage="This field is required"
                        />
                      </div>
                    ) : (
                      <div className="col-3 my-auto">{labdata?.test}</div>
                    )}
                    <div className="col-3 pe-lg-5">
                      <TextInput
                        value={labdata.result}
                        error={labdata.error_result}
                        errorMessage="This field is required"
                        placeholder="Result"
                        isDisable={data?.completed}
                        inputHandler={(e) =>
                          onInput(labdata.id, "result", e.target.value)
                        }
                      />
                    </div>
                    <div className="col-3 pe-lg-5">
                      <TextInput
                        value={labdata.unit}
                        inputHandler={(e) =>
                          onInput(labdata.id, "unit", e.target.value)
                        }
                        isDisable={data?.completed}
                        placeholder="Unit"
                      />
                    </div>
                    <div className="col-3 d-flex justify-content-between">
                      <div>
                        <TextInput
                          isDisable={data?.completed}
                          value={labdata.reference_range}
                          inputHandler={(e) =>
                            onInput(
                              labdata.id,
                              "reference_range",
                              e.target.value
                            )
                          }
                          placeholder="Reference Interval"
                        />
                      </div>
                      <div className="align-self-center">
                        {!data?.completed && labdata.length !== 1 && (
                          <i
                            className="fas fa-minus cursor-pointer textc-primary"
                            onClick={() => handleRemoveClick(labdata.id)}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
              {!dataValid && <div></div>}
              <div>
                {!data?.completed && (
                  <button
                    className="btn btn-outline px-4 ms-2 mb-3"
                    onClick={handleAddClick}
                  >
                    <i className="fas fa-plus"></i> Add Result
                  </button>
                )}
              </div>
            </div>
          </div>
          {context !== "modal" && (
            <div className="row justify-content-center bgc-secondary py-3">
              <div className="col-lg-4 col-md-6 col-10">
                <button
                  className="w-100 btn bg-sky-blue px-5 textc-primary py-2"
                  disabled={disableDoneButton}
                  onClick={onConfirm}
                >
                  {!data?.completed ? "Confirm" : "Go Back"}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PatientReviewLabResult;

function TextInput({
  type,
  inputHandler,
  placeholder,
  error,
  errorMessage,
  value,
  isDisable,
}) {
  const style = {
    padding: 15,
    width: "100%",
    outline: "none",
    border: error ? "1.2px solid #f00" : "1px solid #888888",
    borderRadius: "8px",
    height: 50,
    transition: "all .3s ease-in-out",
  };

  return (
    <div className="w-90">
      <input
        placeholder={placeholder}
        type={type}
        onInput={inputHandler}
        value={value}
        style={style}
        disabled={isDisable}
      />
      {/* <div
        className="text-danger mx-2 my-1 transition-all"
        style={error ? { opacity: 1 } : { opacity: 0 }}
      >
        <i className="fas fa-info-circle"></i> {errorMessage}
      </div> */}
    </div>
  );
}
