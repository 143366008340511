import React from "react";
import { useTranslation } from "react-i18next";
import { PgProgressBar } from "../../Patient/PgProgressBar";
import { useSelector } from "react-redux";

function AfterUploadStarted({
	onTryAgain,
	error,
	status,
	uploadType,
	errorText,
	message
}) {
	const { t, i18n } = useTranslation();
	const userType = useSelector(state => state.auth.userType);
	let _status =
		status?.toLowerCase() === "processing"
			? "analyzing"
			: status?.toLowerCase();
	_status =
		status?.toLowerCase() === "error"
			? "uploading"
			: _status;
	return (
		<div className="container-xl">
			<div className="row justify-content-center my-5">
				<div className="col-12 col-lg-6 text-center">
					<div class="pg-heading">SOMETHING GOOD IS COMING YOUR WAY!</div>
					<div class="pg-text__bold textc-primary text-center my-4">
						{
							(userType == "User")
								? `Thank you for uploading your patient data to ${i18n.language === "en_gb" ? 'PureGenomics' : 'PureInsight'}. Please be patient while your report is being generated.`
								: `Thank you for uploading your data to ${i18n.language === "en_gb" ? 'PureGenomics' : 'PureInsight'}. Please be patient while your report is being generated.`

						}
					</div>
				</div>

				<div className="col-12 col-lg-12 border progress-status-wrapper">
					<div className="row pg-heading__sub text-center textc-primary progress-status-title">
						<div
							className="col-3 border py-2"
							style={{
								background:
									_status === "uploading"
										? "#14328c"
										: _status === "importing" ||
											_status === "analyzing" ||
											_status === "complete"
											? "#e9f1f9"
											: "#fff",
								color: _status === "uploading" ? "#fff" : "#14328c",
							}}
						>
							{t('patient_afstart_txt2')}
						</div>
						<div
							className="col-3 border py-2"
							style={{
								background:
									_status === "importing"
										? "#14328c"
										: _status === "importing" ||
											_status === "analyzing" ||
											_status === "complete"
											? "#e9f1f9"
											: "#fff",
								color: _status === "importing" ? "#fff" : "#14328c",
							}}
						>
							{t('patient_afstart_txt3')}
						</div>
						<div
							className="col-3 border py-2"
							style={{
								background:
									_status === "analyzing"
										? "#14328c"
										: _status === "analyzing" || _status === "complete"
											? "#e9f1f9"
											: "#fff",
								color: _status === "analyzing" ? "#fff" : "#14328c",
							}}
						>
							{t('patient_afstart_txt4')}
						</div>
						<div
							className="col-3 border py-2"
							style={{
								backgroundColor: _status === "complete" ? "#14328c" : "#fff",
								color: _status === "complete" ? "#fff" : "#14328c",
							}}
						>
							{t('patient_snp_txt1')}
						</div>
					</div>
					{error ? (
						<div className="text-center">
							<div className="pg-text__bold text-center my-lg-4 my-1 mt-lg-5 textc-primary">
								{
									uploadType === "genetic" ?
										<div className="genetic-error-message"
											dangerouslySetInnerHTML={{ __html: t('lab_and_genetic_line_2') }} />
										: <div>{errorText}</div>
								}
								<div className="my-5" />
							</div>
							<button
								className="btn btn-primary w-50 mt-0 mt-lg-4 mb-5"
								onClick={onTryAgain}
							>
								{t('try_again')}
							</button>
							<div dangerouslySetInnerHTML={{ __html: t('support_line') }} />
						</div>
					) : (
						<div className="row p-3">
							<div className="d-flex justify-content-between  my-2">
								<div className="pg-text__bold">{t('lab_and_genetic_line_4')} {uploadType} {t('lab_and_genetic_line_5')} {_status}</div>
								<div className="pg-text textc-primary">{message}</div>
							</div>
							<div className="col-12">
								<PgProgressBar status={_status} color="progress_bar" />
								<div className="pg-text__small text-center mt-5">
									{_status !== "uploading" &&
										t('lab_and_genetic_line_3')}
								</div>
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default AfterUploadStarted;
