import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { connect, useSelector, useDispatch } from "react-redux";
import toast from 'react-hot-toast';
import { getPatientList, deletePatients } from "../../store/actions/practiceDashboard";
import PgMultiSelectSearch from "../../components/PgMultiSelectSearch";
import _ from 'lodash'

function DeletePatientModal({ onReject, showModal }) {
  const dispatch = useDispatch();

  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);

  const [patientNameError, setPatientNameError] = useState(false);
  const [patientsNameList, setPatientsNameList] = useState([]);
  const [patientName, setPatientName] = useState("");

  const [patients, setPatients] = useState([]);

  const onDone = () => {
    setPatientNameError("");
    if (patients.length === 0) {
      setPatientNameError("Select at least one patient");
    }
    else{
      const data = {request_type: "delete_data", patient_ids: _.map(patients, 'id')}
      dispatch(deletePatients(token, currentPracticeID, currentPractice, data))
      onReject();
      toast.success('Request sent successfully.')
    }
  };

  const onPatientSelect = (patient) => {
    if(Object.keys(patient).length !== 0){
      if (!(patients.filter(p => p.id === patient.id).length > 0)) {
        setPatients([...patients, patient])
      }
    } 
  };

  const onPatientRemove = (patient) => {
    if(Object.keys(patient).length !== 0) setPatients(patients.filter(option => option != patient))
  };

  useEffect(() => {
    dispatch(getPatientList(token, currentPracticeID, currentPractice)).then((response) => {
      if (!response.error) {
        const patientList = response.payload.patient_list;
        setPatientsNameList(patientList);
      }
    });
  }, []);

  return (
    <Modal
      centered
      show={showModal}
      size="lg"
      onHide={onReject} bsPrefix="border-radius modal" contentClassName="border-radius" style={{ borderRadius: 8 }}>
      <div className="p-3">
        <div className="pg-text__bold py-4">Please note that the patient will receive an email notifying them that you have requested the deletion of their account. Since this process is not reversible, please instruct the patient to download a copy of their report before clicking confirm. If you are not sure which option to select, please contact customer support: support@pure-encapsulations.co.uk.</div>
        <PgMultiSelectSearch
          label="Patient"
          errorMessage={patientNameError}
          id="practitioner-name__addpatient"
          onSelect={onPatientSelect}
          onRemove={onPatientRemove}
          selectedItem={patients}
          placeholder="Enter Patients"
          options={patientsNameList}
          objectKey="full_name"
          objectKey2="email"
          error={patientName != "" ? "" : patientNameError}
        />
        <div className="d-flex justify-content-end mt-4">
          <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>Go Back</button>
          <button className="btn bgc-primary text-light px-5" onClick={() => onDone()}>Confirm</button>
        </div>
      </div>
    </Modal>
  );
}

export default DeletePatientModal;