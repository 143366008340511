import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import PgRatings from "../../PgRatings";
import WarningIcon from "../../../assets/warning.png";
import EnhanceIcon from "../../../assets/enhance.png";
import NewBadgeIcon from "../../../assets/newBadgeIcon.png";
import ActionImage from "../../ActionImage";
import TraitDiagramModel from './TraitDiagramModel';
import { t } from "i18next";

const AffectedSnips = ({ data,patientResult, userType }) => {
	const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
	const [traitDiagram, setTraitDiagram] = useState([])
	const [isUrl, setIsUrl] = useState(false)
	const [showTraitDiagram, setShowTraitDiagram] = useState(false)

	const currentPatientId = useSelector(state => state.patientReport.currentPatientId);


	return (
		<div>
			<TraitDiagramModel show={showTraitDiagram} onClose={() => setShowTraitDiagram(false)} diagramArray={traitDiagram} isUrl={isUrl}/>
			<hr className="my-5" />
			<div className="pg-heading d-inline-block" style={patientTextColor}>
				{t('trait_line_2')}
			</div>{" "}
			{/* <span className="align-warning-icon">
				<ActionImage action={patientResult} />
			</span> */}
			{data?.length < 1 ? (
					<div className="pg-text">{t('pracpl_line_12')}</div>
			) : (
				data?.map((affectedSnip, i) =>  {
				return (
					<div className="pg-text" key={i}>
						<br />
						<div className="pg-text__bold d-inline-block">
							{affectedSnip.title}{" "}
						</div>
						{affectedSnip.is_new && (
							<img src={NewBadgeIcon} alt="" className="ms-2 mb-1" />
						)}
						{affectedSnip?.diagrams?.length > 0 && (
							<div
								className="pg-link"
								onClick={() => {
									setIsUrl(affectedSnip.is_url)
									setTraitDiagram(affectedSnip.diagrams);
									setShowTraitDiagram(true);
								}}
								style={patientTextColor}
							>
								{t('pracrs_btn_9')} <i className="fa fa-chevron-right mx-2" aria-hidden="true"></i>
							</div>
						)}
						<div className="pg-text">
							{t('trait_line_3')}: {affectedSnip.risk_variant.join(", ")}
						</div>
						<div>
							{t('gene_analysis_td_3')}: {affectedSnip.patient_variant}{" "}<ActionImage action={affectedSnip.snp_result} />
							{affectedSnip.patient_variant_action && (
								<img src={WarningIcon} alt="" className="mx-2 mb-1" />
							)}
							{affectedSnip.enhanced_benefits && (
								<img src={EnhanceIcon} alt="" className="mb-1" />
							)}
						</div>
						<div>
							<span>{t('gene_analysis_td_5')}: </span>
							<PgRatings rated={affectedSnip.scientific_rating} size="18px" style={patientTextColor} />
						</div>
					</div>
				);
				})
			)}
		</div>
	);
};

export default AffectedSnips;
