import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import TextInput from "../../TextInput";
import L_EditPractitioner from "../../LoadingEffectComponent/Admin/L_EditPractitioner";
import { validateEmail } from "../../../methods";

import {
  getPatientDetails,
  editPatient,
  gdprManageDataRequest
} from "../../../store/actions/patientList";
import { Link } from "react-router-dom";
import { getCountries } from "../../../store/actions/addPatient";
import PgCheckbox from "../../PgCheckbox";
import PgSelectTooltip from "../../PgSelectTooltip";
import ReactDatePicker from "react-datepicker";
import { months, years } from "../../../methods/constant";
import moment from "moment";
import PgSelect from "../../PgSelect";

function EditPatientModal({
  onCancel,
  showModal,
  patientId,
  currentPractice,
  currentPracticeID,
  updatePatientList,
}) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const lang = useSelector((state) => state.auth.language);
  const userPermissions = useSelector(state => state.auth.userPermissions);

  //  for gdpr
  const [showActionPrompt, setshowActionPrompt] = useState(false);
  const [actionPromptMessage, setactionPromptMessage] = useState("");
  const [currentActionType, setCurrentActionType] = useState(null);
  const practitionerCountry = useSelector((state) => state.auth.country);

  const [gettingPatInfo, setgettingPatInfo] = useState(true);
  const [countryList, setCountryList] = useState([]);

  const [isValidForm, setIsValidForm] = useState(false);
  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [archive, setArchive] = useState(false);
  const [prevEmail, setPrevEmail] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [status, setStatus] = useState("");
  const [dob, setDOB] = useState(null);
  const [gender, setGender] = useState("");
  const [skincolor, setSkinColor] = useState("");

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState(t('auth_page_email_error'));
  const [countryError, setCountryError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [skinColorError, setskinColorError] = useState("");
  const [genderError, setGenderError] = useState("");

  const [patientType, setPatientType] = useState("")

  const [GdprRequest, setGdprRequest] = useState(false);

  useEffect(() => {
    if (userPermissions?.some(item => item.hasOwnProperty('GdprRequest'))) {
      setGdprRequest(true)
    }
  }, []);

  const onDone = () => {
    setEmailError(false);
    setfNameError(false);
    setlNameError(false);
    setCountryError(false);
    setDobError("");
    setGenderError("");
    setskinColorError("");

    if (email.length === 0 || !validateEmail(email)) {
      setEmailError(true);
      setEmailErrorMessage(t('auth_page_email_error'))
    }
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (country.length === 0) setCountryError("Country is required");
    if (!_.isDate(dob) && patientType !== "test") setDobError('Select Valid Date!');
    if (gender.length === 0) setGenderError(t('pracand_gender_error'));
    if (!skincolor) setskinColorError(t('Skin color is required'));
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      country.length != 0 &&
      skincolor &&
      (patientType === "test" || _.isDate(dob))
    ) {
      status === "active"
        ? prevEmail === email
          ? handleDone()
          : setShowPopup(true)
        : handleDone();
    }
  };

  useEffect(() => {
    dispatch(
      getPatientDetails(token, currentPracticeID, currentPractice, patientId)
    ).then((response) => {
      if (!response.error) {
        setgettingPatInfo(false);
        const data = response.payload;
        setStatus(data.aasm_state);
        setGender(data.gender);
        setSkinColor(data.skin_color);
        setDOB(data.dob ? new Date(data.dob) : null)
        setEmail(data.email);
        setPrevEmail(data.email);
        setfName(data.first_name);
        setlName(data.last_name);
        setCountry(data.country);
        setArchive(data.archive);
        setPatientType(data.patient_type);
      }
    });
    dispatch(getCountries(token, currentPracticeID, currentPractice)).then(response => {
      if (!response.error) {
        var availableCountries = response.payload?.countries
        if (practitionerCountry !== "US" && practitionerCountry !== "CA") {
          // setCountry("")
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          _.remove(availableCountries, function (n) {
            return n === "Canada";
          });
        } else if (practitionerCountry === "CA"){
          setCountry("")
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          setCountry("Canada")
        }
        else {
          setCountry("United States")
        }
        setCountryList(availableCountries);
      }
    });
  }, []);

  const handleDone = async () => {
    let data = {
      patient: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        country: country,
        dob: moment(dob).format(),
        email: email.toLowerCase(),
        gender: gender,
        skin_color: skincolor,
      },
    };
    if (archive) data.patient.aasm_state = "archived";
    const response = await dispatch(
      editPatient(token, currentPracticeID, currentPractice, patientId, data)
    );
    if (!response.error) {
      if (response.payload.email_error) {
        setShowPopup(false)
        setEmailError(true);
        setEmailErrorMessage(t('pracand_email_error'))
      }
      else {
        onCancel();
        setIsValidForm(true);
        updatePatientList();
        toastr.success("Patient updated successfully");
      }
    }
  };

  // GDPR section
  const gdprRequest = (pld) => dispatch(gdprManageDataRequest(token, currentPracticeID, currentPractice, pld))

  const gdprRequestFn = (message, actionType) => {
    setactionPromptMessage(message);
    setCurrentActionType(actionType);
    setshowActionPrompt(true)
  }

  const gdprRequestAPIFn = () => {
    gdprRequest({
      request_type: currentActionType,
      patient_ids: [patientId]
    }).then(response => {
      setshowActionPrompt(false);
      if (!response.error)
        toastr.success('Request sent successfully.')
    })
  }

  return (
    <div>
      <Modal
        show={showModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius"
        backdrop="static"
        className="edit-patient-modal"
      >
        <Modal.Header className="w-100 d-flex justif-content-between">
          <div
            style={{
              position: "absolute",
              right: 25,
              top: 15,
              fontSize: 20,
              cursor: "pointer",
              color: "var(--pg_primary)",
            }}
            onClick={onCancel}
          >
            <div className="fas fa-times"></div>
          </div>
          <div className="pg-text text-center">
            <div className="pg-heading my-3 mx-4 text-center">{t('pracpl_ep_line_1')}</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {gettingPatInfo ? (
            <L_EditPractitioner />
          ) : (
            <div className="container" style={lang === "en_gb" ? { overflowY: 'scroll', height: '80vh' } : {}}>
              {showActionPrompt && <ActionPrompt
                show={showActionPrompt}
                onReject={() => setshowActionPrompt(false)}
                message={actionPromptMessage}
                onAccept={gdprRequestAPIFn}
              />}
              <div className="row">
                <div className="col-12">
                  <TextInput
                    label={t('pracand_fname')}
                    showLabel={true}
                    required={true}
                    id="f-name__addpatient"
                    placeholder={t('pracand_fname_ph')}
                    value={fName}
                    error={fNameError}
                    type="text"
                    errorMessage={t('pracand_fname_error')}
                    inputHandler={(e) => {
                      setfName(e.target.value);
                    }}
                  />

                  <TextInput
                    label={t('pracand_lname')}
                    showLabel={true}
                    required={true}
                    id="l-name__addpatient"
                    placeholder={t('pracand_lname_ph')}
                    value={lName}
                    error={lNameError}
                    errorMessage={t('pracand_lname_error')}
                    inputHandler={(e) => {
                      setlName(e.target.value);
                    }}
                  />

                  <TextInput
                    label={t('pracand_email')}
                    showLabel={true}
                    required={true}
                    isDisable={patientType === "test"}
                    emailField={true}
                    id="email-input__addpatient"
                    placeholder={t('pracand_email_ph')}
                    value={email}
                    error={emailError}
                    errorMessage={emailErrorMessage}
                    inputHandler={(e) => {
                      if (
                        e.target.value.length < 0 &&
                        !validateEmail(e.target.value)
                      ) {
                        setEmailError(true);
                        setEmailErrorMessage(t('auth_page_email_error'))
                      } else {
                        setEmailError(false);
                        setEmail(e.target.value);
                      }
                    }}
                  />

                  {(patientType !== "test") && <div className="my-2 dateVal">
                    <div className="d-inline-block pg-text__bold textc-primary">Date Of Birth</div>
                    <ReactDatePicker
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <select
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(value)}
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                      onChange={(date) => {
                        setDOB(date);
                      }}
                      style={{ width: "100%" }}
                      value={dob}
                      dateFormat="MM/dd/yyyy"
                      onChangeRaw={() => { setDOB("") }}
                      selected={dob}
                      className={(_.isEmpty(dobError)) ? "dateFld" : "dateFld error"}
                      placeholderText="Enter DOB MM/DD/YYYY"
                      minDate={new Date("1900-01-01")}
                      maxDate={new Date()}
                      popperPlacement="bottom-start"
                      disabled={patientType === "test"}
                      popperModifiers={{
                        flip: {
                          behavior: ["bottom"]
                        },
                        preventOverflow: {
                          enabled: false
                        },
                        hide: {
                          enabled: false
                        }
                      }}
                    />
                    <div className="text-danger mx-2 my-1 transition-all" style={!(_.isEmpty(dobError)) ? { opacity: 1 } : { opacity: 0, minHeight: '16px' }} >
                      {!(_.isEmpty(dobError)) && <div dangerouslySetInnerHTML={{ __html: `<i class="fas fa-info-circle me-2"></i>` + dobError }}></div>}
                    </div>
                  </div>}

                  <div className="d-inline-block pg-text__bold textc-primary">{t('pracand_gender')}</div>

                  <PgSelect
                    label={t('pracand_gender')}
                    style={{ marginTop: "20% !important", marginBottom: "20% !important" }}
                    showLabel={false}
                    required={true}
                    optional={false}
                    id="gender__addpatient"
                    setSelectedItem={setGender}
                    selectedItem={gender}
                    placeholder={t('pracand_gender_ph')}
                    practiceAddPatient={true}
                    options={["Male", "Female", "Intersex"]}
                    error={gender !== "" ? "" : genderError}
                  />
                  <div className="d-inline-block pg-text__bold textc-primary">{t('Patient Skin Color ')}
                  </div>
                  <span className="textc-primary fst-italic">{t(' (Required for Vitamin D Polygenic Scoring)')}</span>
                  <PgSelect
                    label={t('Patient Skin Color')}
                    showLabel={false}
                    required={true}
                    optional={false}
                    id="skincolor__addpatient"
                    setSelectedItem={setSkinColor}
                    selectedItem={skincolor}
                    placeholder={t('Patient Skin Color')}
                    options={["Dark", "Medium", "Light"]}
                    practiceAddPatient={true}
                    error={skincolor ? "" : skinColorError}
                  />
                  {
                    ((practitionerCountry !== "US") && (practitionerCountry !== "CA")) &&
                    <PgSelectTooltip
                      required={true}
                      optional={false}
                      id="country__addpatient"
                      setSelectedItem={setCountry}
                      selectedItem={country}
                      placeholder="Enter Patient Country"
                      options={countryList}
                      error={countryError}
                      tooltipText="Patient location is required for implementation of GDPR"
                    />
                  }
                  <PgCheckbox
                    name="archive"
                    id="checkbox-archive"
                    checked={archive}
                    onCheck={() => setArchive(!archive)}
                    title={t('pracpl_ep_line_2')}
                  />
                  <button
                    className="btn btn-primary text-light px-5 my-1 mx-1  w-100"
                    onClick={onDone}
                  >
                    {t('update')}
                  </button>
                </div>
                {/* gdpr start */}
                {lang === "en_gb" &&
                  <div>
                    <hr className="my-4" />
                    <div className="col-12">
                      <div className="pg-heading text-center my-3">
                        Manage Patient Data
                      </div>
                      <div className="pg-text fw-bold">
                        The General Data Protection Regulation (“GDPR”) which took effect on May 25, 2018 creates comprehensive data protection rules pertaining to personal data. The following tools are provided to assist you in complying with individual requests that fall under the GDPR’s protection.
                        <br />
                        For further information please review the <Link to="/patient/gdpr-faqs"> GDPR FAQs</Link>
                      </div>
                      <div>
                        <div className="row my-3 mt-5 mt-md-3 justify-content-center">
                          <div className="col-12 col-md-6 my-auto">
                            <button
                              onClick={() => gdprRequestFn(
                                'This will provide the patient with a CSV file (in machine readable format) with their PureGenomics record. The patient will receive a notification to log in to PureGenomics and securely download the report. If the patient prefers a visually formatted report please let the patient know that they may download the report from the PureGenomics patient portal or you may download a formatted copy of the patient report from the top right of the Patients Report Summary.',
                                'request_data')}
                              className='btn btn-primary w-100'
                            >Request Patient Record</button>
                          </div>
                          {/* <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                        This will provide the patient with a CSV file (in machine readable format) with their PureGenomics record. The patient will receive a notification to log in to PureGenomics and securely download the report. If the patient prefers a visually formatted report please let the patient know that they may download the report from the PureGenomics patient portal or you may download a formatted copy of the patient report from the top right of the Patients Report Summary.
                      </div> */}
                        </div>
                        <div className="row my-3 mt-5 mt-md-3 justify-content-center">
                          <div className="col-12 col-md-6 my-auto">
                            <button
                              onClick={() => gdprRequestFn(
                                'This request will permanently delete all of this patients data from the PureGenomics system. We will send a notification to the patient letting them know that this request has been made by you as the data controller. The patient will have 30 days to log in and download their information after which we will delete the entire patient record and all identifying information. This request is permanent and cannot be reversed.',
                                'delete_data'
                              )}
                              className='btn btn-primary w-100'>Delete Patient Record</button>
                          </div>
                          {/* <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                        This request will permanently delete all of this patients data from the PureGenomics system. We will send a notification to the patient letting them know that this request has been made by you as the data controller. The patient will have 30 days to log in and download their information after which we will delete the entire patient record and all identifying information. This request is permanent and cannot be reversed.
                      </div> */}
                        </div>
                        <div className="row my-3 mt-5 mt-md-3 justify-content-center">
                          <div className="col-12 col-md-6 my-auto">
                            <button
                              onClick={() => gdprRequestFn(
                                'This request will inactivate the patients PureGenomics account and prevent any further processing of their data. The data will not be permanently deleted and the patient account may be re-activated at a later date. We suggest that before you inactivate this patient you instruct the patient to download any reports they wish to retain or download a copy of the report for the patient from the top right of the patient’s Report Summary page. Please note that a notification will be sent to the patient letting them know of this request.',
                                'inactivate_account')}
                              className='btn btn-primary w-100'
                            >Inactivate Patient Account</button>
                          </div>
                          {/* <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                        This request will inactivate the patients PureGenomics account and prevent any further processing of their data. The data will not be permanently deleted and the patient account may be re-activated at a later date. We suggest that before you inactivate this patient you instruct the patient to download any reports they wish to retain or download a copy of the report for the patient from the top right of the patient’s Report Summary page. Please note that a notification will be sent to the patient letting them know of this request.
                      </div> */}
                        </div>

                      </div>
                      <div className="text-center my-3">
                        <Link to="/practice/patients/gdpr" className='fw-bold pg-link'>View the status of Data Management requests</Link>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {status === "active" && showPopup && (
        <Modal
          show={showPopup}
          style={
            showPopup && {
              background: "rgba(0, 0, 0, 0.5)",
              backdropFilter: "blur(.1)",
            }
          }
          centered
          aria-labelledby="contained-modal-title-vcenter"
          contentClassName="border-radius"
          backdrop="static"
          className="mx-2"
          size="lg"
        >
          <Modal.Header>
            <div
              style={{
                position: "absolute",
                right: 25,
                top: 15,
                fontSize: 30,
                cursor: "pointer",
                color: "#14328c",
              }}
              onClick={() => setShowPopup(false)}
            >
              <div className="fas fa-times "></div>
            </div>
            <div className="pg-text" dangerouslySetInnerHTML={{ __html: t('pracpl_ep_line_3') }} />
          </Modal.Header>

          <div className="d-flex justify-content-end py-1">
            <button
              className="btn btn-outline px-5 my-1 mx-1"
              onClick={() => setShowPopup(false)}
            >
              {t('no')}
            </button>
            <button
              className="btn btn-primary px-5 me-3 my-1 mx-1"
              onClick={() => handleDone()}
            >
              {t('yes')}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default EditPatientModal;

export const ActionPrompt = ({ show, onAccept, onReject, message, acceptLabel = 'Confirm', rejectLabel = 'Go Back' }) => {
  return (
    <Modal
      style={{
        background: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(.1)",
      }}
      centered
      show={show}
      onHide={onReject}
      bsPrefix="border-radius modal modal-2"
    >
      <div className="p-3">
        <div className="pg-text__bold">{message}</div>
        <div className="d-flex justify-content-end mt-4">
          <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>{rejectLabel}</button>
          <button className="btn bgc-primary text-light px-5" onClick={onAccept}>{acceptLabel}</button>
        </div>
      </div>
    </Modal>)
}