import { Modal } from "react-bootstrap";
function GeneticUploadSuccess({ onAccept, onCancel, showModal }) {
  return (
    <Modal
      show={showModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
      contentClassName="border-radius"
    >
      <Modal.Body>
        <div className="pg-text ">
          <div className="pg-heading my-3">Thank you</div>
          Thank you for uploading your patient genetics file; this is the basis for our
          recommendations. You can continue to provide more information, or be
          done for now. You can always come back and provide more information
          later
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn bg-none text-dark px-5" onClick={onCancel}>
          Cancel
        </button>
        <button className="btn bgc-primary text-light px-5" onClick={onAccept}>
          OK
        </button>
      </Modal.Footer>
    </Modal>
  );
}
export default GeneticUploadSuccess;
