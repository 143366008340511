
import { useEffect, useState } from "react";
import { sample_report_summary } from "../../Patient/PG4/constant"
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import FocusCard from "./FocusCard";
import { useSelector } from "react-redux";

export default function FocusAreasContainer({ data }) {
    const [focusAreas, setfocusAreas] = useState(data);
    const [showAll, setshowAll] = useState(false);
    const userType = useSelector(state => state.auth.userType);

    useEffect(() => {
        if (showAll) setfocusAreas(data);
        else setfocusAreas(data?.slice(0, 3));

    }, [showAll, data]);

    // useEffect(() => {
    //     console.log({ data });
    //     if (data) setfocusAreas(data)
    // }, [data]);

    return (
        <div className="my-5">
            <div className="pg4-text__title text_primary">
                Focus Areas
            </div>
            <div className="d-flex justify-content-between my-2 flex-wrap">
                <div className="pg4-text">
                    Our algorithm has prioritized the selected categories as suggested focus areas.
                </div>
                {userType === "User" && <div className="w-sm-100 mt-4 mt-lg-0 d-flex justify-content-between justify-content-lg-end">
                    <span className="me-2 pg4-text__sm">
                        View all focus areas
                    </span>
                    <label className="pg4-switch">
                        <input type="checkbox" defaultChecked={showAll} onChange={() => setshowAll(!showAll)} />
                        <span className="pg4-switch__slider"></span>
                    </label>
                </div>}
            </div>
            <TransitionGroup className="row justify-content-start g-1 g-md-5 my-2 my-lg-3 transition-all overflow-hidden">
                {focusAreas?.map((focusArea, index) => {
                    const reversedIndex = focusAreas.length - index - 1;
                    return <CSSTransition key={index} timeout={500} classNames="fade" style={{
                        transitionDelay: `${index * 100}ms`, '--fade-exit': `${reversedIndex * 50}ms`
                    }}>
                        <div className="col-12 col-md-6 col-lg-4">
                            <FocusCard data={focusArea} />
                        </div>
                    </CSSTransition>
                })}
            </TransitionGroup>
        </div>
    )
}


