export default function CustomBadgePill({ title, isActive, setActive }) {
  const isActiveClass = isActive
    ? "cursor-pointer badge rounded-pill mx-1 py-2 px-3 border fw-light bgc-primary text-light"
    : "cursor-pointer badge rounded-pill mx-1 py-2 px-3 border fw-light bg-light textc-primary";
  return (
    <span className={isActiveClass} onClick={setActive}>
      {title}
    </span>
  );
}
