import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useSelector, useDispatch } from 'react-redux';
import PracticePatientLogTable from '../../components/Practice/GDPR/PracticePatientLogTable';
import { getPracticeAllPatientGDPRLog } from '../../store/actions/practiceGDPR';

function PracticePatientGDPRLogs() {
    const [patientData, setpatientData] = useState([]);
    const [error, setError] = useState(false);
    const [totalData, setTotalData] = useState(0)
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const currentPractice = useSelector((state) => state.auth.currentPractice);
    const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
    const getPracticeAllPatientGDPRLogAction = (url) => dispatch(getPracticeAllPatientGDPRLog(token, currentPracticeID, currentPractice, url))

    const updatePatientTable = (url) => {
        setLoading(true)
        getPracticeAllPatientGDPRLogAction(url).then(response => {
            setLoading(false)
            if (!response.error) {
                setpatientData(response.payload.gdpr_list);
                setTotalData(response.payload.total)
                // setpatientData([]);
                // setTotalData(response.payload.total)
            } else setError(true);
        });
    };

    return (
        <div>
            <div className="container-fluid">
                <Helmet>
                    <title>
                        PureGenomics® - Patient GDPR List
                    </title>
                </Helmet>
                {/* Head section */}
                <div className="container-xxl py-5 px-0 pg-text">
                    <div className="position-relative">
                        <span
                            id="gtd__patientlist"
                            className="d-inline-block cursor-pointer textc-primary pg-link"
                            onClick={() => navigate("/practice/dashboard")}
                        >
                            <i className="fas fa-chevron-left "></i> Dashboard
                        </span>
                        <PracticePatientLogTable
                            patient={patientData}
                            error={error}
                            totalData={totalData}
                            loading={loading}
                            updatePatientTable={updatePatientTable} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PracticePatientGDPRLogs