import React from "react";
import { Modal } from "react-bootstrap";

function MaintainanceBanner({
  showModal,
  onCancel,
  startTime,
  endTime
}) {
  return (
    <Modal
      show={showModal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      contentClassName="border-radius"
      backdrop="static"
      className="edit-patient-modal"
      align="center"
      centered
      style={{ padding: "25px" }}
    >
      <Modal.Body>
        <div className="banner-text d-lg-block">
          <p>
            The PureInsight application will be unavailable due to maintenance from: {startTime} to {endTime}.
          </p>
          <p>
            We appreciate your patience. For questions, please contact support at: <span style={{ textAlign: "justify", wordBreak: "break-word" }}>puregenomics@pureencapsulations.com</span>
          </p>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary agree_btn_style"
              onClick={() => onCancel()}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MaintainanceBanner;

