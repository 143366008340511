import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import PgAccordion from "./PgAccordion";
import videoIcon from "../../assets/static/videoIconWhite.svg";
import videoIcon2 from "../../assets/videoIcon.png";
import videoThumb from "../../assets/static/pg.png";
import clinicalPicture from "../../assets/static/clinical-picture.webp";
import geneticVariant from "../../assets/static/genetic-variant.webp";
import methylationGenetics from "../../assets/static/methylation-genetics.webp";
import comt from "../../assets/static/comt.webp";
import { postLogin, acceptTermsConditions } from "../../store/actions/auth";
import Toaster from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TermsAndConditions from "../../Pages/TermsAndConditions";
import { getPractitionerTerms } from "../../store/actions/practitionerAccount";
import _ from "lodash";

// rebranding
import PL_Image1 from '../../assets/pg4/rebranding/PostLogin_Image1.jpg'

const PostLogin = ({ history }) => {
  const dispatch = useDispatch();
  const _token = useSelector((state) => state.auth.token);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice)
  const isAuth = useSelector((state) => state.auth.isAuth);
  const postLoginAction = (_token, currentPracticeID) => dispatch(postLogin(_token, currentPracticeID));
  const language = useSelector(state => state.auth.language);

  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [showPracticeTermModal, setShowPracticeTermModal] = useState(false);
  const [showRialtoTermModal, setShowRialtoTermModal] = useState(false);
  const [openRialtoTermModal, setOpenRialtoTermModal] = useState(false);
  const [practitionerTerms, setPractitionerTerms] = useState("");
  const [rialtoTerms, setRialtoTerms] = useState("");
  const isUK = window.location.hostname.endsWith('.co.uk');
  const isCA = (language === 'ca')

  let postLoginApiCall = (_token, currentPracticeID) => {
    postLoginAction(_token, currentPracticeID).then((res) => {
      if (!res.error) {
        setShowPracticeTermModal(res.payload.agree_practice_term)
        setShowRialtoTermModal(res.payload.agree_rialto_term)
      } else console.log("error");
    });
  };
  useEffect(() => {
    if (!location.api && isAuth) {
      postLoginApiCall(_token, currentPracticeID);
    }
  }, []);

  useEffect(() => {
    if (showPracticeTermModal) {
      dispatch(
        getPractitionerTerms(_token, currentPracticeID, currentPractice)
      ).then((response) => {
        if (!response.error) {
          const practitionerTerms = response.payload.terms_and_agreements;          
          if (isCA)
            setPractitionerTerms(_.filter(practitionerTerms, { title: "Ca Practice Agreement" })[0]?.content);
          else if (isUK)
            setPractitionerTerms(_.filter(practitionerTerms, { title: "Uk Practice Agreement" })[0]?.content);
          else {
            var content_filter = practitionerTerms[0]?.title == "Practice Agreement" ? _.filter(practitionerTerms, { title: "Practice Agreement" })[0]?.content : _.filter(practitionerTerms, { title: "Pg4 Practice Agreement" })[0]?.content
            setPractitionerTerms(content_filter);
          }
          if (showRialtoTermModal) setRialtoTerms(_.filter(practitionerTerms, { title: "Virtual Dispensary Agreement" })[0].content);
        }
      });
    }
  }, [showPracticeTermModal, showRialtoTermModal])

  const handleAcceptTerms = (type) => {
    dispatch(
      acceptTermsConditions(_token, currentPracticeID, currentPractice, type)
    ).then((response) => {
      if (!response.error) {
        if (type === "practice") {
          setShowPracticeTermModal(false);
          if (showRialtoTermModal) setOpenRialtoTermModal(true)
        }
        else if (type === "rialto") {
          setShowRialtoTermModal(false);
          setOpenRialtoTermModal(false)
        }
      }
    });
  };

  return (
    <div className="my-5">
      {showPracticeTermModal && <div>
        <TermsAndConditions
          showModal={true}
          content={practitionerTerms}
          showClose={false}
          showButton={true}
          acceptTerms={() => handleAcceptTerms("practice")}
          title={"PUREGENOMICS PLATFORM TERMS OF USE"}
        />
      </div>}
      {openRialtoTermModal && <div>
        <TermsAndConditions
          showModal={true}
          content={rialtoTerms}
          showClose={false}
          showButton={true}
          acceptTerms={() => handleAcceptTerms("rialto")}
          title={"VIRTUAL DISPENSARY TERMS OF USE"}
        />
      </div>}
      <div className="">
        {/* section 1 */}
        <div className="container-xxl px-0 px-md-3">
          <div className="row justify-content-lg-between justify-content-center my-5">
            <div className="col-11 col-lg-5 order-last order-lg-first my-auto">
              <div className={`pgs-title pgs-title__v my-1 d-none d-lg-block ${i18n.language !== "en" ? 'fw-bold' : ''} `}>
                <span dangerouslySetInnerHTML={{ __html: t('education_h1') }} />
              </div>
              <div className="pgs-text">
                <div className="my-3 my-lg-2">
                  <span dangerouslySetInnerHTML={{ __html: t('education_p1') }} />
                </div>
              </div>
            </div>
            <div className="col-11 col-lg-6 my-auto">
              <div className="d-lg-none my-4">
                <div className="pgs-title fw-bold">
                  <span dangerouslySetInnerHTML={{ __html: t('education_h1') }} />
                </div>
              </div>
              <div className="react-player">
                <div>
                  {i18n.language === "en_gb" ? <img
                    src={videoThumb}
                    alt="puregenomics video"
                    className="img-fluid w-100"
                  /> : <img
                    src={PL_Image1}
                    alt="puregenomics video"
                    className="img-fluid w-100 mb-0 mb-md-5"
                    style={{ marginTop: "0px" }}
                  />}
                </div>

                {i18n.language === "en_gb" && <div className="text-center react-player-control">
                  <a
                    href="https://youtu.be/0Dk04H0k9sc"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={videoIcon} alt="videoIcon" />
                  </a>
                  <a href="#" className="text-decoration-none">
                    <div className="pgs-text text-white mt-3">
                      {t('education_learn_txt1')}
                    </div>
                  </a>
                </div>}
              </div>
            </div>
          </div>
        </div>

        {/* section 2 */}
        <div className="bg-grey-2">
          <div className="container-xxl px-0 px-md-2">
            <div className="row justify-content-lg-around  justify-content-center  my-5">
              <div className="col-11 col-lg-5 mb-3 mb-lg-2 text-lg-center">
                <div className="my-4 my-lg-5">
                  <span dangerouslySetInnerHTML={{ __html: t("education_section_1") }} />
                </div>
                <a
                  href="https://pureencapsulations.thinkific.com/courses/puregenomics"
                  target="_blank"
                  className="btn btn-outline lh-lg"
                  rel="noreferrer"
                >
                  {t("enroll_for_free")}
                </a>
              </div>
              {i18n.language === "en_gb" && (
                <div className="col-11 col-lg-5 mb-3 mb-lg-2">
                  <div className="text-lg-center my-4 my-lg-5 my-0">
                    <div className="pgs-heading ff-m textc-primary fw-bold">
                      {t("education_section_2_txt1")}
                    </div>
                    <div className="pgs-text my-3 postlogin_list">
                      <span
                        dangerouslySetInnerHTML={{ __html: t("education_section_2_txt2") }}
                      />
                    </div>
                    <a
                      href="https://pureencapsulations.thinkific.com/courses/PureGenomics-onboarding"
                      target="_blank"
                      className="btn btn-outline lh-lg"
                      rel="noreferrer"
                    >
                      {t("start_your_journey")}
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* section 3 */}
        <div className="container-xxl px-0 px-md-2">
          <div className="row justify-content-center my-lg-5 my-0">
            <div className="col-11 col-lg-12">
              <div>
                <div className="pgs-heading ff-m textc-primary fw-bold">
                  {t('education_section_h1')}
                </div>
                <div className="pgs-text my-3">
                  <span dangerouslySetInnerHTML={{ __html: t('education_row3_p1') }} />
                </div>
              </div>
              <div className="my-4">
                <PgAccordion question={t('education_q1')}>
                  <ul className="my-4">
                    <li className="pgs-text">
                      <span dangerouslySetInnerHTML={{ __html: t('education_section_3_txt1') }} />
                      <div className="react-player w-50 my-3 mx-auto d-none d-lg-block">
                        <div>
                          <img
                            src={clinicalPicture}
                            alt="clinicalPicture"
                            className="w-100 "
                          />
                        </div>
                        <div className="react-player-control-center">
                          <a
                            href="https://www.youtube.com/watch?v=UrmKM_Eh_Zg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={videoIcon2} alt="videoIcon" />
                          </a>
                        </div>
                      </div>
                      <div className="react-player w-100 my-3 mx-auto d-lg-none">
                        <div>
                          <img
                            src={clinicalPicture}
                            alt="clinicalPicture"
                            className="w-100 "
                          />
                        </div>
                        <div className="react-player-control-center">
                          <a
                            href="https://www.youtube.com/watch?v=v_i1B0SFoF4"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={videoIcon2} alt="videoIcon" />
                          </a>
                        </div>
                      </div>
                    </li>

                    <li className="pgs-text">
                      <span dangerouslySetInnerHTML={{ __html: t('education_download_link') }} />{" "}
                      <span dangerouslySetInnerHTML={{ __html: t('education_drop_1_txt1') }} />
                    </li>
                    <li>
                      <span dangerouslySetInnerHTML={{ __html: t('education_download_link_2') }} />{" "}
                      {t('education_drop_1_txt2')}
                    </li>

                    <span dangerouslySetInnerHTML={{ __html: t('education_drop_1_txt3') }} />
                  </ul>
                  <div className="text-secondary fw-bold" >
                    +  {t('education_drop_1_txt4')}
                  </div>
                </PgAccordion>
                <PgAccordion question={t('education_q2')}>
                  <div className="my-4">
                    <span dangerouslySetInnerHTML={{ __html: t('education_drop_2_txt1') }} />
                    <div className="pgs-text">
                      {t('education_drop_2_txt2')}{" "}
                      <Link to="/trait-category-abstracts" className="fw-bold">
                        {t('education_drop_2_txt3')}
                      </Link>{" "}
                      <span dangerouslySetInnerHTML={{ __html: t('education_a2_l2') }} />
                    </div>
                  </div>
                </PgAccordion>
                <PgAccordion question={t('education_q3')}>
                  <div className="my-4">
                    <span dangerouslySetInnerHTML={{ __html: t('education_drop_3_txt1') }} />
                    <ul>
                      <span dangerouslySetInnerHTML={{ __html: t('education_a3') }} />
                    </ul>

                  </div>
                </PgAccordion>
                <PgAccordion question="Trait Tutorial Videos">
                  <div className="my-4">
                    <div className="pgs-text">
                      <span dangerouslySetInnerHTML={{ __html: t('education_drop_4_txt1') }} />
                    </div>
                  </div>
                </PgAccordion>
                <PgAccordion question="Patient Resources">
                  <div className="my-4">
                    <ul>
                      <span dangerouslySetInnerHTML={{ __html: t('education_dlist_txt_1') }} />
                      <li className="pgs-text">
                        <span dangerouslySetInnerHTML={{ __html: t('education_download_link_3') }} />{" "}
                        <span dangerouslySetInnerHTML={{ __html: t('education_drop_1_txt1') }} />
                      </li>
                      <li className="pgs-text">
                        <span dangerouslySetInnerHTML={{ __html: t('education_download_link_4') }} />{" "}
                        {t('education_drop_1_txt2')}
                      </li>
                    </ul>
                  </div>
                </PgAccordion>
              </div>
            </div>
          </div>
        </div>

        {/* section 4 */}
        < div className="bg-grey-2" >
          <div className="container-xxl px-0 px-md-2">
            <div className="row justify-content-lg-around justify-content-center  my-lg-5 my-0">
              <div className="col-11 col-lg-12">
                <div className="m-3">
                  <div className="pgs-heading ff-m textc-primary fw-bold d-block text-center">
                    {t('education_end_txt1')}
                  </div>
                  <div className="pgs-text text-lg-center my-3">
                    <span dangerouslySetInnerHTML={{ __html: t('education_row4_p1') }} />
                  </div>
                </div>
                {/* sub */}
              </div>
            </div>
            <div className="row justify-content-center my-lg-3 my-0">
              <div className="col-11 col-lg-4 mb-3">
                <div className="react-player">
                  <div>
                    <img
                      src={geneticVariant}
                      alt="geneticVariant"
                      className="w-100"
                    />
                  </div>
                  <div className="react-player-control-center">
                    <a
                      href="https://youtu.be/5LChB6w69oA"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={videoIcon2} alt="videoIcon" />
                    </a>
                  </div>
                </div>
                <div className="pgs-text my-3">
                  <a
                    href="https://youtu.be/5LChB6w69oA"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span dangerouslySetInnerHTML={{ __html: t('education_row4_l1') }} />
                  </a>
                </div>
              </div>
              <div className="col-11 col-lg-4 mb-3">
                <div className="react-player">
                  <div>
                    <img
                      src={methylationGenetics}
                      alt="methylationGenetics"
                      className="w-100"
                    />
                  </div>
                  <div className="react-player-control-center">
                    <a
                      href="https://youtu.be/hHimUT91JsY"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={videoIcon2} alt="videoIcon" />
                    </a>
                  </div>
                </div>

                <div className="pgs-text my-3">
                  <a
                    href="https://youtu.be/hHimUT91JsY"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('education_end_txt2')}
                  </a>
                </div>
              </div>
              <div className="col-11 col-lg-4 mb-3">
                <div className="react-player">
                  <div>
                    <img src={comt} alt="clarity with COMT" className="w-100" />
                  </div>
                  <div className="react-player-control-center">
                    <a
                      href="https://youtu.be/3jQO0joq95c"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={videoIcon2} alt="videoIcon" />
                    </a>
                  </div>
                </div>
                <div className="pgs-text my-3">
                  <a
                    href="https://youtu.be/3jQO0joq95c"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t('education_end_txt3')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default PostLogin;
