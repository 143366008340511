import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux'
import { changeIsAuth } from '../../store/actions/auth';
import { agreePatientTAC, getPatientTACContent } from '../../store/actions/patientWelcome';
import { Loading } from '../Loading';
import L_Multiline from '../LoadingEffectComponent/L_Multiline';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

function PatientTAC() {
    const { t, i18n } = useTranslation();
    const [showTACModal, setshowTACModal] = useState(false);
    const [showTermsModal, setshowTermsModal] = useState(false);
    const [showRebrandingModal, setshowRebrandingModal] = useState(false);
    const [usAffirmativeModal, setusAffirmativeModal] = useState(false);
    const [tacContent, settacContent] = useState();
    const [termContent, settermContent] = useState();
    const [rebrandingContent, setrebrandingContent] = useState();
    const [usAffirmativeContent, setusAffirmativeContent] = useState();
    const [content, setcontent] = useState();

    const dispatch = useDispatch();
    const termsAgreed = useSelector((state) => state.auth.termsAgreed);
    const language = useSelector((state) => state.auth.language);
    const token = useSelector((state) => state.auth.token);
    const currentPractice = useSelector((state) => state.auth.currentPractice);
    const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
    const agreeTAC = (id) => dispatch(agreePatientTAC(token, currentPracticeID, currentPractice, id))
    const changeAuthState = (pld) => dispatch(changeIsAuth(pld));
    const getTACContent = () => dispatch(getPatientTACContent(token, currentPracticeID, currentPractice))

    const [show, setShow] = useState(!termsAgreed);
    const [loading, setloading] = useState(true);
    useEffect(() => {
        getContent()
    }, [])

    const getContent = () => {
        // const response = {};
        // response.payload = sample;
        getTACContent().then(response => {
            setloading(false)
            if (!response.error) {
                setcontent(response.payload);
                if (response.payload && _.isArray(response.payload?.terms)) {
                    response.payload.terms.forEach(terms => {
                        if (terms.agreement === "pg4_patient_agreement_1" && !terms.agreed) {
                            setshowTACModal(true);
                            settacContent({ id: terms.id, title: terms.title, content: terms.content })
                        }
                        if (terms.agreement === "pg4_patient_agreement_2" && !terms.agreed) {
                            setshowTermsModal(true);
                            settermContent({ id: terms.id, title: terms.title, content: terms.content })
                        }
                        if (terms.agreement === "ca_patient_agreement" && !terms.agreed) {                           
                            setshowTermsModal(true);
                            settermContent({ id: terms.id, title: terms.title, content: terms.content })
                        }
                        if (terms.agreement === "patient_rebranding_agreement" && !terms.agreed) {
                            setshowRebrandingModal(true);
                            setrebrandingContent({ id: terms.id, title: terms.title, content: terms.content })
                        }

                        if (terms.agreement === "pg4_patient_rebranding_agreement_1" && !terms.agreed) {
                            setusAffirmativeModal(true);
                            setusAffirmativeContent({ id: terms.id, title: terms.title, content: terms.content })
                        }
                        if (terms.agreement === "pg4_ca_patient_affrimative_agreement_1" && !terms.agreed) {
                            setusAffirmativeModal(true);
                            setusAffirmativeContent({ id: terms.id, title: terms.title, content: terms.content })
                        }

                    });
                }
            }
        })
    }

    const onAccept = (id) => {
        agreeTAC(id).then(response => {
            if (!response.error) {
                if (_.get(_.find(content.terms, { 'id': id }), 'agreement') === "pg4_patient_agreement_1") {
                    changeAuthState({ termsAgreed: true });
                    setshowTACModal(false);
                }
                if (_.get(_.find(content.terms, { 'id': id }), 'agreement') === "patient_rebranding_agreement") {
                    setshowRebrandingModal(false);
                }
                if (_.get(_.find(content.terms, { 'id': id }), 'agreement') === "pg4_patient_agreement_2") {
                    setshowTermsModal(false);
                }
                if (_.get(_.find(content.terms, { 'id': id }), 'agreement') === "ca_patient_agreement") {
                    setshowTermsModal(false);
                }
                if (_.get(_.find(content.terms, { 'id': id }), 'agreement') === "pg4_patient_rebranding_agreement_1") {
                    setusAffirmativeModal(false);
                }
                if (_.get(_.find(content.terms, { 'id': id }), 'agreement') === "pg4_ca_patient_affrimative_agreement_1") {
                    setusAffirmativeModal(false);
                }
            }
        })
    }

    return (
        <div>
            {(!usAffirmativeModal && !showTACModal && !showTermsModal && showRebrandingModal && (i18n.language === "en" || i18n.language === "ca")) && <TACModal
                title={rebrandingContent?.title}
                content={rebrandingContent?.content}
                loading={loading}
                show={showRebrandingModal}
                // onAccept={() => setshowRebrandingModal(false)}
                onAccept={() => onAccept(rebrandingContent.id)}
            />}

            {(!usAffirmativeModal && !showTACModal && showTermsModal) && <TACModal
                title={termContent?.title}
                content={termContent?.content}
                loading={loading}
                show={showTermsModal}
                // onAccept={() => setshowTermsModal(false)}
                onAccept={() => onAccept(termContent.id)}
            />}
            {(!usAffirmativeModal && showTACModal) && <TACModal
                title={tacContent?.title}
                content={tacContent?.content}
                loading={loading}
                show={showTACModal}
                // onAccept={() => setshowTACModal(false)}
                onAccept={() => onAccept(tacContent.id)}
            />}
            {(!showTACModal && usAffirmativeModal) && <TACModal
                title={usAffirmativeContent?.title}
                content={usAffirmativeContent?.content}
                loading={loading}
                show={usAffirmativeModal}
                // onAccept={() => setusAffirmativeModal(false)}
                onAccept={() => onAccept(usAffirmativeContent.id)}
            />}
        </div>
    )
}

export default PatientTAC

const TACModal = ({ show, content, title, loading, onAccept }) => {
    return <Modal
        show={show}
        bsPrefix="border-radius modal"
        centered
        backdrop="static"
    >
        <div className="p-3">
            {loading
                ? <L_Multiline />
                : <div className=''>
                    <div className='pg-heading' dangerouslySetInnerHTML={{ __html: title }} />
                    <div className='my-3' dangerouslySetInnerHTML={{ __html: content }} />
                    <div className="mt-4">
                        <button className=" w-100 btn bgc-primary text-light px-5" onClick={onAccept}>Agree</button>
                    </div>
                </div>}
        </div>
    </Modal>
}