import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import _ from "lodash";
import { useLocation, useNavigate } from "react-router-dom";
import L_LabReviewPage from "../../LoadingEffectComponent/L_LabReviewPage";
import LabReviewUpdateSuccess from "./LabReviewUpdateSuccess";
import LabTestRow from "./LabTestRow";
import {
  createManualEntries,
  getLabTests,
} from "../../../store/actions/labUpload";
import DeleteConfirnationModal from "./DeleteConfirmationModal";
import ExampleRow from "./ExampleRow";
import { useTranslation } from "react-i18next";

const ManualEntry = () => {
  const routerLoc = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState(routerLoc.state);

  const [showPop, setShowPop] = useState(false);
  const [showDeletePopup, setshowDeletePopup] = useState(false);
  const [dataValid, setDataValid] = useState(false);
  const [deleteRow, setDeleteRow] = useState("");
  const { t, i18n } = useTranslation();


  // store data
  const token = useSelector((state) => state.auth.token);
  const labTests = useSelector((state) => state.labUpload.tests);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const resultReview = useSelector((state) => state.labUpload.resultReview);
  const patientId = useSelector(
    (state) => state.patientReport.currentPatientId
  );
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const userType = useSelector(state => state.auth.userType);
  const currentPatientId = (userType == "User") ? patientId : currentUserId;
  const state = useSelector(
    (state) => state
  );

  const loading = false;
 
  const dispatch = useDispatch();
  const createManualEntriesAction = (data) =>
    dispatch(
      createManualEntries(token, currentPracticeID, currentPractice, data)
    );
  const getLabTestsAction = () =>
    dispatch(getLabTests(token, currentPracticeID, currentPractice));

  const generateValueRows = (count) => {
    let rows = [];
    _.times(count, (i) => {
      rows.push({
        id: `${Math.floor(Math.random() * 1000) + 1}_${i}`,
        test: "",
        result: "",
        unit: "",
        reference_range: "",
        edited: false,
        is_manual_entry: true,
      });
    });

    return rows;
  };

  const [inputValues, setInputValues] = useState(generateValueRows(3));

  useEffect(() => {
    getLabTestsAction();
  }, []);

  const onInput = (id, key, value) => {
    setInputValues((input_values) => {
      let c_input_values = [...input_values];
      c_input_values[id][key] = value;
      c_input_values[id]["edited"] = true;
      validateInputValues(true);
      return [...c_input_values];
    });
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    let c_input_values = [...inputValues];
    if (c_input_values[index]["edited"]) {
      setDeleteRow(index);
      setshowDeletePopup(true);
    } else {
      removeRow(index);
    }
  };

  const removeRow = (index) => {
    const list = [...inputValues];
    list.splice(index, 1);
    validateInputValues(true, list);
    setshowDeletePopup(false);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputValues([...inputValues, ...generateValueRows(1)]);
  };

  const selectedTestName = (index) => {
    let c_input_values = [...inputValues];
    return c_input_values[index]["test"];
  };

  const validateInputValues = (
    validate_empty_fields = false,
    currentList = []
  ) => {
    return setDataValid((prevValue) => {
      const list = currentList.length > 0 ? currentList : [...inputValues];
      let validRows = [];

      list.forEach((eachValue, index) => {
        let valid = true;
        if (!validate_empty_fields) {
          eachValue["error_test"] = false;
          eachValue["error_result"] = false;
          if (eachValue.test === "" && eachValue.result != "") {
            eachValue["error_test"] = true;
            valid = false;
          }
          if (eachValue.test != "" && eachValue.result === "") {
            eachValue["error_result"] = true;
            valid = false;
          }
          if (
            eachValue.test === "" &&
            eachValue.result === "" &&
            (eachValue.unit != "" || eachValue.reference_range != "")
          ) {
            eachValue["error_test"] = true;
            eachValue["error_result"] = true;
            valid = false;
          }
        }
        if (
          validate_empty_fields &&
          (eachValue.test != "" ||
            eachValue.result != "" ||
            eachValue.unit != "" ||
            eachValue.reference_range != "")
        ) {
          valid = true;
        }
        if (
          eachValue.test === "" &&
          eachValue.result === "" &&
          eachValue.unit === "" &&
          eachValue.reference_range === ""
        ) {
          eachValue["edited"] = false;
          if (validate_empty_fields) valid = false;
        }
        validRows.push(valid);
      });

      let valid = validate_empty_fields
        ? validRows.includes(true)
        : validRows.indexOf(false) == -1;
      setInputValues(list);
      if (!validate_empty_fields) {
        if (valid) {
          postData();
        } else {
          toast.error(t("txt_please_enter_all_fields"));
        }
      }
      return valid;
    });
  };

  const onConfirm = () => {
    validateInputValues();
  };

  const postData = () => {
    let postData = {
      lab_vendor_id: data.vendorId == -1 ? null : data.vendorId,
      patient_id: currentPatientId,
      user_type: "Practitioner",
      uploaded_by: currentUserId,
      is_manual_entry: true,
      lab_report_results: inputValues,
      vendor_name: data.selectedVendorName,
      created_by: currentUserId,
    };

    createManualEntriesAction({ data: postData }).then((response) => {
      if (!response.error) {
        setShowPop(true);
      }
    });
  };

  let buttonStyle = "w-100 btn bg-sky-blue px-5 textc-primary py-2";
  let disabledStyle = "w-100 btn bg-secondary px-5 text-light py-2";

  let lab_url = userType == "Patient" ? "/patient/lab" : "/practice/patient/lab";
  let list_url = userType == "Patient" ? "/patient/dashboard" : "/practice/patients";

  const goToLabUploadPage = () => {
    let lab_url = userType == "Patient" ? "/patient/lab" : "/practice/patient/lab";
    navigate(lab_url);
  };

  const goToPatientList = () => {
    let list_url = userType == "Patient" ? "/patient/dashboard" : "/practice/patients";
    navigate(list_url);
  };

  let modal_text = userType == "Patient" ? t("dashboard") : t("txt_patient_list");

  return (
    <div className="">
      <LabReviewUpdateSuccess
        toPatientList={() => goToPatientList()}
        toUploadPage={() => goToLabUploadPage()}
        modalText={modal_text}
        onCancel={() => setShowPop(false)}
        showModal={showPop}
      />
      <DeleteConfirnationModal
        message={t("txt_are_you_sure") + "?"}
        onAccept={() => removeRow(deleteRow)}
        onCancel={() => setshowDeletePopup(false)}
        showModal={showDeletePopup}
      />
      {loading ? (
        <div className="row">
          <div className="col-12">
            <L_LabReviewPage />
          </div>
        </div>
      ) : (
        <div>
          <div className="container-xxl">
            <div
              className="pg-link my-5"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="fa fa-chevron-left me-1" aria-hidden="true"></i>{" "}
              {data.from}
            </div>
          </div>
        </div>
      )}
      <div className="container-xxl">
        <div className="row">
          <div className="d-flex justify-content-between">
            <div className="pg-title">{t("lab_results")}</div>
            <div className="d-flex align-items-end">
              <div>
                <span className="pg-heading__sub">{t("txt_lab_vendor_name")}:</span>{" "}
                <div
                  className="d-inline"
                  dangerouslySetInnerHTML={{ __html: data.selectedVendorName }}
                />{" "}
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <div className="my-3">
            <div className="pg-heading  d-flex justify-content-between">
              <div>{t("txt_manual_enrtry")}</div>
              <div>
                {!data.completed && (
                  <button
                    className="btn btn-outline px-4 ms-2"
                    onClick={handleAddClick}
                  >
                    <i className="fas fa-plus"></i> {t("txt_add_result")}
                  </button>
                )}
              </div>
            </div>
            <div className="pg-text pt-3">
            {
              (userType == "User") ? t("txt_manual_enrtry_description") : t("Enter your lab results in the fields below. You can view the list of standard lab test names in the Lab Test field, or start typing there to see all matching suggestions.")
            }
              
            </div>
          </div>
        </div>

        <div className="row pg-text__bold justify-content-between">
          <div className="col-3">{t("txt_lab_test")}</div>
          <div className="col-3">{t("gene_analysis_td_4")}</div>
          <div className="col-3">{t("txt_unit")}</div>
          <div className="col-3">{t("txt_reference_interval")}</div>
          {/* <div className="col-2">Range (max)</div> */}
        </div>
        <hr />
        <ExampleRow />
        <hr />
        {inputValues.map((data, index) => {
          return (
            <div key={data.id}>
              <LabTestRow
                onInput={onInput}
                handleRemoveClick={handleRemoveClick}
                index={index}
                inputValues={inputValues}
                labTests={labTests}
                selectedTestName={selectedTestName}
                labData={data}
              />
              <hr />
              <div>
                {inputValues.length - 1 === index && (
                  <button
                    className="btn btn-outline px-4 ms-2 mb-3"
                    onClick={handleAddClick}
                  >
                    <i className="fas fa-plus"></i> {t("txt_add_result")}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="row justify-content-center bgc-secondary py-3">
        <div className="col-lg-4 col-md-6 col-10">
          <button
            className={dataValid ? buttonStyle : disabledStyle}
            onClick={onConfirm}
            disabled={!dataValid}
          >
            {t("patient_rv_txt3")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManualEntry;

function TextInput({
  type,
  name,
  inputHandler,
  placeholder,
  error,
  errorMessage,
  value,
  isDisable,
  key,
}) {
  const style = {
    padding: 15,
    width: "100%",
    outline: "none",
    border: error ? "1.2px solid #f00" : "1px solid #888888",
    borderRadius: "8px",
    height: 50,
    transition: "all .3s ease-in-out",
  };

  return (
    <div className="w-90">
      <input
        placeholder={placeholder}
        name={name}
        type={type}
        onInput={inputHandler}
        value={value}
        style={style}
        disabled={isDisable}
        key={key}
      />
      {/* <div
          className="text-danger mx-2 my-1 transition-all"
          style={error ? { opacity: 1 } : { opacity: 0 }}
        >
          <i className="fas fa-info-circle"></i> {errorMessage}
        </div> */}
    </div>
  );
}
