import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import toast from "react-hot-toast";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { UploadDataManually } from "../../components/Patient/UploadDataManually";
import AfterSNPFilesSelected from "../../components/Practice/Genetics/AfterSNPFilesSelected";
import FilePicker from "../../components/Patient/FilePicker";
import { PgProgressBar } from "../../components/Patient/PgProgressBar";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getSNPUploadStatus,
  updateSnpUpload,
  uploadSNPForPractice,
} from "../../store/actions/snpUpload";
import GeneticUploadSuccess from "../../components/Practice/Genetics/GeneticUpdloadSuccess";
import AfterUploadStarted from "../../components/Practice/Lab/AfterUploadStarted";
import { updatePatientReport } from "../../store/actions/patientReport";
import { useTranslation } from "react-i18next";

const GeneticUpload = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // redux state variable
  const token = useSelector(state => state.auth.token);
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const userType = useSelector(state => state.auth.userType);
  const currentPatientId = useSelector(state => state.patientReport.currentPatientId);
  const fileStatus = useSelector(state => state.snpUpload.fileStatus);

  const dispatch = useDispatch();
  // redux actions

  const updateSNPAction = (data) => dispatch(updateSnpUpload(data));
  const uploadSNP = (token, currentPracticeID, currentPractice, data) => dispatch(uploadSNPForPractice(token, currentPracticeID, currentPractice, data));
  const getSNPUploadStatusAction = (token, currentPracticeID, currentPractice, data) => dispatch(getSNPUploadStatus(token, currentPracticeID, currentPractice, data));
  const updatePatientReportAction = (data) => dispatch(updatePatientReport(data));
  const language = useSelector((state) => state.auth.language)

  const [currentPatientStatus, setcurrentPatientStatus] = useState({
    patientId: "",
    uploadError: false,
    error: false,
    isFileSelected: false,
    uploadStarted: false,
    errorText: "",
    status: "",
    documentId: null,
  });

  useEffect(() => {
    let currentPatientIndex = findPatientIndex(currentPatientId);
    if (currentPatientIndex < 0) {
      let dFileStatus = [...fileStatus];
      fileStatus.push({
        patientId: currentPatientId,
        uploadError: false,
        uploadStarted: false,
        error: false,
        errorText: "",
        isFileSelected: false,
        file: null,
        status: "",
      });
    } else {
      setcurrentPatientStatus({ ...fileStatus[currentPatientIndex] });
    }
  }, [fileStatus]);

  useEffect(() => {
    if (currentPatientStatus.uploadStarted) {
      if (
        !currentPatientStatus.uploadError &&
        currentPatientStatus.status !== "Complete" &&
        !currentPatientStatus.error &&
        !_.isUndefined(currentPatientStatus.documentId)
      ) {
        updateLoadingStatus();
      }
    }
  }, [currentPatientStatus]);

  const onFilePicked = (e, t) => {
    if (e.target.files.length === 1) {
      const pickedFiles = e.target.files[0];
      if (
        pickedFiles.type === "text/plain" ||
        pickedFiles.type === "application/zip" ||
        pickedFiles.type === "application/x-zip-compressed" ||
        (pickedFiles.type === "text/x-vcard" && language == "en_gb")
      ) {
        let patientIndex = findPatientIndex(currentPatientId);
        let labFileStatus = [...fileStatus];
        if (patientIndex < 0) console.log("It shouldnt be printed");
        else {
          labFileStatus[patientIndex].file = pickedFiles;
          labFileStatus[patientIndex].isFileSelected = true;
          if (t === 2) {
            labFileStatus[patientIndex].status = "Uploading";
            labFileStatus[patientIndex].error = false;
            labFileStatus[patientIndex].uploadError = false;
            labFileStatus[patientIndex].uploadStarted = false;
          }
          updateSNPAction({
            fileStatus: labFileStatus,
          });
        }
      } else {
        let message = language == "en_gb" ? "Only accepts text, vcf and zip files (.txt, .vcf and .zip)" : "Only accepts text and zip files (.txt and .zip)"
        toast.error(message);
      }
    }
  };

  const onStartUpload = () => {
    let snpFileStatus = [...fileStatus];
    let patientIndex = findPatientIndex(currentPatientId);
    if (patientIndex < 0) {
      console.log("This shouldnt be printed");
    } else {
      snpFileStatus[patientIndex].uploadStarted = true;
      snpFileStatus[patientIndex].status = "Uploading";
      snpFileStatus[patientIndex].error = false;
      updateSNPAction({
        fileStatus: snpFileStatus,
      });
      let data = new FormData();
      data.append("patient_id", currentPatientStatus.patientId);
      data.append("user_type", "Practitioner");
      data.append("uploaded_by", currentUserId);
      data.append(`file`, currentPatientStatus.file);
      uploadSNP(token, currentPracticeID, currentPractice, data).then(
        (response) => {
          const responseData = response.payload;
          if (response.error) {
            let snpFileStatus = [...fileStatus];
            snpFileStatus[patientIndex].error = true;
            snpFileStatus[patientIndex].uploadError = true;
            updateSNPAction({
              fileStatus: snpFileStatus,
            });
          } else {
            let snpFileStatus = [...fileStatus];
            snpFileStatus[patientIndex].error = false;
            snpFileStatus[patientIndex].uploadError = false;
            snpFileStatus[patientIndex].documentId = responseData.document_id;
            updateSNPAction({
              fileStatus: snpFileStatus,
            });
            // updateLoadingStatus(responseData.document_id)
            // setPollingStarted(false);
          }
        }
      );
    }
  };
  const removeSelectedFile = () => {
    const currentPatientIndex = findPatientIndex(currentPatientId);
    let dCurrentPatientStatus = [...fileStatus];
    dCurrentPatientStatus[currentPatientIndex].file = null;
    dCurrentPatientStatus[currentPatientIndex].isFileSelected = false;
    updateSNPAction({
      fileStatus: dCurrentPatientStatus,
    });
  };

  const addAnotherFile = (e) => {

    if (e.target.files.length === 1) {
      const pickedFiles = e.target.files[0];
      if (
        pickedFiles.type === "text/plain" ||
        pickedFiles.type === "application/zip" ||
        pickedFiles.type === "application/x-zip-compressed" ||
        (pickedFiles.type === "text/x-vcard" && language == "en_gb")
      ) {
        const currentPatientIndex = findPatientIndex(currentPatientId);
        let dCurrentPatientStatus = [...fileStatus];
        dCurrentPatientStatus[currentPatientIndex].file = pickedFiles;
        updateSNPAction({
          fileStatus: dCurrentPatientStatus,
        });
      } else {
        let message = language == "en_gb" ? "Only accepts text, vcf and zip files (.txt, .vcf and .zip)" : "Only accepts text and zip files (.txt and .zip)"
        toast.error(message);
      };
    }
  };

  const findPatientIndex = (patientId) => {
    return _.findIndex(fileStatus, { patientId: patientId });
  };

  let intervalTracker;
  const _getSNPUploadStatusAction = (dId) => {
    let data = {
      type: "prac_genetic",
      document_id: currentPatientStatus.documentId || dId,
    };
    getSNPUploadStatusAction(
      token,
      currentPracticeID,
      currentPractice,
      data
    ).then((response) => {
      let labFileStatus = [...fileStatus];
      let patientIndex = findPatientIndex(currentPatientId);
      if (!response.payload.success) {
        // clearInterval(intervalTracker);
        if (patientIndex < 0) {
          console.log("This shouldnt be printed");
        } else {
          labFileStatus[patientIndex].error = true;
          labFileStatus[patientIndex].errorText = response.payload.message;
          updateSNPAction({
            fileStatus: labFileStatus,
          });
        }
      } else if (response.payload.current_state === "Complete") {
        const reportId = response.payload.pg4_report_id;
        clearInterval(intervalTracker);
        labFileStatus[patientIndex].status = "Complete";
        labFileStatus[patientIndex].error = false;
        updateSNPAction({
          fileStatus: labFileStatus,
        });
        // updatePatientReportAction({
        //   currentReportId: reportId,
        // });
        if (_.isUndefined(response.payload.pg4_report_id)) navigate(-1);
        else navigate("/practice/report/new/" + reportId);
      } else {
        labFileStatus[patientIndex].status =
          response.payload.current_state === "Processing"
            ? labFileStatus[patientIndex].status
            : response.payload.current_state;
        labFileStatus[patientIndex].error = false;
        updateSNPAction({
          fileStatus: labFileStatus,
        });
      }
    });
  };

  const updateLoadingStatus = (dId) => {
    intervalTracker = setTimeout(() => {
      _getSNPUploadStatusAction(dId);
    }, 5000);
  };

  const onTryAgain = () => {
    const currentPatientIndex = findPatientIndex(currentPatientId);
    let dCurrentPatientStatus = [...fileStatus];
    dCurrentPatientStatus[currentPatientIndex].isFileSelected = false;
    dCurrentPatientStatus[currentPatientIndex].uploadStarted = false;
    dCurrentPatientStatus[currentPatientIndex].documentId = undefined;
    dCurrentPatientStatus[currentPatientIndex].status = "Uploading";
    dCurrentPatientStatus[currentPatientIndex].error = false;
    dCurrentPatientStatus[currentPatientIndex].uploadError = false;
    updateSNPAction({
      fileStatus: dCurrentPatientStatus,
    });
  };

  const { t, i18n } = useTranslation();

  return (
    <div>
      <Helmet>
        <title>
          {t('puregenomics_1')} - Practice Genetic Upload
        </title>
      </Helmet>
      <div className="container-fluid">
        <div className="container-xl pt-1 pt-lg-5 px-0">
          <div
            className="pg-link"
            onClick={() => navigate("/practice/patients")}
          >
            <i className="fa fa-chevron-left me-2" aria-hidden="true"></i> {t('patients')}
          </div>
          <div className="pg-title my-4">{t('pracgu_line_1')}</div>
          <div className="pg-heading">{location?.state?.patientName}</div>
          {!currentPatientStatus.isFileSelected && (
            <div className="row mt-2 ">
              <div className="col-12 col-lg-6">
                <p className="pg-text my-2 my-lg-2">
                  <span dangerouslySetInnerHTML={{ __html: t('upload_instruc_line_1') }} />
                </p>
              </div>
            </div>
          )}
          <hr className="my-5 d-none d-lg-block" />
          {!currentPatientStatus.isFileSelected && (
            <UploadDataManually onChange={onFilePicked} userType={userType} language={language} />
          )}
          {currentPatientStatus.isFileSelected &&
            !currentPatientStatus.uploadStarted && (
              <AfterSNPFilesSelected
                onStartUpload={onStartUpload}
                addAnotherFile={addAnotherFile}
                snpData={currentPatientStatus}
                removeSelectedFile={removeSelectedFile}
                t={t}
                language={language}
              />
            )}
          {currentPatientStatus.uploadStarted && (
            <div>
              <AfterUploadStarted
                uploadType="genetic"
                error={currentPatientStatus.error}
                status={currentPatientStatus.status}
                errorText={currentPatientStatus.errorText}
                onTryAgain={onTryAgain}
              />

              <div className="row justify-content-center my-5">
                <div className="col-12 col-lg-5" dangerouslySetInnerHTML={{ __html: t('pracgu_line_3') }} />
              </div>
              <div className="my-5 pt-5" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GeneticUpload;
