import React from 'react'
import { Dropdown } from "react-bootstrap";

export default function ReportTableFooter({
  pageSize,
  canNextPage,
  canPreviousPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageOptions,
}) {
  return (
    <div className="d-flex justify-content-between my-2">
      <div>
        <Dropdown className="d-inline-block">
          <Dropdown.Toggle as="span" className="mx-2">
            <span style={{ fontWeight: "100 !important" }}>{pageSize}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="1" onClick={() => setPageSize(3)}>
              3
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" onClick={() => setPageSize(6)}>
              6
            </Dropdown.Item>
            <Dropdown.Item eventKey="1" onClick={() => setPageSize(10)}>
              10
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        per page
      </div>
      <div>
        <span>
          Page {pageIndex + 1} of {pageOptions.length}{" "}
        </span>
        <span>
          <i
            className="fas fa-chevron-up mx-2 cursor-pointer"
            onClick={previousPage}
            disabled={!canPreviousPage}
            style={
              !canPreviousPage
                ? { color: "grey", cursor: "not-allowed" }
                : { color: "black" }
            }
          ></i>
          <i
            className="fas fa-chevron-down mx-3 cursor-pointer"
            onClick={nextPage}
            disabled={!canNextPage}
            style={
              !canNextPage
                ? { color: "grey", cursor: "not-allowed" }
                : { color: "black" }
            }
          ></i>
        </span>
      </div>
    </div>
  );
}
