import _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";

const RadioVariantSelection = ({ question, onInput }) => {
    const [selectedChoice, setselectedChoice] = useState("");
    const answers = useSelector(state => state.pg4.q_answers);
    return (<div className="pg4-card">
        <div className="pg-heading__sub textc-primary__p">
            {question?.question_text}
        </div>
        <div className="pg-text">
            {question?.placeholder}
        </div>

        <div className="d-flex flex-column flex-lg-row">
            {_.isArray(question?.choices) && question?.choices.map((choice, index) => (
                <div
                    key={choice + index}
                    className={`cursor-pointer my-lg-2 msq-multi_radio ${answers?.[question?.question_id]?.answer[0] === choice ? 'msq-radio__selected' : ''}`}
                    onClick={() => onInput({
                        [question?.question_id]: {
                            question_id: question?.question_id,
                            answer: [choice]
                        }
                    })}
                >
                    <span className="my-auto">
                        {choice}
                    </span>
                </div>
            ))}
        </div>
    </div>);
}

export default RadioVariantSelection;