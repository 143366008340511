// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `[role="tooltip"].popup-content {
	width: -moz-fit-content !important;
	width: fit-content !important;
	border-radius: 11px !important;
}
.popup-content {
	border: 2px solid #14328c !important;
	margin-top: -7px !important;
	color: #14328c;
	font-size: 18px;
	font-weight: 300;
	font-style: normal;
	letter-spacing: normal;
	line-height: 32px;
	text-align: left;
}
.popup-arrow {
	stroke: #14328c !important;
	stroke-dasharray: 39px !important;
	stroke-dashoffset: -58px !important;
}
.popup-content div:nth-of-type(1) {
	width: 32px !important;
	height: 14px !important;
}

.align-warning-icon {
	position: relative;
	bottom: 4px;
}

@media only screen and (max-width: 574px) { 
	.popup-content {
		left: 0px !important;
	}
}

`, "",{"version":3,"sources":["webpack://./src/styles/lifestyle.css"],"names":[],"mappings":"AAAA;CACC,kCAA6B;CAA7B,6BAA6B;CAC7B,8BAA8B;AAC/B;AACA;CACC,oCAAoC;CACpC,2BAA2B;CAC3B,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,kBAAkB;CAClB,sBAAsB;CACtB,iBAAiB;CACjB,gBAAgB;AACjB;AACA;CACC,0BAA0B;CAC1B,iCAAiC;CACjC,mCAAmC;AACpC;AACA;CACC,sBAAsB;CACtB,uBAAuB;AACxB;;AAEA;CACC,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC;EACC,oBAAoB;CACrB;AACD","sourcesContent":["[role=\"tooltip\"].popup-content {\n\twidth: fit-content !important;\n\tborder-radius: 11px !important;\n}\n.popup-content {\n\tborder: 2px solid #14328c !important;\n\tmargin-top: -7px !important;\n\tcolor: #14328c;\n\tfont-size: 18px;\n\tfont-weight: 300;\n\tfont-style: normal;\n\tletter-spacing: normal;\n\tline-height: 32px;\n\ttext-align: left;\n}\n.popup-arrow {\n\tstroke: #14328c !important;\n\tstroke-dasharray: 39px !important;\n\tstroke-dashoffset: -58px !important;\n}\n.popup-content div:nth-of-type(1) {\n\twidth: 32px !important;\n\theight: 14px !important;\n}\n\n.align-warning-icon {\n\tposition: relative;\n\tbottom: 4px;\n}\n\n@media only screen and (max-width: 574px) { \n\t.popup-content {\n\t\tleft: 0px !important;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
