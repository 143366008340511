import { Ancestryand23andMe } from './Ancestryand23andMe';
import FilePicker from './FilePicker'
import { useTranslation } from "react-i18next";

const onlyAccepts = {
  marginTop: 10,
  color: "#888888",
  fontFamily: "Mulish",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
};

export const UploadDataManually = ({ onChange, userType, language }) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <hr className="my-5 d-block d-lg-none" />
      <div className="pg-heading" style={{ color: userType === "Patient" ? "#2470C2" : "" }}>{t('patient_snp_manual')}</div>
      <div className="row my-4 justify-content-center justify-content-lg-between">
        <div className="col-12 col-lg-6">
          {(userType === "User" && language === "en")
            ? <span>
              If your patient has provided a 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> file,
              you can upload it on their behalf.
            </span>
            : <span dangerouslySetInnerHTML={{ __html: t('patient_snp_manual_txt1') }} />
          }
        </div>
        <div className="col-12 col-lg-4 text-end">
          <div className="text w-100 mt-5 mt-lg-0">
            <FilePicker onChange={onChange} type={1} label="Choose file..." userType={userType} acceptFiles={language == 'en_gb' ? ".txt,.vcf,.zip" : ".txt,.zip"} />
            <div style={onlyAccepts} className="text-cente">
              {t('patient_afsnp_txt2')}
            </div>
          </div>
        </div>
      </div>
      <Ancestryand23andMe userType={userType} />
    </div>
  );
}