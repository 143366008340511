import { useState } from "react";
import PgAccordion from "../PgAccordion";
import { useNavigate } from "react-router-dom";
import ExternalSitePrompt from "../ExternalSitePrompt";

function CAProviderFAQ() {
  const navigate = useNavigate();
  const [showExternalSiteModal, setShowExternalSiteModal] = useState(false);
  const [externalSiteURL, setExternalSiteURL] = useState("");

  return (
    <div className="my-5">
      {showExternalSiteModal && (
        <ExternalSitePrompt
          showModal={showExternalSiteModal}
          externalSiteLink={externalSiteURL}
          onCancel={() => setShowExternalSiteModal(false)}
        />
      )}
      <div className="container-xxl">
        <div className="pgs-title my-5">
          PureInsight<sup>&trade;</sup> Healthcare Provider FAQs
        </div>
        {/* section 1 */}
        <div className="pgs-heading mt-5 mb-2 textc-primary">
          PureInsight<sup>&trade;</sup> Usage and Registration
        </div>
        <PgAccordion question="What is PureInsight<sup>&trade;</sup>?">
          <div>
            PureInsight<sup>&trade;</sup> is our new, complementary platform that helps delivers simple, personalized health reports for your patients that easily incorporate into your existing clinical environment. This platform simplifies the data collection process, prioritizes patient needs and assists in developing a holistic, scientifically supported plan of care that focuses on diet, exercise, lifestyle and supplement recommendations. Unlike PureGenomics<sup>&reg;</sup>, our PureInsight<sup>&trade;</sup> platform allows healthcare providers utilize patient questionnaires and validated tools to provide a plan of care. With PureInsight<sup>&trade;</sup>, genetic data and lab test data is no longer required but remain optional for your patient to upload.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Why is PureGenomics<sup>&reg;</sup> changing to PureInsight<sup>&trade;</sup>?">
          <div>
            We want our services to align with your needs and our go-forward strategy to support and empower you in designing evidence- based, personalized and a scientifically supported plan of care focused on diet, exercise, lifestyle, and supplement recommendations for your patients.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Will the rebrand introduce a new logo?">
          <div>
            Yes, the new logo is shown throughout the website – PureInsight<sup>&trade;</sup> Powered by PureGenomics<sup>&reg;</sup>
          </div>
        </PgAccordion>
        <PgAccordion
          question="What is changing for me?"
        >
          <div>
            <p>The look and feel of our website will change slightly. Individual healthcare provider pages will remain the same with updated descriptions to help emphasize what this platform delivers and the tools available when you sign in to your PureInsight<sup>&trade;</sup> account.  The PureInsight platform introduces new features such as a Multi-Symptom Questionnaire that simplifies and streamlines patient data collection, clinically validated questionnaires such as GAD-7, PHQ-9, PSS-4 and optional genetic reports and polygenic soring that can help provide insights to support your plan of care.</p>
            <p>Rest assured that you will continue to receive the exceptional services and features that you have grown accustomed to. This transition will be smooth and effortless, allowing us to assist you in utilizing our services with even greater efficiency. </p>
          </div>
        </PgAccordion>
        <PgAccordion
          question="I have a PureGenomics<sup>&reg;</sup> account. Do I need to create a new one to access PureInsight<sup>&trade;</sup>?"
        >
          <div>
            No, your credentials from your PureGenomics<sup>&reg;</sup> account can be used to log in and access the PureInsight<sup>&trade;</sup> platform.
          </div>
        </PgAccordion>
        <PgAccordion
          question="I did not have a PureGenomics<sup>&reg;</sup> account. How do I get access to PureInsight<sup>&trade;</sup>?"
        >
          <div>
            If you don’t have access, you can register <a href="/hcp/registration">here</a>. If you are a Pure Encapsulations<sup>&reg;</sup> account holder, you can register using your Pure account credentials.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Is the https://www.puregenomics.ca/  web address staying the same?"
        >
          <div>
            No, the new web address is <a href="https://pureinsight.pureencapsulationspro.com">pureinsight.pureencapsulationspro.com</a>
          </div>
        </PgAccordion>
        <PgAccordion
          question="Is there a specific transition plan I need to be aware of?"
        >
          <div>
            No, we will gradually transition to the new brand. Access to your healthcare provider account is not impacted and you can use new features and tools 24/7.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Where can I send any follow-up questions I might have?"
        >
          <div>
            For any additional questions about the rebranding of PureGenomics to PureInsight<sup>&trade;</sup>,
            please click on <a href="/contact-us">Contact Us</a> and submit your inquiry.
          </div>
        </PgAccordion>
        <PgAccordion
          question="What is the cost to use PureInsight<sup>&trade;</sup>?"
        >
          <div>
            PureInsight<sup>&trade;</sup> is complimentary to use! We believe in helping all achieve  health and wellness goals, that's why you can benefit from all that PureInsight<sup>&trade;</sup> has to offer at no cost.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How can I sign up for a PureInsight<sup>&trade;</sup> account?"
        >
          <div>
            If you have your credentials from PureGenomics<sup>&reg;</sup>, use them to sign in to PureInsight<sup>&trade;</sup>. If you did not have a PureGenomics<sup>&reg;</sup> account, sign up for PureInsight<sup>&trade;</sup>by clicking this link: <a href="https://pureinsight.pureencapsulationspro.com">pureinsight.pureencapsulationspro.com</a>.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Upon attempting to register, I received an email that said more information is needed from me to complete my registration. What does this mean?"
        >
          <div>
            This means that we need to verify that you are a healthcare provider to complete the account setup. Please email us a copy of your credentials to complete your registration.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How does a patient create a PureInsight<sup>&trade;</sup> account?"
        >
          <div>
            <p>The healthcare provider must first choose “Add New Patient” on their dashboard. This will trigger the system to send the patient a registration email. </p>
            <p>The patient must click on the link in the registration email to activate their account. The patient is prompted to create a password and log into their patient portal. </p>
          </div>
        </PgAccordion>
        <PgAccordion
          question="Can I register my patients for PureInsight<sup>&trade;</sup> if they have not received their genetic test results yet?"
        >
          <div>
            Yes, you can add patients and they can activate their account even if they have not yet received their genetic test results from 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>. With PureInsight<sup>&trade;</sup>, genetic data is not required but remains as an option for your patients.
          </div>
        </PgAccordion>
        <PgAccordion
          question="While attempting to add a new patient, I received an error message that the email address is already in use. How do I proceed?"
        >
          <div>
            <p>You will need to obtain an alternative email address from the patient as the email address is a unique identifier and can only be used one time. Since that email has been used previously, the patient will need to provide another email address for the account.</p>
            <p>If this patient has a PureInsight<sup>&trade;</sup> account with a different provider using the email address, your patient can also contact customer support to request their account be transferred to your dashboard by emailing us.</p>
          </div>
        </PgAccordion>
        <PgAccordion
          question="My patient wants to set their family members up with PureInsight<sup>&trade;</sup> accounts. How do they do this?"
        >
          <div>
            <p>Family members will need to be invited by you or another participating provider to set up their own account.  Patients must be eighteen (18) years of age or older.</p>
            <p>Each patient must use a different email address to create an account.</p>
          </div>
        </PgAccordion>
        <PgAccordion
          question="What if my patients are not tech-savvy?"
        >
          <div>
            Many tech questions can be resolved easily by your staff, like helping patients log into their accounts and understanding how to download the text file. If the tech problem is not easily resolved, please have your patients <a href="/contact-us">Contact Us </a> for assistance.
          </div>
        </PgAccordion>


        {/* section 2 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Practice Management
        </div>
        <PgAccordion
          question="How do I add staff members to my practice?"
        >
          <div>
            After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then click the “Add New Staff Member” button to open the form for adding a new staff member. Complete the required fields, assign the desired permissions, and click “Save” to finish. Your new staff member will receive an email to set their password and activate their account.
          </div>
        </PgAccordion>
        <PgAccordion
          question="What can staff members do?"
        >
          <div>
            Staff members can add patients, view the patient list, send patient reminders, and view patient reports. Depending on the optional permissions selected by the practice owner, staff members can also upload patient genetic files and lab files, view recommendations, and view the catalogue and substitutions. Staff members are not permitted to create recommendations, edit recommendations, or perform practice management tasks such as adding other staff members.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How do I manage my staff members’ permissions?"
        >
          <div>
            Optional permissions are user specific and can be configured from the Staff Management page. After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then select a staff member from the list and click “Manage” to open the account details for that staff member. Assign the desired permissions and click “Update” to finish.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How do I remove a staff member from my practice?"
        >
          <div>
            After you are logged in, click on your name in the top right corner of the page to access the Staff Management page. Then select a staff member from the list and click “Archive Staff Member”. Their account status will be updated to Archived and they will no longer be able to access their account.
          </div>
        </PgAccordion>
        <PgAccordion question="How do I update my personal information?">
          <div>
            All changes can be made from your account dashboard by clicking <b>My Account,</b> found under where your name is listed at the top of the page.
          </div>
        </PgAccordion>
        <PgAccordion
          question="I have a patient I am no longer seeing. How do I delete this patient off my dashboard?"
          answer="Patients cannot be permanently removed from your dashboard. If you have a patient who is no longer active within your practice, you can archive them from within your dashboard. To archive, click Edit Patient in their patient row."
        />


        {/* section 3 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Personalization and Questionnaires
        </div>
        <PgAccordion
          question="How does PureInsight<sup>&trade;</sup> deepen personalization?  "
        >
          <div>
            PureInsight<sup>&trade;</sup> allows you to collect a variety of your patient’s clinically relevant information from questionnaires, DNA, and blood work to help you clearly identify priority areas and help your patients achieve their health and wellness goals.  The DNA data provides nutrigenomic insights to better understand how your patients’ genetics may influence their health which you can use to make highly personalized supplements and lifestyle recommendations based on both the patients’ genetics and questionnaire results. A healthcare provider cannot diagnose or prescribe medication using the PureInsight<sup>&trade;</sup> services including the nutrigenomics report.
          </div>
        </PgAccordion>
        <PgAccordion question="What is the Multi-Symptom Questionnaire (MSQ)?">
          <div>
            The MSQ is for more personalized lifestyle and dietary recommendations. This is a, short and engaging questionnaire that takes 5-7 minutes for patients to complete. The questionnaire provides you with more specific information about your patient to use in combination with their genetic data for a comprehensive personalized plan. While genetic data can help provide a comprehensive personalized plan, it is no longer required and your patient can chose to complete the MSQ only. Responses to the MSQ allow for more refinement of the recommendations. Examples include a patient's habits (such as caffeine consumption), preferences, goals, and others.
          </div>
        </PgAccordion>
        <PgAccordion question="Can my patient complete the Multi-Symptom Questionnaire if they don’t yet have their genetic raw data file?">
          <div>
            Yes, a patient can complete the questionnaire prior to receiving their genetic raw data file. While genetic data remains an option, it is no longer required. You can send an invite to the patient from your patient dashboard. You will be able to view the questionnaire responses from your patient dashboard. To view the responses, click the arrow across from your patient’s name and click View Responses under Questionnaire.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Can a patient edit their responses?"
          answer="Patients will have the ability to edit their responses before submitting their final answers. They will only have one opportunity to make any necessary changes. If you or they need to edit any answers beyond this, a new questionnaire will need to be completed (which can be requested from your dashboard)."
        />
        <PgAccordion question="Can my patient request a new Multi-Symptom Questionnaire after several months of care?">
          <div>
            Yes. From the patient dashboard, your patient can click the arrow across from the patient’s name and click Request New Questionnaire.
          </div>
        </PgAccordion>
        <PgAccordion question="What is the basis of using the additional questionnaires for patients? - Generalized Anxiety Disorder Questionnaire (GAD-7), Patient Health Questionnaire (PHQ-9), and Perceived Stress Scale Questionnaire (PSS-4)?">
          <div>
            <p>Based on how the responses to the Multi-Symptom Questionnaire, you may request your patient complete certain additional questionnaires. These additional assessment instruments can help you gain a deeper understanding of your patient’s health and wellness status.</p>
            <p>These additional questionnaires do not impact the patient’s Multi-Symptom Questionnaire score but helps deepen the understanding of the patient’s health and wellness needs.</p>
          </div>
        </PgAccordion>


        {/* section 4 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Genetic Testing, Labs and Upload
        </div>
        <PgAccordion question="What genetic testing labs are compatible with PureInsight<sup>&trade;</sup>? ">
          <div>
            PureInsight<sup>&trade;</sup> <b>only</b> interprets genetic data
            from 23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup>.
          </div>
        </PgAccordion>
        <PgAccordion question="As a healthcare provider, how do I upload my own 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>  test results to PureInsight<sup>&trade;</sup>?">
          <div>
            When you receive your 23andMe<sup>&reg;</sup> or Ancestry
            <sup>&reg;</sup> test results, log into your PureInsight
            <sup>&trade;</sup> account. To upload and process your own genetic
            data, please do the following:
          </div>
          <ol type="1">
            <li>
              Click
              <b>
                <i> Add New Patient.</i>
              </b>
            </li>
            <li>
              Check the
              <b>
                <i> Add Myself as a Patient.</i>
              </b>
            </li>
            <li>Enter any missing information.</li>
            <li>If you would like to take the Questionnaire, check the box.</li>
            <li>If you have labs to upload, check the box.</li>
            <li>
              Click
              <b>
                <i> Done. </i>
              </b>
              The platform will take you to your patient list.
            </li>
            <li>
              Under
              <b>
                <i> Genetics </i>
              </b>
              Click
              <b>
                <i> Start Upload.</i>
              </b>
            </li>
          </ol>
        </PgAccordion>
        <PgAccordion question="I noticed that 23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup> offer different DNA kits you can purchase. Is there a particular DNA kit that patients should buy if they are interested in using your platform?">
          <div>
            Any DNA kit sold by either 23andMe<sup>&reg;</sup> or Ancestry
            <sup>&reg;</sup> is compatible with PureInsight<sup>&trade;</sup>.
          </div>
        </PgAccordion>
        <PgAccordion question="Can I order a 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> test kit through PureInsight<sup>&trade;</sup>? ">
          <div>
            No. You must order the DNA kit directly through 23andMe
            <sup>&reg;</sup> or Ancestry<sup>&reg;</sup> or other authorized
            retailers. Please note that PureInsight<sup>&trade;</sup> is not
            affiliated with or endorsed by 23andMe<sup>&reg;</sup> or Ancestry
            <sup>&reg;</sup>.
          </div>
        </PgAccordion>
        <PgAccordion question="How do patients download their raw data from 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup>, and upload this data to PureInsight<sup>&trade;</sup>?">
          <div>
            <div>
              <span className="text-decoration-underline fw-bold">
                Through 23andMe
              </span>
              <sup>&reg;</sup>: Once logged into their PureInsight
              <sup>&trade;</sup> account, the patient will need to click the{" "}
              <a
                onClick={() => {
                  setExternalSiteURL("https://you.23andme.com/tools/data/download/");
                  setShowExternalSiteModal(true)
                }}
                target="_blank"
              >
                23andMe
              </a>
              <sup>&reg;</sup> link to log into their account.
            </div>
            <br />
            <div>
              The patient will scroll down to the bottom of the page, check a
              box acknowledging they are downloading from a secure server, and
              then click submit request.
            </div>
            <br />
            <div>
              23andMe<sup>&reg;</sup> requires a 2-step authentication process.
              The patient will receive an email from 23andMe<sup>&reg;</sup> to
              let them know their raw data download is ready. The call to action
              at the bottom of 23andMe<sup>&reg;</sup>’s email takes the patient
              back to their 23andMe<sup>&reg;</sup> account where they can now
              download their raw data file and save it to their computer.
            </div>
            <br />
            <div>
              Lastly, the patient goes back into their PureInsight
              <sup>&trade;</sup> patient dashboard and clicks <b>Upload Your Data,</b>{" "}
              selects their file from the saved location, and clicks{" "}
              <b>Start Upload</b>.
            </div>
            <br />
            <div>
              Once their data is uploaded, the patient will be able to see their
              patient report. The patient’s report does not include supplement
              or lab recommendations. You will also be notified that their
              report is ready to view from your practitioner dashboard.
            </div>
            <br />
            <div>
              <span className="text-decoration-underline fw-bold">
                Through Ancestry
              </span>
              <sup>&reg;</sup>: Once logged in to their PureInsight
              <sup>&trade;</sup> account, the patient will need to click the{" "}
              <a
                target="_blank"
                onClick={() => {
                  setExternalSiteURL("https://www.ancestry.ca/account/signin/?returnUrl=https%3A%2F%2Fwww.ancestry.ca%2Faccount%2Fdata%2F");
                  setShowExternalSiteModal(true)
                }}
              >
                Ancestry.ca
              </a>{" "}
              link to log into their account. Directions are provided on this
              page for the patient to download their genetic raw data file.
            </div>
            <br />
            <div>
              Ancestry.ca requires a 2-step authentication process. The patient
              will receive an email from the testing company to let them know
              their raw data download is ready. The call to action{" "}
              <b>Confirm Data Download</b> at the bottom of the company’s email
              takes the patient back to their
              Ancestry.ca account where they
              can now download their raw data file and save it to their
              computer.
            </div>
            <br />
            <div>
              Lastly, the patient goes back into their PureInsight
              <sup>&trade;</sup> patient dashboard and clicks <b>Upload Your Data,</b>{" "}
              selects their file from the saved location, and clicks{" "}
              <b>Start Upload</b>.
            </div>
            <br />
            <div>
              Once their data is uploaded, the patient will be able to see their
              patient report. The patient’s report does not include supplement
              or lab recommendations. You will also be notified that their
              report is ready to view from your practitioner dashboard.
            </div>
            <br />
          </div>
        </PgAccordion>
        <PgAccordion question="Can I upload data for my patients?">
          <div>
            Yes, you can upload the patient’s data for them. They need to
            provide you their genetic raw data file or access to their testing
            kit account.
          </div>
          <br />
          <div>
            To upload data for your patient, you first need to create the
            patient’s PureInsight<sup>&trade;</sup> account. Click
            <b>
              <i> Add New Patient. </i>
            </b>
            On the next page, you will be asked to enter the patient’s name,
            email address, date of birth and gender. In the Genetics section, select the
            <b>
              <i> Practitioner will upload, </i> option{" "}
            </b>
            which will give you the ability to upload their data file.
          </div>
          <br />
          <div>
            If the patient already has a PureInsight<sup>&trade;</sup> account,
            you will go to
            <b> View Patients</b> and then click on the right arrow found across
            the row of the patient’s name. Then click on <b>Start Upload</b> and
            choose their genetic data file.
          </div>
        </PgAccordion>
        <PgAccordion question="I received an error message when I tried to upload my patient’s genomic data. How do I fix this?">
          <div>
            There are three reasons an error may occur while uploading your
            patient’s genomic data.
          </div>
          <ol type="1">
            <li>
              The data is from a non-compatible testing company. PureInsight
              <sup>&trade;</sup> is only compatible with 23andMe<sup>&reg;</sup>{" "}
              or Ancestry
              <sup>&reg;</sup> test results.
            </li>
            <li>
              Your patient provided results that is in a format other than a
              text or a zip file (like a PDF). Please ensure the file you are
              uploading is in a .txt or .zip file format. This is also the
              common download format from 23andMe<sup>&reg;</sup> or Ancestry
              <sup>&reg;</sup>.
            </li>
            <li>
              The patient’s data is unreadable. Data may become corrupt and is
              unintelligible by our system. Please ask your patient to
              re-download the file from the testing website and send it to you
              again. When their device asks them if they wish to open or save the file, they will need to select save as opening the file can lead to corruption.
            </li>
          </ol>
        </PgAccordion>
        <PgAccordion
          question="How long after patients complete the 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> genetic test will they get their results?"
          answer="Both companies state that a period of 6-8 weeks can be expected. Once the patient receives a notification that the results are ready, the patient is ready to login to the testing company’s website and download their genetic raw data file"
        />
        <PgAccordion question="How does the Lab Upload capability work?">
          <div>
            The lab upload capability easily displays nutrient status for ease in decision-making.This optional feature allows a practitioner to upload results from lab vendors such as labcorp and Quest. You can upload the entire PDF or enter the results manually. An AI-driven engine extracts the data that is relevant to the traits in PureInsight<sup>&trade;</sup>. This is an example of phenotype being displayed along with genotype.
          </div>
        </PgAccordion>
        <PgAccordion question="How do I enter a lab result?">
          <div>
            From your patient list click the arrow under Lab Results click Start Upload. On the Lab Results Entry page enter the vendor name and click on enter labs and follow the directions and example. Add the name of the Lab test, the results, units and the reference values. Once you have entered all the relevant labs you can save the results.
          </div>
        </PgAccordion>
        <PgAccordion question="What is the likelihood of a false positive?">
          <div>
            The likelihood of a false positive is information you would need to
            obtain from the genetic service provider you worked with (23andMe
            <sup>&reg;</sup> or Ancestry<sup>&reg;</sup>).
          </div>
        </PgAccordion>
        <PgAccordion question="My patients can only view the raw genetic data from 23andMe<sup>&reg;</sup> but not download it, what should I tell them?">
          <div>
            Unfortunately, we do not have control over the 23andme<sup>&reg;</sup> system. Nevertheless, we maintain ongoing communication with them, and at present, they do not have a specific timeframe for resolving the issue. I suggest advising your patients to reach out to 23andMe<sup>&reg;</sup> for information on alternative methods to obtain their genetic file so they can upload it in PureInsight<sup>&trade;</sup> and get their patient report. Alternatively, our system is also compatible with genetic data from Ancestry<sup>&reg;</sup>, another testing service, which your patients may want to consider getting their genetic data from.
          </div>
        </PgAccordion>


        {/* section 5 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Report
        </div>
        <PgAccordion question="What does a PureInsight<sup>&trade;</sup> report look like?">
          <div>
            PureInsight<sup>&trade;</sup>  provides an interactive report that
            allows practitioners and patients to explore their results by trait
            category. Practitioners also have access to a printable summary
            report that consolidates all the results into one place. Both
            reports are visible to the healthcare provider.{" "}
            {/* <a
              href="https://pg3-abstract.s3.amazonaws.com/abstract/Sample%20Practitioner%20Report%20PLACEHOLDER.pdf"
              download
            >
              <b>View Sample Report</b>
            </a> */}
          </div>
        </PgAccordion>
        <PgAccordion question="How does a patient generate their PureInsight<sup>&trade;</sup> report?">
          <div>
            <p>For a PureInsight<sup>&trade;</sup> report to be generated, the
              patient’s genetic raw data file must be uploaded into PureInsight
              <sup>&trade;</sup>.</p>
            <p>
              Once logged into their PureInsight<sup>&trade;</sup> patient account, they will be given two options: log into their account on their testing company’s website to download their genetic raw data file or, if they already have their data file downloaded, simply upload their data. When the file is downloaded and saved to their device, they are ready to upload their data into PureInsight<sup>&trade;</sup> and generate their report.
            </p>
          </div>
        </PgAccordion>
        <PgAccordion
          question="How long will it take for PureInsight<sup>&trade;</sup> to interpret my patients' data?"
          answer="Once the genetic data file is uploaded,  using your independent discretion, you will have the opportunity to review the genetic data and recommendations and then release the PureInsight™ patient report and your recommendations for the patient’s viewing. The patient is encouraged to schedule a consultation with you to review their PureInsight™ patient report."
        />
        <PgAccordion question="Where do I find my patients' lab results in the PureInsight<sup>&trade;</sup> report?">
          <div>
            The lab results can be found in the Assessment Recommendations
            column of both the HTML and printable PDF report.
            <br />
            The results can also be viewed from the patient dashboard by
            clicking the arrow at the end of the patient row and click
            <b>
              <i> View Results.</i>
            </b>
          </div>
        </PgAccordion>
        <PgAccordion question="This report looks different from what I am used to. Did you update to a newer version?">
          <div>
            Yes. We have evolved our reports to reflect the current scientific
            knowledge. We offer tutorial videos that highlight the new changes
            to the website functionality and the reports. We also recommend
            scheduling a one-on-one <a
              href="https://karawarecoaching.as.me/puregenomics"
              target="_blank"
              className="cursor-pointer"
              rel="noreferrer"
            >
              consult{" "}
            </a>
            with Business Advisor Kara Ware+ to learn more about the new
            reports in comparison to the previous version. There is no charge
            for your first one-on-one coaching session.
          </div>
        </PgAccordion>
        <PgAccordion
          question="What is the difference between the report I share with my patients and the full DNA report?"
          answer="The report you share focuses on specific areas of a patient’s health and wellness status by allowing you to provide personalized recommendations. The full DNA report provides details about your patient’s analyzed genetic traits."
        />
        <PgAccordion question="How do I save reports to my computer?">
          <div>
            To save the report to your computer, click on
            <b> View and Print Practitioner Report</b> and/or
            <b> View and Print Patient Report</b> at the top of the page. You
            will see a download button on the next screen.
            <br />
            When viewing Past Reports, you may not see the View and Print Report button. Instead, you may see a Print button at the top of the page, please click that to save the report. From the printer options, select Print to PDF. This will save the report to your computer.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Can I edit a personal recommendation that was already shared with a patient?"
          answer="No, but a new recommendation can be created anytime the patient submits new data or completes a questionnaire."
        />
        <PgAccordion
          question="Can reports be generated without supplement recommendations?"
          answer="Patient reports are generated without supplement recommendations until the provider edits and sends the recommendations. Once those recommendations are saved and sent to the patient, they will then be shown on the patient report."
        />


        {/* section 6 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Supplement Recommendations
        </div>
        <PgAccordion
          question="If blood levels or other lab values do not show a low nutrient level, but the PureInsight<sup>&trade;</sup> report recommends a supplement, what should I do?"
          answer="A SNP alone cannot determine an increased nutrient requirement. However, the presence of a SNP helps identify whether a patient is likely to require additional amounts of a specific form of a nutrient. Clinical assessment is necessary in making final decisions regarding the need for supplementation."
        />
        <PgAccordion
          question="How does PureInsight<sup>&trade;</sup> help increase efficiency with supplement recommendations?"
          answer="It decreases time spent creating supplement recommendations, which was a previous pain point per practitioner feedback. Additionally, the supplement dosage and frequency are auto-filled from the product label."
        />
        <PgAccordion
          question="Is there a way to print out the supplement recommendations with edits?"
          answer="Once you edit and send the patient supplement recommendations, it will appear in the patient report, and you can print either the entire patient report or just the page containing the recommendations. These recommendations can also be seen by the patient. Also, you have the ability to add any supplement to the list of recommendations by simply pressing the '+ ADD PRODUCT RECOMMENDATION ' button and a free text box will open."
        />
        <PgAccordion question="Are supplement recommendations and dosage suggestions provided in the report?">
          <div>
            The healthcare provider report includes Pure Encapsulations
            <sup>&reg;</sup> supplement recommendations, along with suggested
            dosages.
          </div>
          <br />
          <div>
            PureInsight<sup>&trade;</sup> prioritizes a list of supplement suggestions based on traits where Consider Action is suggested. The supplement recommendation feature enhances customization by auto-populating recommended doses, quantities, and instructions that you can edit. The platform provides the ability to send supplement recommendations to the patient and easily track if they have been reviewed.
          </div>
          <br />
          <div>
            It is up to the health care provider to decide which supplement recommendations will be visible within the patient report. You are only able to edit the supplement recommendations through the interactive report. Edits made will be reflected in the printable summary report. Once your supplement recommendation list is ready for the patient to view, you can push the recommendations through so the patient can see them within their reports.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Do I need to dispense all recommended supplements for a given trait category?"
          answer="No. For several traits, multiple supplements are suggested. However, the patient may not require all of them. The selection can be fine-tuned by assessing nutrient levels or other types of testing you would normally include in patient evaluations."
        />
        <PgAccordion
          question="How can my patients order the recommended supplements?"
          answer="Healthcare providers who have a Pure Encapsulations Pro account can sign up for the PureInsight™ Virtual Dispensary to enable direct to patient fulfillment."
        />


        {/* section 7 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Nutrigenomics and Traits
        </div>
        <PgAccordion
          question="What are traits?"
          answer="Traits are specific characteristics like eye color, blood type, or the ability to tolerate lactose that are based on an individual's unique genetic variations (SNPs), as well as environmental factors."
        />
        <PgAccordion
          question="What are Single Nucleotide Polymorphisms (SNPs)?"
          answer="SNPs (single nucleotide polymorphisms) occur normally in a person's DNA, and each SNP represents a genetic variation within a single DNA building block in a gene sequence, called a nucleotide. While most SNPs have no effect on a person's health or development, some can help inform lifestyle choices and health and wellness outcomes."
        />
        <PgAccordion question="Which trait categories are supported by PureInsight<sup>&trade;</sup>?">
          <div>
            PureInsight<sup>&trade;</sup> reports offer insights into the
            following trait categories:
          </div>
          <ul>
            <li>Vitamins, minerals & omega-3s</li>
            <li>Detoxification</li>
            <li>Glucose metabolism</li>
            <li>Metabolic & weight management</li>
            <li>Cognitive health & memory</li>
            <li>Immune health</li>
            <li>Energy & fitness</li>
            <li>Cardiovascular health</li>
            <li>Gastrointestinal health</li>
          </ul>
        </PgAccordion>
        <PgAccordion question="Which testing companies’ genetic raw data file provides the most SNPs on your PureInsight<sup>&trade;</sup> report? ">
          <div>
            <div className="pgs-text">
              23andMe<sup>&reg;</sup> and Ancestry<sup>&reg;</sup> use a
              “genotyping chip” to detect patients’ DNA data. The reference SNP
              cluster IDs, also known as rs numbers, that are included on the raw
              data is dependent on which chip was used. These companies commonly
              update their chip versions for a variety of reasons, which can
              impact the raw data available for further analysis. Below is a
              chart that outlines the differences in the most common chip
              versions used in PureInsight<sup>&trade;</sup>. (The “X” indicates
              that the SNP is included in that chip version).
            </div>
            <div className="overflow-x-scroll overflow-x-lg-hidden">
              <table className="pg-table my-3 text-center fw-bold">
                <thead>
                  <tr>
                    <td rowSpan="2" className="text-start">
                      SNP Tested
                    </td>
                    <td colSpan="2">23andMe</td>
                    <td>Ancestry</td>
                  </tr>
                  <tr>
                    <td>Version 4</td>
                    <td>Version 5</td>
                    <td>Version 2</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-start">EDN1 (rs5370)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">GATA3 (rs4143094</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">AOC1 (rs10156191)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">BCMO1 (rs12934922)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">TCN2 (rs1801198)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">SLC23A1 (rs33972313)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">DHCR7 (rs12785878)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">CYP2R1 (rs10741657)</td>
                    <td></td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                  </tr>

                  <tr>
                    <td className="text-start">GPx1P1 (rs1050450)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td className="text-start">DRD2 (rs6277)</td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td>
                      <i className="fas fa-times"></i>
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </PgAccordion>
        <PgAccordion question="What are lab markers that coincide with the PureInsight<sup>&trade;</sup> traits?">
          <div>
            The following tests can be extracted from the labcorp and Quest reports that correspond to the PureInsight<sup>&trade;</sup> traits or lab results can be manually entered from other vendors.
          </div>

          <div className="overflow-x-scroll overflow-x-lg-hidden">
            <table className="pg-table my-3">
              <thead>
                <tr>
                  <td>
                    <b>Vitamins, Mineral & Omega-3 Profile</b>
                  </td>
                  <td>
                    <b>Glucose Metabolism</b>
                  </td>
                  <td>
                    <b>Weight Management</b>
                  </td>
                  <td>
                    <b>Immune Health</b>
                  </td>
                  <td>
                    <b>Exercise Response</b>
                  </td>
                  <td>
                    <b>Cardiovascular Health</b>
                  </td>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Vitamin A (retinol)</td>
                  <td>Fasting Glucose</td>
                  <td>Fasting Glucose</td>
                  <td>IL-6</td>
                  <td>Fasting Glucose</td>
                  <td>hsCRP</td>
                </tr>

                <tr>
                  <td>Vitamin B6</td>
                  <td>Hemoglobin A1c</td>
                  <td>Hemoglobin A1c</td>
                  <td>Tumor Necrosis Factor-Alpha</td>
                  <td>Hemoglobin A1c</td>
                  <td>Oxidized LDL</td>
                </tr>

                <tr>
                  <td>Homocyst(e)ine</td>
                  <td>LP-IR Score</td>
                  <td>Adiponectin</td>
                  <td>Neutrophil % (Serum)</td>
                  <td></td>
                  <td>HDL-C</td>
                </tr>

                <tr>
                  <td>Folate, RBC</td>
                  <td>Triglycerides</td>
                  <td></td>
                  <td>Eosinophil % (Serum)</td>
                  <td></td>
                  <td>HDL-P</td>
                </tr>

                <tr>
                  <td>Folate (Folic Acid),Serum</td>
                  <td></td>
                  <td></td>
                  <td>Basophil % (Serum)</td>
                  <td></td>
                  <td>Triglycerides</td>
                </tr>

                <tr>
                  <td>Vitamin B12</td>
                  <td></td>
                  <td></td>
                  <td>GlyCA</td>
                  <td></td>
                  <td>Coenzyme Q10</td>
                </tr>

                <tr>
                  <td>Methylmalonic acid</td>
                </tr>

                <tr>
                  <td>Vitamin D, 25-Hydroxy</td>
                </tr>

                <tr>
                  <td>Iron</td>
                </tr>

                <tr>
                  <td>Iron Binding Capacity (TIBC)</td>
                </tr>


                <tr>
                  <td>Ferritin</td>
                </tr>

                <tr>
                  <td>Alkaline Phosphatase</td>
                </tr>

                <tr>
                  <td>OmegaCheck</td>
                </tr>
              </tbody>
            </table>
          </div>
        </PgAccordion>
        <PgAccordion question="If there are millions of SNPs, why does the report only show a limited number?">
          <div>
            Most SNPs have no effect on nutritional health and therefore lack
            nutritional relevance. PureInsight<sup>&trade;</sup> includes SNPs
            that are:
          </div>
          <ul>
            <li>Relevant to health</li>
            <li>Validated by published research</li>
            <li>Responsive to nutritional support</li>
          </ul>
        </PgAccordion>
        <PgAccordion question="Do you plan to add more SNPS to the reports?">
          <div>
            If you have a specific SNP that you would like us to add,
            <a onClick={() => navigate("/contact-us")}>
              {" "}
              please let us know{" "}
            </a>
            and our scientific team will investigate the information.
          </div>
          <div>
            As more SNPs are added to the report, your patients' reports will be
            automatically updated. New SNPs are added each year as scientific
            evidence becomes available
          </div>
        </PgAccordion>
        <PgAccordion question="If there is a SNP that’s not available on the PureInsight<sup>&trade;</sup> report, can I still see how the patient is coding?">
          <div>
            PureInsight<sup>&trade;</sup> does offer a Supplemental Genotype
            Report. These SNPs have yet to have enough published literature
            substantiating the effects on diet and lifestyle. At some point,
            SNPs on the Supplemental Genotype report may migrate over to the
            PureInsight<sup>&trade;</sup> report. If you have a SNP you wish to
            see that is not already on the Supplemental Genotype report,{" "}
            <a
              className="cursor-pointer"
              onClick={() => navigate("/contact-us")}
              target="_blank"
            >
              contact us
            </a>
          </div>
        </PgAccordion>
        <PgAccordion question="How do I know whether the patient is heterozygous or homozygous for a specific SNP?">
          <div>You simply compare the patient variant vs the risk variant.</div>
          <br />
          <div>
            For instance, in the Folate trait under the MTHFR C677T rs1801133
            SNP the risk variants are CT (Heterozygous) or TT (Homozygous). Both
            variants are shown to affect folic acid conversion.
          </div>
          <br />
          <div>
            If the patient variant is CC and the result is No Action, you know the
            patient is the wild type. Wild type and homozygous always have two
            of the same alleles whereas heterozygous have one of each chemical
            code, therefore, two different alleles.
          </div>
        </PgAccordion>
        <PgAccordion question="As new SNPs become available, will patients need to repeat their 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> tests?">
          <div>
            No, the test only needs to be performed once. As new SNPs are added,
            the PureInsight<sup>&trade;</sup> reports will be automatically updated to reflect
            these SNPs.
          </div>
        </PgAccordion>

        {/* section 8 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Polygenic Scores and Traits
        </div>
        <PgAccordion
          question="What is a polygenic score?"
        >
          <div>
            A polygenic score (PGS) is a measurement of genetic predisposition that analyzes many contributing SNPs, instead of just one.  Most traits are determined by many SNPs, not just one.  Since PGS looks at many SNPs, it is a more comprehensive genetic assessment and is generally more accurate than single SNP analysis.
          </div>
        </PgAccordion>
        <PgAccordion
          question="What do the scores mean?"
        >
          <div>
            In PureInsight™, the score reflects the number of risk alleles that contribute to the trait.   The higher the score, the greater the genetic predisposition.  For example, a high score for vitamin B<sub>12</sub> indicates that an individual’s B<sub>12</sub> needs are greater than the needs of someone with a lower score.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How long will it take to receive the Polygenic Risk report?"
        >
          <div>
            Calculating the Polygenic scores should take no more than an hour to be generated, and once the score is ready, an email notification will be sent to both you and your patient. If your scores were not generated by then, please contact us <a href="/contact-us">here</a>.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Why were enhancements made for just a few select traits?"
        >
          <div>
            The accuracy of scores and intake recommendations depends on robust genome-wide association studies and development of mathematical models requiring population data on blood levels and dietary intake information. This information is currently not available for all traits. As part of our ongoing research and development, we will continue to evaluate new traits for compatibility with PGS and personalized intake recommendations.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How are the SNPs for the polygenic trait selected?"
        >
          <div>
            The contributing SNPs analyzed by the PGS were selected based on genome-wide association studies and other population data sources. SNPs with strong and statistically significant effects on nutrient levels are included in a polygenic score.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How accurate are the scores and intake recommendations?"
        >
          <div>
            The polygenic scores and personalized intake recommendations are calculated from population data showing correlations between nutrient intakes, blood levels and genotypes. These calculations are based on genetics. Practitioners are encouraged to adjust nutrient intake recommendations based on labs and other assessments.
          </div>
        </PgAccordion>

        {/* section 9 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Privacy and Security
        </div>
        <PgAccordion
          question="Where can I go to learn more about my patient’s privacy?"
        >
          <div>
            For more information about our privacy and security practices, please review our <a href="/privacy-policy">Privacy Policy</a>.
          </div>
        </PgAccordion>
        <PgAccordion
          question="What privacy and security measures are taken to protect patient data?"
        >
          <div>
            <p>We use industry standard practices to store your genetic test results and other Personal Information you provide to us in a secured database, which employs a number of security measures. The specific security measures used are based on the sensitivity of the Personal Information collected.</p>
            <p>All connections to and from our website are encrypted using Secure Socket Layer (SSL) technology. In addition, patients’ genetic raw data is completely de-identified and encrypted in our database.</p>
            <p>As well as protecting users' information from unauthorized access from those outside of the company, we strictly limit access to this database from within the company. Only employees who need the information to perform a specific job (for example, a customer service representative) are granted access to personally identifiable information. </p>
          </div>
        </PgAccordion>
        <PgAccordion
          question="How secure and private is PureInsight<sup>&trade;</sup>?"
        >
          <div>
            <p>We store patients’ genetic test results without their name or other common identifying information. This means that no outside affiliate can access your patients’ genetic data and that their Personal Information has been unattached from their DNA. </p>
            <p>We use secure server software to encrypt Personal Information (including sensitive health information and genetic data), and we only partner with security companies that meet and commit to our security standards. While we cannot guarantee that loss, misuse, or alteration of data will not occur, we use reasonable efforts to prevent this.</p>
          </div>
        </PgAccordion>
        <PgAccordion
          question="Will any information be shared?"
          answer="Only you and your patient will have access to their genetic data file. We do not share with third parties any patient’s individual data, except as legally required or with his/her explicit consent."
        />

        {/* section 10 */}
        <div className="pgs-heading mt-5 mb-3 textc-primary">
          Virtual Dispensary
        </div>
        <PgAccordion
          question="How is the PureInsight<sup>&trade;</sup> virtual dispensary different from the Pure Patient Direct dispensary?"
        >
          <div>
            These are two separate dispensaries that offer direct to patient fulfillment of Pure Encapsulations products. The PureInsight<sup>&trade;</sup> dispensary allows you to use evidence-based recommendations to personalize your patients' supplement shopping experience. If you already use the Pure Patient Direct dispensary, you can use your account to automatically populate all of your dispensary settings in PureInsight<sup>&trade;</sup>. 
          </div>
        </PgAccordion>
        <PgAccordion
          question="What are the steps to join the program as a practitioner?"
        >
          <div>
            Please follow these steps to join:
            <ol type="A">
              <li>If you are a Pure Encapsulations Account Holder, please verify your Pure Encapsulations account to get started; if you do not have a Pure Encapsulations account, you can <a href="https://www.pureencapsulationspro.com/patient/registerorlogin" target="_blank" rel="noreferrer">register</a>.</li>
              <li>Follow the instructions to complete your virtual dispensary setup. You must complete the Stripe account setup before your virtual dispensary can be activated. Learn more on <a href="https://www.pureencapsulationspro.com/patient-direct#stripe" target="_blank" rel="noreferrer">Stripe</a>, a third-party payment platform.</li>
              <li>Tax certificates are required for states in which your patients purchase supplements. Upload a resale certificate to avoid paying tax on these transactions. Learn more on the benefits of uploading your <a href="https://www.pureencapsulationspro.com/patient-direct#resale-certificate" target="_blank" rel="noreferrer">resale certificate</a>.</li>
              <li>As part of your account setup, you can choose to provide your patients with a discount ranging from 5-35%</li>
              <li>After the steps above are completed, your virtual dispensary will be active. </li>
            </ol>
          </div>
        </PgAccordion>
        <PgAccordion
          question="What is Stripe? Why do I need this?"
        >
          <div>
            Stripe is a payment platform that makes taking online payments from credit or debit cards easy and allows you to manage your business online.
          </div>
        </PgAccordion>
        <PgAccordion
          question="What is the process for creating or connecting a Stripe account?"
        >
          <div>
            <ul>
              <li>Please refer to the following instructional video guides featured on Stripe to assist you with creating or connecting your Stripe account:</li>            
              <ol type="A">
                <li><a href="https://support.stripe.com/questions/getting-started-with-stripe-create-or-connect-an-account" target="_blank" rel="noreferrer">Full Account Creation and Connection Video Guides</a></li>
                <li><a href="https://youtu.be/u9YcbWDioC4" target="_blank" rel="noreferrer">Creating or Connecting your Stripe Account</a></li>
                <li><a href="https://youtu.be/hmKdZVhDk7I" target="_blank" rel="noreferrer">Verification follow-ups</a></li>
              </ol>
            </ul>
          </div>
        </PgAccordion>
        <PgAccordion
          question="What should I do if I don't have a website or social media account when signing up with Stripe?"
        >
          <div>
            <ul>
              <li>If you don't have a website or social media account when signing up with Stripe, you can take the following steps:</li>
              <ol type="A">
                <li><b>Provide alternative business information:</b> If you don't have a website or social media account, you can focus on other aspects of your business. Provide detailed descriptions of your products or services, your business model and any relevant information that can help Stripe understand your business.</li>
                <li><b>Explain your marketing and sales strategy:</b> If you don't have a website or social media presence, describe your marketing strategy, including any offline methods such as in-person sales, direct sales or partnerships with other businesses.</li>
                <li><b>Highlight your business experience:</b> If you have prior experience in a similar industry or have successfully operated a similar business in the past, make sure to mention it. Demonstrating your knowledge and expertise can help build trust with Stripe.</li>
                <li><b>Consider providing your LinkedIn page</b> as supporting evidence.</li>
                <li><b>Provide supporting documentation:</b> Stripe may request additional documentation to verify your business. This could include business licenses, permits, certifications or any other relevant documents that can validate your business operations.</li>
                <li>
                  <p><b>Contact Stripe's support team:</b> If you are unsure about how to proceed or have specific questions regarding the sign-up process reach out to Stripe's customer support. They will be able to provide guidance and assist you in completing the sign-up process.</p>
                  <p>Remember, Stripe evaluates each application on a case-by-case basis. Providing comprehensive and transparent information about your business will increase your chances of successfully signing up with Stripe, even without a website or social media presence.</p>
                  <p>For more information, please review <a href="https://support.stripe.com/questions/i-do-not-have-a-website-or-social-media-account-how-do-i-submit-my-application" target="_blank" rel="noreferrer">Stripe's guidelines for those applying without a social media account or website</a>.</p>
                </li>
              </ol>
            </ul>
          </div>
        </PgAccordion>
        <PgAccordion
          question="What are the reasons preventing me from creating an account with Stripe?"
        >
          <div>
            <ul>
              <li>Here are some possible reasons why you might be facing difficulties in creating an account with Stripe:</li>
              <ol type="A">
                <li><b>Incomplete or incorrect information:</b> Make sure you have provided all the required information accurately during the account creation process. Verify that you have entered your personal details, business information, and banking details correctly.  </li>
                <li><b>Unsupported business type:</b> Stripe has certain restrictions on the types of businesses they support, which include telemedicine, telehealth services and online pharmacies and may require prior written approval from Stripe to complete account creation. Some high-risk or prohibited industries may not be eligible for a Stripe account. Check if your business falls under any of these categories</li>
                <li><b>Compliance and verification issues:</b> Stripe enforces strict compliance and verification procedures to prevent fraud and ensure security. If your account fails to meet their verification requirements or raises any compliance concerns, it may result in the rejection of your account creation.</li>
                <li>
                  <p><b>Previous account issues:</b> If you have had a previous Stripe account that was closed or suspended, it could affect your ability to create a new account. Stripe maintains records and may restrict individuals or businesses with a history of account violations.</p>
                  <p>If you're still unable to create an account with Stripe after considering these factors, contact <a href="https://support.stripe.com/contact/login" target="_blank" rel="noreferrer">Stripe's customer support</a> directly. They will be able to provide specific information about your account and assist you further in resolving the issue.</p>
                </li>
              </ol>
            </ul>
          </div>
        </PgAccordion>
        <PgAccordion
          question="Why do I need to add my payment information into Stripe?"
        >
          <div>
            We require your payment information inside Stripe to ensure you will receive your proceeds from your patients' orders. 
          </div>
        </PgAccordion>
        <PgAccordion
          question="Is a resale certificate required to join the program?"
        >
          <div>
            No, a resale certificate is not required. If you don't provide a resale certificate, you will be charged tax on the transaction between the patient and you (according to state law).
          </div>
        </PgAccordion>
        <PgAccordion
          question="Can my patients order from the entire Pure Encapsulations catalog?"
        >
          <div>
            Yes, your patients will have access to the entire Pure Encapsulations product line.
          </div>
        </PgAccordion>
        <PgAccordion
          question="Where can I see my patients' orders?"
        >
          <div>
            You will be able to view and track all your patients' orders. Please log in to your account and click "Order History" on your dashboard. 
          </div>
        </PgAccordion>
        <PgAccordion
          question="Can my patients track their orders?"
        >
          <div>
            Yes, patients will receive an email confirmation when they place their order, along with their tracking information once their order has shipped.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How do my patients remit payment for their order?"
        >
          <div>
            They pay for their orders directly on the website, and they are shipped fast and free directly to their door.
          </div>
        </PgAccordion>
        <PgAccordion
          question="How long will it take for me to get my earnings once a patient places an order?"
        >
          <div>
            <ul>
              <li>
                You should see the order transaction details appear in your Stripe account within 48 hours of your patient placing an order. If your Stripe account is configured for daily payout, order funds are paid within 3 business days, but <a href="https://stripe.com/docs/payouts" target="_blank" rel="noreferrer">your very first payout with a new Stripe account can take 7-14 days</a>.
              </li>
            </ul>
          </div>
        </PgAccordion>
        <PgAccordion
          question="Who can I contact for assistance?"
        >
          <div>
            <ul>
              <li>
                <div class="pg-text">
                  For any questions, our Customer Service team is available M-F, 8:30am – 7:00pm (ET) by phone: 800-263-5861 or by email: <a href="emailto:customerservice@pureencapsulations.com" class="pg-text">customerservice@pureencapsulations.com</a>
                </div>
                <div class="pg-text">
                  For all other questions, our Technical Support team is available M-F, from 9:00am – 5:00pm (ET) by phone: 877-760-0323 or by email: <a href="emailto:pureinsight@pureencapsulations.com" class="pg-text">pureinsight@pureencapsulations.com</a>
                </div>
              </li>
            </ul>
          </div>
        </PgAccordion>
      </div>
    </div>
  );
}

export default CAProviderFAQ;