import { Modal } from 'react-bootstrap'
function PgPrompt({ show, onClose, onAccept, onCancel }) {
    return (
        <Modal show={show} onHide={onClose} bsPrefix="border-radius modal" contentClassName="border-radius" style={{borderRadius:8}}>
            <div className="p-3">
                <div className="pg-text__bold">Are you sure to go back to Patient page ?</div>
                <div className="text-danger">Note : Your file will not be uploaded</div>
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn bg-sky-blue px-4 mx-1" onClick={onCancel}>Cancel</button>
                    <button className="btn bgc-primary text-light px-5" onClick={onAccept}>Yes</button>
                </div>
            </div>
        </Modal>
    )
}

export default PgPrompt
