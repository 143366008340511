import React, { Component, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getLifeStyleSuggestionDetails } from "../../../store/actions/patientReport";
import "../../../styles/lifestyle.css";
import _ from "lodash";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import i_blue from "../../../assets/i_blue.png";
import i from "../../../assets/i.png";
import down from "../../../assets/down.png";
import right from "../../../assets/right.png";

function LifestyleItemDetails({title,description,lifestyleKey}) {

  // redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const currentReportId = useSelector(state => state.patientReport.currentReportId);

  const dispatch= useDispatch();
  // redux actions
  const getLifeStyleSuggestionDetailsAction = (token,currentPracticeID,currentPractice,reportId,lifestyleKey) => dispatch(getLifeStyleSuggestionDetails(token,currentPracticeID,currentPractice,reportId,lifestyleKey));



  const { t, i18n } = useTranslation();
  const [lifestyleDetails, setLifestyleDetails] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [
    additionalConsiderationItems,
    setAdditionalConsiderationItems,
  ] = useState([]);

  useEffect(() => {
    getLifeStyleSuggestionDetailsAction(
      token,
      currentPracticeID,
      currentPractice,
      currentReportId,
      lifestyleKey
    ).then((res) => {
      setLifestyleDetails(res.payload);
      var itemArr = [];
      for (var k in res.payload.additional_considerations) itemArr.push(k);
      setAdditionalConsiderationItems(itemArr);
    });
  }, []);

  return (
    <div>
      {"patient_details" in lifestyleDetails &&
        lifestyleDetails.patient_details !== "" && (
          <div className="row pt-1">
            <div className="col-lg-3 col-12 mb-3">
              <div className="pg-text__bold">{t('lifestyle_line_1')}</div>
            </div>
            <div className="col-lg-9 col-12 mb-3">
              <div
                dangerouslySetInnerHTML={{
                  __html: lifestyleDetails.patient_details,
                }}
              />
            </div>
          </div>)}
      <div className="row pt-1">
        <div className="col-lg-3 col-12 mb-3">
          <div className="pg-text__bold">{t('lifestyle_line_10')}</div>
        </div>
        <div className="col-lg-9 col-12 mb-3">
          <div
            dangerouslySetInnerHTML={{
              __html: lifestyleDetails.primary_suggestion,
            }}
          />
        </div>
      </div>
      <div
        className="row pt-5 pt-4"
        style={{ borderTop: "0.4px solid #d8d8d8" }}
      >
        <div className="col-lg-3 col-12 mb-3">
          <div className="pg-text__bold">{t('lifestyle_line_5')}</div>
        </div>
        <div className="col-lg-9 col-12 mb-3">
          <div className="row">
            <div className="col-lg-12">
              {additionalConsiderationItems.map(
                (additionalConsiderationItem, index) => {
                  return (
                    <div index={index}>
                      <div className="mb-2">
                        {additionalConsiderationItem}
                        <Popup
                          trigger={(open) =>
                            open ? (
                              <img
                                src={i_blue}
                                style={{
                                  marginLeft: "4px",
                                  cursor: "pointer",
                                  width: "16px",
                                }}
                              />
                            ) : (
                              <img
                                src={i}
                                style={{
                                  marginLeft: "4px",
                                  cursor: "pointer",
                                  width: "16px",
                                }}
                              />
                            )
                          }
                          position="top center"
                          closeOnDocumentClick
                        >
                          <div style={{ padding: "12px" }}>
                            <p className="mb-0">
                              {t('trait')}:{" "}
                              {_.map(
                                lifestyleDetails.additional_considerations[
                                additionalConsiderationItem
                                ],
                                "trait"
                              ).join("; ")}
                            </p>
                            <p className="mb-0">
                              {t('snp')}:{" "}
                              {_.map(
                                lifestyleDetails.additional_considerations[
                                additionalConsiderationItem
                                ],
                                "snp"
                              ).join("; ")}
                            </p>
                          </div>
                        </Popup>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LifestyleItemDetails;
