// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  width: 300px;
  position: absolute;
  top: 0;
  transition: all 0.1s ease-in-out;
  transform: translateX(-100%);
  display: none;
  height: 100vh;
  z-index: 999999;
}

.sidebar-container.active__1 .sidebar {
  opacity: 1;
  /* visibility: visible; */
  position: fixed;
  transition: all 0.1s ease-in-out;
  transform: translateX(0);
  z-index: 10000000;
  width: 55%;
  height: 100vh;
  box-shadow: 0 0 15px rgba(0 0 0 / 15%);
}

/* .active__1 {
  opacity: 1;
  position: fixed;
  transition: all 0.1s ease-in-out;
  transform: translateX(0);
  z-index: 10000000;
  width: 55%;
  height: 100vh;
  box-shadow: 0 0 15px rgba(0 0 0 / 15%);
} */

.anchor {
  color: #fff !important;
  font-size: 1rem;
  text-decoration: none;
  margin: 1rem;
  border-bottom: gray 1px solid;
  padding-bottom: 1rem;
}

.sidebar-container.active__1 .sidebar-backdrop {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 110vw;
  background: rgba(0, 0, 0, 0.7);
  transition: transform 0.01s ease-in-out;
}
`, "",{"version":3,"sources":["webpack://./src/styles/sidenav.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,MAAM;EACN,gCAAgC;EAChC,4BAA4B;EAC5B,aAAa;EACb,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,eAAe;EACf,gCAAgC;EAChC,wBAAwB;EACxB,iBAAiB;EACjB,UAAU;EACV,aAAa;EACb,sCAAsC;AACxC;;AAEA;;;;;;;;;GASG;;AAEH;EACE,sBAAsB;EACtB,eAAe;EACf,qBAAqB;EACrB,YAAY;EACZ,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,MAAM;EACN,OAAO;EACP,aAAa;EACb,YAAY;EACZ,8BAA8B;EAC9B,uCAAuC;AACzC","sourcesContent":[".sidebar {\n  width: 300px;\n  position: absolute;\n  top: 0;\n  transition: all 0.1s ease-in-out;\n  transform: translateX(-100%);\n  display: none;\n  height: 100vh;\n  z-index: 999999;\n}\n\n.sidebar-container.active__1 .sidebar {\n  opacity: 1;\n  /* visibility: visible; */\n  position: fixed;\n  transition: all 0.1s ease-in-out;\n  transform: translateX(0);\n  z-index: 10000000;\n  width: 55%;\n  height: 100vh;\n  box-shadow: 0 0 15px rgba(0 0 0 / 15%);\n}\n\n/* .active__1 {\n  opacity: 1;\n  position: fixed;\n  transition: all 0.1s ease-in-out;\n  transform: translateX(0);\n  z-index: 10000000;\n  width: 55%;\n  height: 100vh;\n  box-shadow: 0 0 15px rgba(0 0 0 / 15%);\n} */\n\n.anchor {\n  color: #fff !important;\n  font-size: 1rem;\n  text-decoration: none;\n  margin: 1rem;\n  border-bottom: gray 1px solid;\n  padding-bottom: 1rem;\n}\n\n.sidebar-container.active__1 .sidebar-backdrop {\n  z-index: 99;\n  position: fixed;\n  top: 0;\n  left: 0;\n  height: 100vh;\n  width: 110vw;\n  background: rgba(0, 0, 0, 0.7);\n  transition: transform 0.01s ease-in-out;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
