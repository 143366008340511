import { useMutation } from 'react-query';
import { Modal } from 'react-bootstrap';
import toastr from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { FARecommendationItem } from './FARecommendationItem';

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;



const fetchPDFReport = async ({ userType, token, currentPractice, currentType, currentReportId, currentPatientId, currentPracticeID }) => {
    const response = await fetch(
        `${protocol}//${currentPractice}${PG3_BASE_URL}/${currentType}/pg4/reports/${currentReportId}/${currentPatientId}/pdf_view.pdf?further_assessment_report=true`,
        {
            headers: {
                "Content-Type": "application/pdf",
                Authorization: token,
                "Current-Type": userType,
                "Current-Practice": currentPracticeID,
            }
        }
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.blob();
};


const FurtherAssessmentCard = ({
    currentPractice,
    currentPracticeID,
    token,
    userType,
    currentReportId,
    currentPatientId,
    reportData,
    t
}) => {
    const [showFA, setShowFA] = useState(false);

    return (
        <div>
            {showFA && (
                <FAModal
                    reportData={reportData}
                    userType={userType}
                    show={showFA}
                    onCancel={() => setShowFA(false)}
                    currentPractice={currentPractice}
                    currentPracticeID={currentPracticeID}
                    token={token}
                    currentReportId={currentReportId}
                    currentPatientId={currentPatientId}
                />
            )}
            <div className="d-flex flex-wrap justify-content-between align-items-center bg_secondary p-2 p-lg-4">
                <div className=" w-sm-100 w-75">
                    <b className="pg4-text pg4-text__subheading">Further Assessment</b>
                    <p>
                        Based on the data provided about your patient, here are additional tests you could consider to further personalize their treatment plan.
                    </p>
                </div>
                <button className="px-5 w-sm-100 pg4-btn pg4-btn_outline" onClick={() => setShowFA(true)}>
                    View Details
                </button>
            </div>
        </div>
    );
};

export default FurtherAssessmentCard;

const FAModal = ({
    show,
    onCancel,
    reportData,
    userType,
    token,
    currentPractice,
    currentPracticeID,
    currentReportId,
    currentPatientId,
}) => {
    let currentType = userType
    const [data, setData] = useState(reportData['further_assessment'] || {});
    const [focusAreas, setFocusAreas] = useState([]);
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const { mutate: getPDF, isLoading, error } = useMutation(
        ({ userType, token, currentPractice, currentType, currentReportId, currentPatientId, currentPracticeID }) =>
            fetchPDFReport({ userType, token, currentPractice, currentType, currentReportId, currentPatientId, currentPracticeID })
    );

    useEffect(() => {
        setFocusAreas(() => reportData?.focus_areas?.slice(0, 3)?.map(trait => trait?.focus_id));
        setData(reportData['further_assessment']);
    }, [reportData]);
    const handlePDFDownload = (isDownload) => {
        if (isDownload) setIsLoadingDownload(true)
        else setIsLoadingPrint(true)
        getPDF(
            { userType, token, currentPractice, currentType, currentReportId, currentPatientId, currentPracticeID },
            {
                onSuccess: (pdfBlob) => {
                    const url = window.URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));
                    if (isDownload) {
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'report.pdf');
                        document.body.appendChild(link);
                        link.click();
                    } else {
                        const printWindow = window.open(url);
                        if (printWindow) {
                            printWindow.onload = function () {
                                printWindow.print();
                            };
                        }
                    }
                },
                onError: () => {
                    toastr.error('Failed to load PDF');
                },
                onSettled: () => {
                    setIsLoadingDownload(false)
                    setIsLoadingPrint(false)
                }
            }
        );
    };

    return (
        <Modal
            show={show}
            size="lg"
            onHide={onCancel}
            contentClassName="border-radius"
            backdrop="static"
            className="edit-practitioner-modal modal-dialog-scrollable"
            id="edit-practitioner-modal"
            role="dialog"
            aria-modal="true"
            aria-labelledby="further-assessment-modal-title"
        >
            <Modal.Header className="d-flex flex-column w-100 align-items-end">
                <div className="text-end">
                    <button className="pg4-btn bg-transparent fs-3" onClick={onCancel} aria-label="Close">
                        <i className="fas fa-times"></i>
                    </button>
                </div>
                <div className="d-flex flex-wrap justify-content-between w-100 mt-2">
                    <div className="w-sm-100">
                        <h1 id="further-assessment-modal-title" className="pg4-text__subheading text_primary">Further Assessment</h1>
                        <div className="d-flex flex-wrap">
                            {focusAreas?.map((fa, idx) => (
                                <span className={`pill pill-${fa?.toLocaleLowerCase()}`} key={idx}>{fa}</span>
                            ))}
                        </div>
                    </div>
                    <div className="d-flex flex-column gap-1 w-sm-100">
                        <button
                            className="pg4-btn pg4-btn_outline"
                            onClick={() => handlePDFDownload(true)}
                            aria-label="Save as PDF"
                            role="button"
                            disabled={isLoading}
                        >
                            {isLoadingDownload ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-download"></i>} Save as PDF
                        </button>
                        <button
                            className="pg4-btn pg4-btn_outline"
                            onClick={() => handlePDFDownload(false)}
                            aria-label="Print Report"
                            role="button"
                            disabled={isLoading}
                        >
                            {isLoadingPrint ? <i className="fas fa-spinner fa-spin"></i> : <i className="fas fa-print"></i>} Print Report
                        </button>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <b className="pg4-text__subheading text_primary">Recommendation</b>
                <div>
                    {data?.recommendations?.map((ri, idx) => (
                        <FARecommendationItem key={idx} focusAreas={focusAreas} data={ri} userType={userType} />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};

