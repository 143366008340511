import React, { Component, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { arrayDisplay } from "../../methods";
import { getWelcomeQuestion } from "../../store/actions/questions";


function ViewPatientAnswers({ history }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const [patientAnswers, setPatientAnswers] = useState([]);
  const navigate = useNavigate();
  const [conversationItems, setConversationItems] = useState([]);
  const [patientAnswer, setPatientAnswer] = useState("");
  const patientId = history?.location?.state?.patientId;

  useEffect(() => {
    dispatch(getWelcomeQuestion(patientId)).then((res) => {
      const sessionAnswers = JSON.parse(res.payload.session).questionnaire;
      const newanswers = _.filter(sessionAnswers, function (o) {
        return parseInt(o.id) > 1.2;
      });
      setPatientAnswers(newanswers);
    });
  }, []);

  return (
    <div>
      <div className="container-fluid">
        <div className="container-xxll pt-1 pt-lg-5 px-lg-5 px-2">
          <div className="row mt-4 justify-content-center justify-content-lg-between">
            <div
              className="pg-link"
              onClick={() => navigate("/practice/patients")}
            >
              <i className="fa fa-chevron-left me-2" aria-hidden="true"></i>
              Patients
            </div>
            <div className="col-12 col-lg-6 order-lg-0 order-1">
              <hr className="my-5 d-block d-lg-none" />
              <div className="pg-title"> Questionnaire</div>
            </div>
          </div>
        </div>
      </div>
      {true && (
        <div>
          <div className="review-answers-wrapper">
            <div className="container-fluid">
              <div>
                <p className="pg-heading review-header">View patient answers</p>
              </div>
              {
                <div>
                  {patientAnswers.map((item, index) => {
                    return (
                      <div className="row" index={index}>
                        <hr />
                        <div className="col-lg-6 col-12 pg-text__bold mb-3" dangerouslySetInnerHTML={{ __html: item.question_text }}
                        >

                        </div>
                        <div className="col-lg-4 col-12">
                          {arrayDisplay(item.answer_text)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
            </div>
          </div>
          <div className="bgc-secondary text-center p-3">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4">
                <button
                  className="btn bg-sky-blue px-5 textc-primary  w-100"
                  onClick={() => navigate("/practice/patients")}
                >
                  GO BACK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default ViewPatientAnswers;
