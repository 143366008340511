import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import warningIcon from "../../../assets/warning.png";
import TraitCardDropDown from "./TraitCardDropDown";
import { pg3_report_icons, pg3_report_white_icons } from "../../Patient/PG4/constant";

const TraitCard = ({ traitData, index, updateSelectedGrid, selectedGrid }) => {
  const { t, i18n } = useTranslation();
  const [onHoveredBGStyle, setonHoveredBGStyle] = useState("#fff");
  const [onHoveredTextStyle, setonHoveredTextStyle] = useState("#000");
  const [showPopup, setShowPopup] = useState(index == 0);
  const [onHovered, setOnHovered] = useState(index == 0);

  useEffect(() => {
    if (selectedGrid == index) {
      setonHoveredBGStyle(traitData.color);
      setonHoveredTextStyle("#fff");
      setShowPopup(true)
    }
    else {
      setonHoveredBGStyle("#fff");
      setonHoveredTextStyle("#000");
      setOnHovered(false);
      setShowPopup(false)
    }
  }, [selectedGrid])

  return (
    <div className="col-12 col-lg-4 col-md-6 position-relative">
      <div
        onClick={() => {
          setShowPopup(!showPopup)
          updateSelectedGrid(index)
        }
        }
        className="text-center rounded border border-radius position-relative shadow-sm cursor-pointer"
        style={{
          transition: "all .3s ease-in-out",
          background: onHoveredBGStyle,
          color: `${onHoveredTextStyle} `,
          // minHeight:'250px'
        }}
        onMouseEnter={(e) => {
          setonHoveredBGStyle(traitData.color);
          setonHoveredTextStyle("#fff");
          setOnHovered(true);
          //setShowPopup(false);
        }}
        onMouseLeave={(e) => {
          setonHoveredBGStyle("#fff");
          setonHoveredTextStyle("#000");
          setOnHovered(false);
          setShowPopup(false);
        }}
      >
        <div className="text-center px-3 position-relative ">
          <span
            className="pg-text position-absolute my-2"
            style={{ right: 20, top: -10, color: onHoveredTextStyle }}
          >
            {traitData.considerableTraits > 0 && (
              <span>
                {traitData.considerableTraits}{" "}
                <img src={warningIcon} alt="" className="mb-1" />
                {/* <i className="fas fa-info-circle text-warning "></i> */}
              </span>
            )}
          </span>
          <div className="my-3" style={{ height: "100px" }}>
            <img
              src={onHovered ? pg3_report_white_icons[traitData.title] : pg3_report_icons[traitData.title]}
              alt={traitData.title}
              className="h-100"
            />
          </div>
          <div
            className="pg-text__bold text-center my-2"
            style={{ color: `${onHoveredTextStyle} ` }}
          >
            {traitData.title}
          </div>
        </div>
        <div>
          <div
            className="pg-text text-center mt-2 mb-1 pb-1 position-relative text-lowercase"
            style={{ color: `${onHoveredTextStyle} ` }}
          >
            {traitData.traitsLength} {t('traits')}
            <i
              className="fas fa-chevron-right transition-all mb-n2 mx-2"
              style={{
                transform: (showPopup && (selectedGrid == index)) ? "rotate(90deg)" : "rotate(0deg)",
              }}
            ></i>
            {showPopup && (selectedGrid == index) && (
              <TraitCardDropDown
                traits={traitData.traits}
                color={traitData.color}
                positionTop={35}
                traitCat={traitData.title}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraitCard;
