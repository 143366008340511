import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import toastr from 'react-hot-toast';
import L_SingleLine from '../../LoadingEffectComponent/L_SingleLine';
import { Spinner } from 'react-bootstrap';
import { useState } from 'react';

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

const fetchPDFReport = async ({ userType, token, currentPractice, currentType, currentReportId, currentPatientId, currentPracticeID }) => {
    const response = await fetch(
        `${protocol}//${currentPractice}${PG3_BASE_URL}/${currentType}/pg4/reports/${currentReportId}/${currentPatientId}/pdf_view.pdf`,
        {
            headers: {
                "Content-Type": "application/pdf",
                Authorization: token,
                "Current-Type": userType,
                "Current-Practice": currentPracticeID,
            }
        }
    );

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.blob();
};


const PDFReportOptions = ({
    userType,
    token,
    currentPracticeID,
    currentPatientId,
    currentReportId,
    currentPractice,
    t
}) => {
    let currentType = userType;
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);
    const [isLoadingPrint, setIsLoadingPrint] = useState(false);
    const { mutate: getPDF, isLoading, error } = useMutation(
        ({ userType, token, currentPractice, currentType, currentReportId, currentPatientId, currentPracticeID }) =>
            fetchPDFReport({ userType, token, currentPractice, currentType, currentReportId, currentPatientId, currentPracticeID })
    );


    const handlePDFDownload = (isDownload) => {
        if (isDownload) setIsLoadingDownload(true)
        else setIsLoadingPrint(true)
        getPDF(
            { userType, token, currentPractice, currentType, currentReportId, currentPatientId, currentPracticeID },
            {
                onSuccess: (pdfBlob) => {
                    const url = window.URL.createObjectURL(new Blob([pdfBlob], { type: 'application/pdf' }));
                    if (isDownload) {
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'report.pdf');
                        document.body.appendChild(link);
                        link.click();
                    } else {
                        const printWindow = window.open(url);
                        if (printWindow) {
                            printWindow.onload = function () {
                                printWindow.print();
                            };
                        }
                    }
                },
                onError: () => {
                    toastr.error('Failed to load PDF');
                },
                onSettled: () => {
                    setIsLoadingDownload(false)
                    setIsLoadingPrint(false)
                }
            }
        );
    };
    return (
        <div>
            <button
                className="pg4-btn pg4-btn_outline me-lg-2 px-4 w-sm-100"
                onClick={() => handlePDFDownload(true)}
                aria-label="Save as PDF"
                role="button"
                disabled={isLoading}
            >
                {(isLoadingDownload) ? <Spinner as="span" animation="border" size="sm" /> : <i className="fas fa-download"></i>} Save as PDF
            </button>
            <button
                className="pg4-btn pg4-btn_outline px-4 w-sm-100 my-2 my-lg-0"
                onClick={() => handlePDFDownload(false)}
                aria-label="Print Report"
                role="button"
                disabled={isLoading}
            >
                {(isLoadingPrint) ? <Spinner as="span" animation="border" size="sm" /> : <i className="fas fa-print"></i>} Print Report
            </button>
        </div>
    );
};

export default PDFReportOptions;
