import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SupportAndResources() {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="pg-card p-4 px-5"
      style={{
        backgroundColor: "#f6f6f6 !important",
        background: "#f6f6f6 !important",
      }}
    >
      <div className="pg-heading text-center" dangerouslySetInnerHTML={{ __html: t('pracd_sar') }} />
      <div className=" justify-content-between">
        <div className="my-4">
          <div dangerouslySetInnerHTML={{ __html: t('pracd_sar_s_1') }} />
          <div className="text-center px-5">
            <a
              className="btn btn-outline px-5 my-3 w-75 lh-lg"
              href="https://www.youtube.com/playlist?list=PLfpPUB-xUWePWGSPdaZHTxam9qeg9REKD"
              target="_blank"
              rel="noreferrer"
            >
              {t('pracd_btn_5')}
            </a>
          </div>
        </div>
        <div className="my-4">
          <div dangerouslySetInnerHTML={{ __html: t('pracd_sar_s_2') }} />
          <div className="text-center px-5">
            <NavLink
              className="btn btn-outline px-5 my-3 w-75 lh-lg"
              to={{ pathname: "/hcp/educational-page", api: true }}
            >
              {t('pracd_btn_6')}

            </NavLink>
          </div>
        </div>

        <div className="">
          <div className="my-4">
            <div dangerouslySetInnerHTML={{ __html: t('pracd_sar_s_3') }} />
            <div className="text-center px-5">
              <Link
                className="btn btn-outline px-5 my-3 w-75 lh-lg"
                style={{ textTransform: "unset" }}
                to={ i18n.language === "ca" ? "/hcp-faqs" : "/hcp/faqs" }
              >
                {t('pracd_btn_7')}
              </Link>
            </div>
          </div>
        </div>

        <div className="">
          <div className="my-4">
          <div dangerouslySetInnerHTML={{ __html: t('pracd_sar_s_4') }} />
          </div>
        </div>
      </div>
    </div>
  );
}
