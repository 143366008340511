import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast';
import 'reactjs-popup/dist/index.css';

import PgInfoTooltip from '../../PgInfoTooltip'
import { toggleLoading } from '../../../store/actions/ui'
import { updateLabUpload } from '../../../store/actions/labUpload'
import { updateSnpUpload } from '../../../store/actions/snpUpload'
import {
  resendAllPendingInvites,
  sendInvites,
  sendReminderInvites,
  generateReport,
  updateReportWITHPRS,
  passwordSetupReminder
} from '../../../store/actions/patientList'
import AnswersModal from './AnswersModal'
import EditPatientModal from './EditPatientModal'
import LabReviewModal from '../Lab/LabReviewModal'
import EditPatientUploadModal from './EditPatientUploadModal'
import GeneticUploadErrorModal from '../../Report/GeneticUploadErrorModal'
import QuestionnaireAlertModal from './QuestionnaireAlertModal'

import { useTranslation } from "react-i18next";
// import { float } from 'html2canvas/dist/types/css/property-descriptors/float';

function PatientListItem({
  patient,
  updatePatientReport,
  expandPatientListItem,
  updatePatientList
}) {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redux state variable
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const geneticFileStatus = useSelector(state => state.snpUpload.fileStatus);
  const labFileStatus = useSelector(state => state.labUpload.fileStatus);
  const userPermissions = useSelector(state => state.auth.userPermissions);

  // redux actions
  const __toggleLoading = (data) => dispatch(toggleLoading(data));
  const updateGeneticFileStatus = (data) => dispatch(updateSnpUpload(data));
  const updateLabFileStatus = (data) => dispatch(updateLabUpload(data));
  const resendAllPendingInvitesAction = (id) => dispatch(resendAllPendingInvites(currentPracticeID, currentPractice, token, id));
  const passwordSetupReminderAction = (id) => dispatch(passwordSetupReminder(currentPracticeID, currentPractice, token, id));
  const sendInvitesAction = (id) => dispatch(sendInvites(currentPracticeID, currentPractice, token, id));
  const sendReminderInvitesAction = (id) => dispatch(sendReminderInvites(currentPracticeID, currentPractice, token, id));
  const generateReportAction = (id) => dispatch(generateReport(currentPracticeID, currentPractice, token, id));
  const updateReportWITHPRSAction = (pld) => dispatch(updateReportWITHPRS({ currentPracticeID, currentPractice, token, pld }))


  // local state variables
  const [expandListItem, setExpandListItem] = useState(expandPatientListItem)
  const [showAnswersModal, setShowAnswersModal] = useState(false)
  const [showEditPatientModal, setShowEditPatientModal] = useState(false)
  const [showEditPatientUploadModal, setShowEditPatientUploadModal] = useState(false)
  const [showLabReviewModal, setShowLabReviewModal] = useState(false)
  const [showGeneticUploadErrorModal, setShowGeneticUploadErrorModal] = useState(false)
  const [showQuestionnaireAlertModal, setShowQuestionnaireAlertModal] = useState(false)
  const [uploadGeneticFile, setUploadGeneticFile] = useState(false);
  const [uploadLabFile, setUploadLabFiles] = useState(false);

  useEffect(() => {
    if (userPermissions?.some(item => item.hasOwnProperty('UploadingPatientGeneticFiles'))) {
      setUploadGeneticFile(true)
    }
    if (userPermissions?.some(item => item.hasOwnProperty('UploadingPatientLabFiles'))) {
      setUploadLabFiles(true)
    }
  }, []);

  const goToLabUploadPage = (
    patientId,
    patientName,
    reportId,
    anotherFile,
    status,
    dId,
  ) => {
    if (uploadLabFile) {
      const patientIndex = findPatientIndex(labFileStatus, patientId)
      if (anotherFile) {
        if (patientIndex >= 0) {
          let c_fileStatus = [...labFileStatus]
          c_fileStatus = c_fileStatus.filter(
            (file) => file.patientId !== patientId,
          )
          updateLabFileStatus({ fileStatus: c_fileStatus })
        }
      } else {
        if (status !== '') {
          let _labFileStatus = [...labFileStatus]
          if (patientIndex >= 0) {
            _labFileStatus[patientIndex].uploadStarted = true
            _labFileStatus[patientIndex].isFileSelected = true
            _labFileStatus[patientIndex].error = false
            _labFileStatus[patientIndex].status = status
            _labFileStatus[patientIndex].documentId = dId
            _labFileStatus[patientIndex].patientId = patientId
          } else {
            _labFileStatus.push({
              uploadStarted: true,
              isFileSelected: true,
              status: status,
              documentId: dId,
              error: false,
              patientId,
            })
          }
          updateLabFileStatus({
            fileStatus: _labFileStatus,
          })
        }
      }
      updatePatientReport({
        currentPatientId: patientId,
        // currentReportId: reportId,
      })
      navigate('/practice/patient/lab', { state: { patientName } })
    }
  }


  const findPatientIndex = (filestatus, patientId) => {
    return _.findIndex(filestatus, { patientId: patientId })
  }

  const goToGeneticUploadPage = (
    patientId,
    patientName,
    reportId,
    anotherFile,
    status,
    dId,
  ) => {
    if (uploadGeneticFile) {
      const patientIndex = findPatientIndex(geneticFileStatus, patientId)
      if (anotherFile) {
        if (patientIndex >= 0) {
          let c_fileStatus = [...geneticFileStatus]
          c_fileStatus = c_fileStatus.filter(
            (file) => file.patientId !== patientId,
          )
          updateGeneticFileStatus({ fileStatus: c_fileStatus })
        }
      } else {
        if (status !== '') {
          let c_fileStatus = [...geneticFileStatus]
          if (patientIndex >= 0) {
            c_fileStatus[patientIndex].isFileSelected = true
            c_fileStatus[patientIndex].uploadStarted = true
            c_fileStatus[patientIndex].documentId = dId
            c_fileStatus[patientIndex].status = status
            c_fileStatus[patientIndex].patientId = patientId
            c_fileStatus[patientIndex].error = false
            c_fileStatus[patientIndex].uploadError = false
          } else {
            c_fileStatus.push({
              isFileSelected: true,
              uploadStarted: true,
              documentId: dId,
              status: status,
              error: false,
              uploadError: false,
              patientId,
            })
          }
          updateGeneticFileStatus({ fileStatus: c_fileStatus })
        }
      }
      updatePatientReport({
        currentPatientId: patientId,
        // currentReportId: reportId,
      })
      navigate('/practice/patient/snp', {
        state: {
          patientName,
          isFromPL: !anotherFile,
        }
      })
    }

  }

  const goToReportPage = (patientId, reportId, reportType) => {
    if (reportType === 'pg3') {
      updatePatientReport({
        currentPatientId: patientId,
        currentReportId: reportId,
      })
      navigate('/practice/patient/report')
    } else {
      navigate(`/practice/report/new/${reportId}`)
    }
    
  }

  const sendReminder = (patientId) => {
    console.log("sendReminder ---- patinet id ---->", patientId)
    resendAllPendingInvitesAction(patientId).then((response) => {
      if (!response.error)
        toast.success('Mail sent successfully')
    })
  }

  const sendInvite = (patientId) => {
    sendInvitesAction(patientId).then((response) => {
      if (!response.error)
        toast.success('Invitation sent successfully')
    })
  }

  const passwordReminder = (patientId) => {
    passwordSetupReminderAction(patientId).then((response) => {
      if (!response.error)
        toast.success('Invitation sent successfully')
    })
  }

  const sendNewInvite = (patientId, geneticUploaded) => {
    if (geneticUploaded) {
      sendInvitesAction(patientId).then((response) => {
        if (!response.error)
          toast.success('Invitation sent successfully')
      })
    }
    else {
      setShowQuestionnaireAlertModal(true)
    }
  }

  const sendReminderInvite = (patientId) => {
    sendReminderInvitesAction(patientId).then((response) => {
      if (showEditPatientUploadModal) {
        setShowEditPatientUploadModal(false)
        updatePatientList()
      }
      if (response.error) toast.error('Unable to send invitation')
      else toast.success('Invitation sent successfully')
    })
  }

  const generatePG2UserReport = (pId) => {
    __toggleLoading(true);
    generateReportAction(pId).then(response => {
      __toggleLoading(false);
      if (!response.error) {
        console.log('do somethinf');
        const data = response.payload;
        goToGeneticUploadPage(
          pId,
          patient.full_name,
          data.report_id,
          false,
          "uploading",
          data.doc_id,
        )
      }
    })
  }

  const updateReportFn = (
    patient_id,
    report_id,
    full_name,
    anotherFile,
    current_status) => {
    const patientIndex = findPatientIndex(geneticFileStatus, patient_id)
    console.log({ report_id })
    updateReportWITHPRSAction({ report_id }).then(response => {
      if (response.error) {
        toast.error(response?.payload?.message)
        return;
      }
      let dId = response.payload.document_id;
      if (dId) {
        console.log(response);
        let c_fileStatus = [...geneticFileStatus]
        if (patientIndex >= 0) {
          let status = {
            isFileSelected: true,
            uploadStarted: true,
            documentId: dId,
            status: current_status,
            patientId: patient_id,
            error: false,
            uploadError: false,
          }
          c_fileStatus[patientIndex] = status;
        } else {
          c_fileStatus.push({
            isFileSelected: true,
            uploadStarted: true,
            documentId: dId,
            status: current_status,
            error: false,
            uploadError: false,
            patientId: patient_id,
          })
        }
        updateGeneticFileStatus({ fileStatus: c_fileStatus })
      }
      updatePatientReport({ currentPatientId: patient_id })
      navigate('/practice/patient/snp', {
        state: {
          full_name,
          isFromPL: !anotherFile,
        }
      })
    })
  }

  const style = {
    batchFnBtnActive: {
      // color: "red", cursor: 'pointer'
    },
    batchFnBtnDisabled: {
      color: "var(--pg_primary)", cursor: 'not-allowed', opacity: 0.6
    }
  }

  return (
    <div
      className="transition-all"
      style={{ border: '2px solid #888888',
      marginTop: '15px',
      borderRadius: '5px',
      marginBottom: '15px', }}
    >
      {showLabReviewModal &&
        <LabReviewModal
          showModal={showLabReviewModal}
          modalData={{
            documentIds: patient.lab_document_ids,
            documentId: patient.lab_session_id,
            patientId: patient.id,
            completed: true,
          }}
          onCancel={() => setShowLabReviewModal(false)}
        />
      }

      <div className="row my-2 py-1 px-3">
        {
          showGeneticUploadErrorModal &&
          <GeneticUploadErrorModal
            showModal={showGeneticUploadErrorModal}
            onSubmit={() => goToGeneticUploadPage(
              patient.id,
              patient.full_name,
              patient.genetic_data?.genetic_doc_report_id,
              true,
              patient?.analyze_name,
              patient?.genetic_doc_id,
            )}
            onCancel={() => setShowGeneticUploadErrorModal(false)}
            context="practitioner"
            uploadSnp={patient.upload_snp}
          />
        }
        {
          showQuestionnaireAlertModal &&
          <QuestionnaireAlertModal
            showModal={showQuestionnaireAlertModal}
            onCancel={() => setShowQuestionnaireAlertModal(false)}
            snpUpload={patient.upload_snp}
          />
        }
        {
          showAnswersModal &&
          <AnswersModal
            patientId={patient.id}
            showModal={showAnswersModal}
            onCancel={() => setShowAnswersModal(false)}
            isTestPatient={patient.patient_type === 'test'}
          />
        }
        {
          showEditPatientModal &&
          <EditPatientModal
            showModal={showEditPatientModal}
            onCancel={() => setShowEditPatientModal(false)}
            patientId={patient.id}
            currentPractice={currentPractice}
            currentPracticeID={currentPracticeID}
            updatePatientList={updatePatientList}
          />
        }
        {
          showEditPatientUploadModal &&
          <EditPatientUploadModal
            showModal={showEditPatientUploadModal}
            onCancel={() => setShowEditPatientUploadModal(false)}
            onSubmit={() => sendReminderInvite(patient.id)}
          />
        }
        <div className="col-3 col-md-3 col-lg-3 my-auto">
          {patient.full_name}{' '}
        </div>
        <div className="col-2 my-auto">
          <span className="text-secondary my-auto">
            {patient.display_status}
            {(patient.display_status === 'Pending' ||
              patient.current_status === 'No Data') && (
                <PgInfoTooltip >
                  {patient.display_status === 'Pending'
                    ? t('pracpl_line_8')
                    : <div style={{ minWidth: 300, minHeight: 150 }} dangerouslySetInnerHTML={{ __html: t('pracpl_line_9') }} />}
                </PgInfoTooltip>
              )}
          </span>{' '}
        </div>
        <div className="col-2 col-md-2 col-lg-2 my-auto">
          <div className="d-flex justify-content-between">
            <div className="my-auto">
              {patient?.report_generated_at
                ? moment(patient?.report_generated_at).format('ll')
                : t('pracpl_line_5')}
            </div>
          </div>
        </div>
        <div className="col-2 my-auto">
          <span className="text-secondary mx-1">
            {patient?.report_status
            }
          </span>
        </div>
        <div className="col-3 text-end ">
          {
            <div className='d-flex justify-content-end'>
              {patient?.report_code == 140 && (
                <div className='text-end'>
                  <button
                    className="btn btn-primary text-light my-0 py-0 me-lg-5 me-md-2 me-1"
                    onClick={() => goToReportPage(patient.id, patient?.report_id, patient?.report_type)}
                    style={{ width: 220 }}
                  >
                    {t('pracpl_btn_2')}
                  </button>
                </div>)}
              <i
                className="fa fa-chevron-right transition-all my-auto cursor-pointer"
                aria-hidden="true"
                style={{
                  transform: expandListItem ? 'rotate(90deg)' : 'rotate(0deg)',
                }}
                onClick={() => setExpandListItem(!expandListItem)}
              ></i>
            </div>
          }

        </div>
      </div>
      {
        expandListItem && (
          <div className="row justify-content-end">
            <div className="col pe-5 mt-auto pb-3" >
              <div
                className="pg-link "
                style={{ marginLeft: "30px", height: "0px" }}
                onClick={() => setShowEditPatientModal(true)}
              >
                <i
                  className="fas fa-pencil-alt me-2"
                  style={{ marginRight: "8px" }}
                  aria-hidden="true"
                ></i>
                {t('pracpl_btn_3')}
              </div>

              {patient.display_status === 'Pending' ? <div
                className="pg-link mt-auto h-100" style={{ float: "right"}}
                onClick={() => sendReminder(patient.id)}
              >
                <i className="fa fa-bell ms-1" aria-hidden="true"
                style={{ marginRight: "8px"}}></i>
                {t('Invitation Reminder')}
                
              </div> :<div
                className="pg-link mt-auto h-100" style={{ float: "right"}}
                onClick={() => passwordReminder(patient.id)}
              >
                <i className="fa fa-bell ms-1" aria-hidden="true"
                style={{ marginRight: "8px"}}></i>
                {t('Patient Action Reminder')}
                
              </div>}
            </div>
            <div className="col-12 col-md-12 col-lg-7">
              {/* <hr className="my-2 mx-1" /> */}
              { patient?.report_id && (
                  <div className="d-flex justify-content-between pl-pr_margin"
                  >
                    {/* <div>
                      {t('pracpl_line_7')}
                    </div> */}
                    <div
                      className="me-lg-5 pg-link text-end"
                      onClick={() =>
                        navigate(
                          '/practice/patient/past_report?patient_id=' + patient.id,
                        )
                      }
                    >
                      <i
                        className="far fa-clock me-2"
                        aria-hidden="true"
                      ></i>
                      {t('pracpl_btn_5')}
                      
                    </div>
                  </div>
                )}
              <div></div>
              <div className="row px-1 my-3">
                <div className="col-12 col-md-4 col-lg-4  my-3 my-md-0"
                  style={{ backgroundColor: 'rgba(238, 234, 234)',
                  borderRadius: '5px', width: '32%', position: 'relative', right: '10px'}}
                >
                  <div className="pg-text__bold">{t('genetics')}</div>
                  {patient?.analyze_name ? (
                    <>
                      <div>
                      {patient?.analyze_name === 'Complete' ? (
                          patient?.genetic_vendor ? <div dangerouslySetInnerHTML={{ __html: patient?.genetic_vendor }} /> : "Processing"
                        ) : (
                          <div
                            className="pg-link"
                            style={(uploadGeneticFile) ? style.batchFnBtnActive : style.batchFnBtnDisabled}
                            onClick={
                              patient?.analyze_name != "Error" ?
                                () =>
                                  goToGeneticUploadPage(
                                    patient.id,
                                    patient.full_name,
                                    patient.genetic_data?.genetic_doc_report_id,
                                    false,
                                    patient?.analyze_name,
                                    patient?.genetic_doc_id,
                                  )
                                :
                                () =>
                                  setShowGeneticUploadErrorModal(
                                    true
                                  )
                            }
                          >
                            {patient?.analyze_name}
                          </div>
                        )}
                      </div>
                      {((patient?.analyze_name === 'Complete' &&
                        patient.patient_type !== 'test')) && (
                          <div
                            className="pg-link"
                            style={(uploadGeneticFile) ? style.batchFnBtnActive : style.batchFnBtnDisabled}
                            onClick={() =>
                              goToGeneticUploadPage(
                                patient.id,
                                patient.full_name,
                                patient.genetic_data?.genetic_doc_report_id,
                                true,
                              )
                            }
                          >
                            {patient.genetic_data?.genetic_doc_report_id? t('pracpl_btn_6'): t('pracpl_btn_7')}
                            {}
                            {' '}
                            <i
                              className="fa fa-chevron-right ms-1"
                              aria-hidden="true"
                            ></i>
                          </div>
                        )}
                      {patient?.analyze_name === "Error" && <div
                        className="pg-link"
                        onClick={() =>
                          sendReminderInvite(
                            patient.id,
                          )
                        }
                      >
                        {t('pracpl_btn_4')}{' '}
                        <i
                          className="fa fa-chevron-right"
                          aria-hidden="true"
                        ></i>{' '}
                      </div>}
                    </>
                  ) : (
                    <div>
                      {t('pracpl_line_5')}  {/*  No data */}
                      {patient.patient_type !== 'test' && (
                        <div>
                          {patient.upload_snp &&
                            patient.current_status === 'Pending' ? (
                            <div></div>
                          ) : (
                            <div>
                              <div
                                className="pg-link"
                                style={(uploadGeneticFile) ? style.batchFnBtnActive : style.batchFnBtnDisabled}
                                onClick={() =>
                                  goToGeneticUploadPage(
                                    patient.id,
                                    patient.full_name,
                                    patient.genetic_data?.genetic_doc_report_id,
                                    true,
                                  )
                                }
                              >
                                {t('pracpl_btn_7')}{' '}
                                <i
                                  className="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>{' '}
                              </div>
                              {patient.upload_snp &&
                                <div
                                  className="pg-link"
                                  onClick={() =>
                                    sendReminderInvite(
                                      patient.id,
                                    )
                                  }
                                >
                                  {t('pracpl_btn_4')}{' '}
                                  <i
                                    className="fa fa-chevron-right"
                                    aria-hidden="true"
                                  ></i>{' '}
                                </div>
                              }
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-12 col-md-4 col-lg-4  my-3 my-md-0"
                  style={{ backgroundColor: 'rgba(238, 234, 234)',
                  borderRadius: '5px', width: '32%',}}
                >
                  <div className="pg-text__bold">Questionnaire</div>
                  {patient?.questionnaire_status?.toLowerCase() === "completed" ? (
                    <>
                      <div className="pg-text">{
                         t( _.startCase(patient?.questionnaire_status))
                      }</div>
                      {/* <div
                        className="pg-link"
                        onClick={() => setShowAnswersModal(true)}
                      >
                        {t('pracpl_btn_9')}{" "}
                        <i
                          className="fa fa-chevron-right ms-1"
                          aria-hidden="true"
                        ></i>
                      </div> */}
                      <div
                        className="pg-link"
                        onClick={() => sendInvite(patient.id)}
                      >
                        {t('Send Reminder')}{" "}
                        <i
                          className="fa fa-chevron-right ms-1"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </>
                  ) : (
                    <>
                      {patient.patient_type !== 'test' ? (
                        <div className="pg-text">{
                          patient.questionnaire_status ?
                            _.startCase(patient?.questionnaire_status) : patient.question_status ?
                            _.startCase(patient?.question_status):t('pracpl_line_5')
                        }</div>
                      ) :
                        <div
                          className="pg-link"
                          onClick={() => setShowAnswersModal(true)}
                        >
                          {t('pracpl_btn_9')}{" "}
                          <i
                            className="fa fa-chevron-right ms-1"
                            aria-hidden="true"
                          ></i>
                        </div>
                      }
                      {patient.upload_snp &&
                        patient.current_status === 'Pending' ? (
                        <div></div>
                      ) : (
                        <div>
                          {(patient.patient_type === 'real') && (
                            <div
                              className="pg-link"
                              onClick={() => sendInvite(patient.id)}
                            >
                              {patient.invite_questionnaire ? t('pracpl_btn_4') : t('Send Reminder')}
                              {/*  send reminder or send invite */}
                              {" "}
                              <i
                                className="fa fa-chevron-right ms-1"
                                aria-hidden="true"
                              ></i>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="col-12 col-md-4 col-lg-4 my-3 my-md-0"
                  style={{ backgroundColor: 'rgba(238, 234, 234)',
                  borderRadius: '5px', width: '32%', position: 'relative', left: '10px'}}
                >
                  <div className="pg-text__bold">{t('lab_results')}</div>
                  {patient.lab_analyze_name ? (
                    <>
                      <div
                        className="cursor-pointer"
                        style={(uploadLabFile) ? style.batchFnBtnActive : style.batchFnBtnDisabled}
                        onClick={() => {
                          if (patient.lab_analyze_name === 'Completed')
                            setShowLabReviewModal(true)
                          // navigate('/practice/patient/lab/review', {state:{
                          //   documentIds: patient.lab_document_ids,
                          //   documentId: patient.lab_session_id,
                          //   completed: true,
                          // }})
                          else
                            goToLabUploadPage(
                              patient.id,
                              patient.full_name,
                              patient.report_id,
                              false,
                              patient.lab_analyze_name,
                              patient.lab_session_id,
                            )
                        }}
                      >
                        {patient.lab_analyze_name === "Completed"
                          ? <div className="pg-link">
                            {t('pracpl_btn_12')}{" "}
                            <i
                              className="fa fa-chevron-right ms-1"
                              aria-hidden="true"
                            ></i>
                          </div>
                          : patient.lab_analyze_name
                        }
                      </div>
                      {patient.lab_analyze_name === 'Yet to be reviewed' ? (
                        <div
                          className="pg-link"
                          onClick={() => {
                            navigate('/practice/patient/lab/review', {
                              state: {
                                documentIds: patient.lab_document_ids,
                                documentId: patient.lab_session_id,
                                patientId: patient.id,
                                completed: false,
                              }
                            })
                          }}
                        >
                          {t('pracpl_btn_13')}{" "}
                          <i
                            className="fa fa-chevron-right ms-1"
                            aria-hidden="true"
                          ></i>
                        </div>
                      ) : (
                        <div>
                          {patient.lab_analyze_name === 'Completed' &&
                            patient.patient_type !== 'test' && (
                              <div
                                className="pg-link"
                                style={(uploadLabFile) ? style.batchFnBtnActive : style.batchFnBtnDisabled}
                                onClick={() =>
                                  goToLabUploadPage(
                                    patient.id,
                                    patient.full_name,
                                    patient.report_id,
                                    true,
                                  )
                                }
                              >
                                {t('pracpl_btn_6')}{" "}
                                <i
                                  className="fa fa-chevron-right ms-1"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            )}
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="pg-text">
                        {t('pracpl_line_5')}
                      </div>
                      {(!patient.upload_snp ||
                        patient.current_status === 'Active') && patient.patient_type !== 'test' && (
                          <div
                            className="pg-link"
                            style={(uploadLabFile) ? style.batchFnBtnActive : style.batchFnBtnDisabled}
                            onClick={() =>
                              goToLabUploadPage(
                                patient.id,
                                patient.full_name,
                                patient.report_id,
                                true,
                              )
                            }
                          >
                            {t('pracpl_btn_7')}{' '}
                            <i
                              className="fa fa-chevron-right ms-1"
                              aria-hidden="true"
                            ></i>
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      }
    </div >
  )
}

export default PatientListItem;