import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

function PgTypeAhead({
  options = [],
  objectKey,
  textBoxName,
  label,
  placeholder,
  index,
  id,
  required,
  showLabel,
  optional,
  isDisable = false,
  onSelect,
  selectedItem,
  error,
  errorMessage,
}) {
  const [inputText, setInputText] = useState(
    _.isObject(selectedItem) ? selectedItem[objectKey] : selectedItem
  );
  const [optionState, setOptionState] = useState(options);
  const [focused, setFocused] = React.useState(false);
  const [hoverIndex, setHoverIndex] = useState(-1);

  const [showSuggestion, setShowSuggestion] = useState(false);

  const onFocus = () => {
    setHoverIndex(-1);
    setOptionState(options);
    return setFocused(true);
  };
  const onBlur = () => {
    setTimeout(() => {
      setFocused(false);
      setShowSuggestion(false);
      return;
    }, 500);
  };

  let liRefs = [];

  const onInput = (e) => {
    if (focused == false || showSuggestion == false) {
      setFocused(true);
      setShowSuggestion(true);
      setHoverIndex(-1);
    }

    const value = e.target.value;
    setInputText(value);
    onSelect(index, textBoxName, value);
    const regex = new RegExp(`${add_escape_character(value.trim())}`, `i`);
    setOptionState((os) => {
      options = options
        .sort()
        .filter((option) =>
          regexMatcher(option, regex) ? option["name"] : ""
        );
      let liRefs = [];
      return options;
    });
  };

  const regexMatcher = (option, regex) => {
    let filtered_value = option["alternative_names"]
      .sort()
      .filter((test_name) => regex.test(test_name));
    return (
      filtered_value != undefined &&
      filtered_value != null &&
      filtered_value != ""
    );
  };

  const add_escape_character = (value) => {
    let updated_value = value.replace(/\{/g, "\\{");
    updated_value = updated_value.replace(/\}/g, "\\}");
    updated_value = updated_value.replace(/\(/g, "\\(");
    updated_value = updated_value.replace(/\)/g, "\\)");
    updated_value = updated_value.replace(/\./g, "\\.");
    updated_value = updated_value.replace(/\//g, "\\/");
    updated_value = updated_value.replace(/\\/g, "\\\\");
    updated_value = updated_value.replace(/\*/g, "\\*");
    updated_value = updated_value.replace(/\[/g, "\\[");
    updated_value = updated_value.replace(/\]/g, "\\]");
    return updated_value;
  };

  const __onSelect = (e) => {
    setInputText(_.isObject(e) ? e[objectKey] : e);
    onSelect(index, textBoxName, _.isObject(e) ? e[objectKey] : e);
    setFocused(false);
    setShowSuggestion(false);
  };

  const onDelete = () => {
    setFocused(true);
    setShowSuggestion(true);
    setInputText("");
    onSelect(index, textBoxName, "");
    setOptionState(options);
  };

  const onKeyDown = (e) => {
    if (e.keyCode == 8 || e.keyCode == 46) {
      // on delete and backspace press
      onDelete();
    }

    if (liRefs.length > 0) {
      if (e.keyCode == 40) {
        // up arrow press
        if (hoverIndex < liRefs.length - 1) {
          setHoverIndex((currentHoverIndex) => {
            let tempHoverIndex = currentHoverIndex + 1;
            liRefs[tempHoverIndex].classList.add("pg-ss-hover");
            liRefs[tempHoverIndex].scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
            if (tempHoverIndex > 0) {
              liRefs[currentHoverIndex].classList.remove("pg-ss-hover");
            }
            return tempHoverIndex;
          });
        }
      }
      if (e.keyCode == 38) {
        // down arrow press
        if (hoverIndex > 0) {
          setHoverIndex((currentHoverIndex) => {
            let tempHoverIndex = currentHoverIndex - 1;
            liRefs[tempHoverIndex].classList.add("pg-ss-hover");
            liRefs[tempHoverIndex].scrollIntoView({
              behavior: "smooth",
              block: "nearest",
            });
            if (currentHoverIndex <= liRefs.length - 1) {
              liRefs[currentHoverIndex].classList.remove("pg-ss-hover");
            }
            return tempHoverIndex;
          });
        }
      }
      if (e.keyCode == 13) {
        // on enter press
        if (hoverIndex >= 0 && hoverIndex <= liRefs.length - 1) {
          __onSelect(liRefs[hoverIndex].innerText);
        }
      }
    }
  };

  const showTypeAhead = () => focused || showSuggestion;

  const style = {
    input: {
      padding: 15,
      width: "100%",
      outline: "none",
      border: error ? "1.2px solid #f00" : "1px solid #888888",
      borderRadius: "8px",
      height: 50,
      transition: "all .3s ease-in-out",
    },
    close: {
      position: "absolute",
      right: 10,
      top: 15,
      // fontSize:12,
    },
  };

  return (
    <div className="pg-ss" id={`${index}-pg-ss`}>
      {showLabel && (
        <div>
          <div className="d-inline-block pg-text__bold textc-primary">
            {label}
          </div>
          {required && (
            <span className="textc-primary fst-italic"> (Required)</span>
          )}
          {optional && !isDisable && (
            <span className="textc-primary fst-italic"> (Optional)</span>
          )}
        </div>
      )}
      <div className="pg-ss__container" id={`${index}-pg-ss__container`}>
        <input
          style={style.input}
          id={`${index}-pg-ss__input`}
          disabled={isDisable}
          autoComplete={"alaban"}
          placeholder={placeholder}
          type="search"
          value={
            _.isObject(selectedItem) ? selectedItem[objectKey] : selectedItem
          }
          className="pg-ss__input"
          type="text"
          onInput={onInput}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
        />
        {showTypeAhead() && (
          <div
            className="cp fas fa-times fs-5"
            style={style.close}
            onClick={onDelete}
          ></div>
        )}
        {showTypeAhead() && (
          <ul className="pg-ss__list" id={`${id}-pg-ss__list`}>
            {optionState.length > 0 ? (
              optionState.map((option, index) => (
                <Li
                  key={index}
                  className="pg-type_ahead_list_item"
                  id={`${index}-pg-ss__list_item`}
                  option={option}
                  objectKey={objectKey}
                  onClick={__onSelect}
                  ref={(el) => (liRefs[index] = el)}
                />
              ))
            ) : (
              <div className="text-center py-2">{inputText} not found</div>
            )}
          </ul>
        )}
      </div>
      {/* <div
                className="text-danger mx-2 my-1 transition-all"
                style={error ? { opacity: "1" } : { opacity: "0" }}
            >
                <i className="fas fa-info-circle"></i> {errorMessage}
            </div> */}
    </div>
  );
}

export default PgTypeAhead;

const Li = React.forwardRef((props, ref) => {
  const [hover, setHover] = useState(false);

  const onMouseOver = (e) => setHover(true);
  const onMouseOut = (e) => setHover(false);

  return (
    <li
      key={props.key}
      className={`${hover && "pg-ss-hover "} ${props.className}`}
      id={`${props.id}-pg-ss__list_item`}
      onClick={() => props.onClick(props.option)}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      ref={ref}
    >
      {" "}
      {_.isObject(props.option) ? (
        <div>{props.option[props.objectKey]}</div>
      ) : (
        props.option
      )}
    </li>
  );
});
