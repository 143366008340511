// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r-item__container {
  position: relative;
  height: 500px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  padding-right: 4px;
  /* For WebKit browsers (Chrome, Safari) */
}
.r-item__container::-webkit-scrollbar {
  width: 8px;
}
.r-item__container::-webkit-scrollbar-track {
  background: transparent;
}
.r-item__container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.scroll-shadow__patient,
.scroll-shadow {
  position: relative;
  left: 0;
  top: -45px;
  width: 100%;
  height: 30px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, 0.5) 40%, rgba(255, 255, 255, 0.6) 60%, rgb(255, 255, 255));
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.scroll-shadow__patient {
  top: -26px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/Report/PG4/PersonalizedRecommendations/personalizedRecommendation.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,gBAAA;EACA,UAAA;EACA,kBAAA;EAEA,yCAAA;AAAJ;AACI;EACI,UAAA;AACR;AAEI;EACI,uBAAA;AAAR;AAGI;EACI,sBAAA;EACA,mBAAA;AADR;;AAQA;;EAEI,kBAAA;EACA,OAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,4IAAA;EACA,oBAAA;EACA,6BAAA;AALJ;;AAQA;EACI,UAAA;EACA,YAAA;AALJ","sourcesContent":[".r-item__container {\n    position: relative;\n    height: 500px;\n    overflow-y: auto;\n    list-style: none;\n    padding: 0;\n    padding-right: 4px;\n\n    /* For WebKit browsers (Chrome, Safari) */\n    &::-webkit-scrollbar {\n        width: 8px;\n    }\n\n    &::-webkit-scrollbar-track {\n        background: transparent;\n    }\n\n    &::-webkit-scrollbar-thumb {\n        background-color: #888;\n        border-radius: 10px;\n        // border: 2px solid #e0e0e0;\n    }\n\n}\n\n\n.scroll-shadow__patient,\n.scroll-shadow {\n    position: relative;\n    left: 0;\n    top: -45px;\n    width: 100%;\n    height: 30px;\n    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 10%, rgba(255, 255, 255, .5) 40%, rgba(255, 255, 255, .6) 60%, rgba(255, 255, 255, 1));\n    pointer-events: none;\n    transition: opacity 0.3s ease;\n}\n\n.scroll-shadow__patient {\n    top: -26px;\n    height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
