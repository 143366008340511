import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import _ from "lodash";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import qs from "query-string";
const REACT_APP_PG3_APP_DOMAIN = process.env.REACT_APP_PG3_APP_DOMAIN;
const UK_TLD = process.env.REACT_APP_UK_TLD;
const CA_TLD = process.env.REACT_APP_CA_TLD;
const cookies = new Cookies();
const obj = qs.parse(window.location.search, { decode: false });

if (!_.isEmpty(obj) && obj.lng) {
  cookies.set("i18next", obj.lng)
  console.log(cookies.get('i18next'), "-----inside loop")
}

if (window.location.hostname.endsWith(".co.uk"))
  cookies.set("i18next", "en_gb")

if (window.location.hostname.endsWith(".ca"))
  cookies.set("i18next", "ca")

if (cookies.get('i18next') == 'undefined') {
  cookies.set("i18next", "en")
  console.warn('setting i18 to en');
  var i18nextValue = "en"
} else if (cookies.get('i18next') == undefined) {
  cookies.set("i18next", "en")
  console.warn('setting i18 to en');
  var i18nextValue = "en"
}
else {
  var i18nextValue = cookies.get('i18next')
}
// let i18nextValue = cookies.get('i18next') == 'undefined' || undefined ? "en" : cookies.get('i18next')

// const lang = i18nextValue;
// const lang = localStorage.getItem('i18nextLng');
// const lang = i18nextValue == 'undefined' ? "en" : localStorage.getItem('i18nextLng');

console.warn({ i18nextValue });

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: false, // use et if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    initImmediate: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false
    },
    nonExplicitSupportedLngs: true,
    debug: true,
    react: {
      useSuspense: true
    },
    backend: {
      loadPath: REACT_APP_PG3_APP_DOMAIN + '/language_text?lang=' + i18nextValue,
      crossDomain: true,
    }
  });

export default i18n;
// import i18n from "i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import { initReactI18next } from "react-i18next";

// i18n
//   .use(LanguageDetector)
//   .use(initReactI18next)
//   .init({
//     // we init with resources
//     resources: {
//       en: {
//         translations: {
//           "prac_register_header": "Register with PureGenomics<sup>®</sup>",
//         }
//       },
//       de: {
//         translations: {
//             "prac_register_header": "Register with PureGenomics<sup>TM</sup>",
//         }
//       }
//     },
//     fallbackLng: "en",
//     debug: true,

//     // have a common namespace used around the full app
//     ns: ["translations"],
//     defaultNS: "translations",

//     keySeparator: false, // we use content as keys

//     interpolation: {
//       escapeValue: false
//     }
//   });

// export default i18n;
