import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function HeightPicker({ question, onInput, errors }) {
    const answers = useSelector(state => state.pg4.q_answers);
    const [feet, setFeet] = useState(null);
    const [inches, setInches] = useState(null);

    const handleFeetChange = (event) => {
        setFeet(parseInt(event?.target?.value));
    };

    const handleInchesChange = (event) => {
        setInches(parseInt(event?.target?.value));
    };

    const calculateHeightInInches = () => {
        return feet * 12 + inches;
    };

    useEffect(() => {
        console.log({ feet, inches })
        if (feet && inches)
            onInput({
                [question.question_id]: {
                    question_id: question.question_id,
                    answer: [feet, inches]
                }
            })
    }, [feet, inches]);


    useEffect(() => {
        console.log(errors, 'errors');
    }, [errors]);

    return (
        <div className="pg4-card">
            <div className="pg-heading__sub textc-primary__p">
                {question.question_text}
            </div>
            <div>
                For example, 5' 7" is 67 inches.
            </div>
            <div className='d-flex mt-3 fw-bold'>
                <div className='d-flex flex-column'>
                    Feet
                    <select id="feetInput"
                        value={answers[question?.question_id]?.answer[0]}
                        onChange={handleFeetChange}
                        style={{ width: 50 }}
                        className='pg4-card p-2 border mt-0'
                    >
                        {Array.from({ length: 4 }, (_, index) => (
                            <option key={index} value={index + 4}>{index + 4}'</option>
                        ))}
                    </select>
                </div>
                <div className='d-flex flex-column ms-2'>
                    Inches
                    <select
                        id="inchesInput"
                        value={answers[question?.question_id]?.answer[1]}
                        onChange={handleInchesChange}
                        style={{ width: 70 }}
                        className='pg4-card p-2 border mt-0'
                    >
                        {Array.from({ length: 12 }, (_, index) => (
                            <option key={index} value={index}>{index}"</option>
                        ))}
                    </select>
                </div>
            </div>
            {/* {errors[question?.label] && <div className="text-danger my-2">
                <span>
                    <i className="fas fa-info-circle me-1"></i>
                    {errors[question?.label]}
                </span>
            </div>} */}

        </div>
    );
}

export default HeightPicker;
