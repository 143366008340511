import { useState } from "react";
import DateInput from "../../components/Patient/PG4/Account/DateInput";
import MSQSections from "../../components/Patient/PG4/MSQSections";
import RadioVariantSelection from "../../components/Patient/PG4/QuestionTypes/RadioVariantSelection";
import SingSelectDropdown from "../../components/Patient/PG4/QuestionTypes/SingleSelectDropdown";
import InputContainer from "../../components/Patient/PG4/Account/AccountInputsContainer";
import Account from "../Patient/Account";
import AccountDetails from "../../components/Patient/PG4/Account/AccountDetails";
import { useTranslation } from "react-i18next";

const AccountSection = () => {
    const [inputValues, setinputValues] = useState({});
    const [sectionData, setsectionData] = useState({ section_id: 1 });
    const { t, i18n } = useTranslation();

    return (<div className="container">
        <div className="pg4-text__title textc-primary__p mt-5">
            Welcome To ${i18n.language === "en_gb" ? 'PureGenomics' : 'PureInsight'}
        </div>
        <div className="msq-container my-4">
            <div className="row">
                <div className="col">
                    {/* checked={parseInt(section.id) < parseInt(questionsData?.section_id)}  */}
                    <MSQSections
                        sections={{
                            sections: [{
                                id: 1,
                                name: "Account Details"
                            }, {
                                id: 2,
                                name: "Profile Information"
                            }]
                        }}
                        isLoading={false}
                        onSelectSections={(e) => setsectionData({ section_id: e?.id })}
                        questionsData={{ section_id: sectionData.section_id }}
                    />

                </div>
                <div className="col-12 col-lg-8 col-xl-9">
                    <>
                        {
                            sectionData.section_id === 1
                                ? <AccountDetails />
                                : <InputContainer inputValues={inputValues} setinputValues={setinputValues} />
                        }
                    </>
                </div>
                <div className="col-12 col-lg-4 col-xl-3" />
                <div className="col-12 col-lg-8 col-xl-9 my-3">
                    <div className={`d-flex ${sectionData.section_id > 1 ? 'justify-content-between' : 'justify-content-end'}`}>
                        {/* {sectionData.section_id > 1 && <button
                            className="btn btn-primary__p px-5"
                        >Previous Section</button>} */}
                        <button className="btn btn-primary__p w-100"
                        >Save & Exit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default AccountSection;