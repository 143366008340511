import React, { Component, useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import toastr from "react-hot-toast";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import PgSelect from "../../components/PgSelect";
import TextInput from "../../components/TextInput";
import { validateEmail } from "../../methods";
import L_EditPractitioner from "../../components/LoadingEffectComponent/Admin/L_EditPractitioner";

import {
  getPractitionersList,
  getPatientDetails,
  editPatient,
  removeReport,
} from "../../store/actions/patientAccount";
import { getCountries } from "../../store/actions/addPatient";
import PgSelectSearch from "../../components/PgSelectSearch";

import SetNewPasswordModal from "../NewPasswordModal";

import { useTranslation } from "react-i18next";

import moment from "moment";
import { months, years } from "../../methods/constant";

import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

function Account() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentUserId = useSelector((state) => state.auth.currentUserId);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [reportStatus, setReportStatus] = useState("");

  const practitionersList = useSelector(
    (state) => state.adminPractitioner.practitionersList
  );

  const [gettingPatInfo, setgettingPatInfo] = useState(true);
  const [countryList, setCountryList] = useState([]);

  const [isValidForm, setIsValidForm] = useState(false);
  //form fields
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [skincolor, setSkinColor] = useState("");
  const [country, setCountry] = useState("US");
  const [practitionerName, setPractitionerName] = useState("");
  const [practitionerId, setPractitionerId] = useState("");

  //for validation
  const [fNameError, setfNameError] = useState(false);
  const [lNameError, setlNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [genderError, setGenderError] = useState(false);
  const [skinColorError, setskinColorError] = useState("");
  const [countryError, setCountryError] = useState(false);
  const [geneticError, setGeneticError] = useState(false);
  const [practitionerNameError, setPractitionerNameError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pactitionersNameList, setPactitionersNameList] = useState([]);
  const [selectedPractitioner, setSelectedPractitioner] = useState({});
  const [gettingPatientInfo, setgettingPatientInfo] = useState(true);

  const onDone = () => {
    setEmailError(false);
    setfNameError(false);
    setlNameError(false);
    setAgeError(false);
    setGenderError(false);
    setskinColorError(false);
    setPractitionerNameError(false);
    if (email.length === 0 || !validateEmail(email)) setEmailError(true);
    if (fName.length === 0) setfNameError(true);
    if (lName.length === 0) setlNameError(true);
    if (!_.isDate(age)) setAgeError('Select Valid Date!');
    if (gender.length === 0) setGenderError(true);
    if (skincolor?.length === 0) setskinColorError(t('Skin color is required'));
    if (
      email.length !== 0 &&
      validateEmail(email) &&
      fName.length !== 0 &&
      lName.length !== 0 &&
      _.isDate(age) &&
      gender.length !== 0 &&
      skincolor?.length !== 0
    ) {
      handleDone();
    } else
      window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    dispatch(
      getPractitionersList(token, currentPracticeID, currentPractice)
    ).then((response) => {
      if (!response.error) {
        const practitionerList = response.payload.practitioners;
        setPactitionersNameList(practitionerList?.map((a) => a.full_name));
        /******
         * getting patient detail after getting practitioner list
         * since patient detail has dependency on pract.. list it is fetched earlier
         *
         *********/
        dispatch(
          getPatientDetails(
            token,
            currentPracticeID,
            currentPractice,
            currentUserId
          )
        ).then((response) => {
          if (!response.error) {
            setgettingPatientInfo(false);
            const data = response.payload;
            setSelectedPractitioner({
              id: data.created_by,
              full_name: data.assigned_practitioner,
            });
            setEmail(data.email.toLowerCase());
            setfName(data.first_name);
            setlName(data.last_name);
            setAge(data.dob ? new Date(data.dob) : null);
            setGender(data.gender);
            setSkinColor(data.skin_color||"");
            setCountry(data.country);
            setReportStatus(data.report_status);
          }
        });
      }
    });
    dispatch(getCountries(token, currentPracticeID, currentPractice)).then(response => {
      if (!response.error) {
        var availableCountries = response.payload?.countries
        if (country !== "US" && country !== "CA" ) {
          setCountry("")
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          _.remove(availableCountries, function (n) {
            return n === "Canada";
          });
        } else if (country === "CA"){
          setCountry("")
          _.remove(availableCountries, function (n) {
            return n === "United States";
          });
          setCountry("Canada")
        }
        else {
          setCountry("United States")
        }
        setCountryList(availableCountries);
      }
    });
  }, [reportStatus]);

  const handleDone = async () => {
    let data = {
      patient: {
        first_name: _.startCase(fName),
        last_name: lName,
        full_name: `${_.startCase(fName)} ${lName}`,
        email: email,
        dob: moment(age).format(),
        gender: gender,
        skin_color: skincolor,
        country: country
      },
    };
    setLoading(true);
    const response = await dispatch(
      editPatient(
        token,
        currentPracticeID,
        currentPractice,
        currentUserId,
        data
      )
    );
    setLoading(false);
    if (response.error) {
      toastr.error(t('patient_err1'));
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
      toastr.success(t('patient_suc1'));
    }
  };

  const removePatientReport = () => {
    dispatch(
      removeReport(token, currentPracticeID, currentPractice, currentUserId)
    ).then((response) => {
      if (!response.error) {
        setReportStatus(response.payload.report_status);
        window.scrollTo({ top: 0, behavior: "smooth" });
        toastr.success(t('patient_suc2'));
      }
    });
  };

  return (
    <div className="container" id="edit-practitioner-modal">
      <Helmet>
        <title>{t('pat_account_h1')}</title>
      </Helmet>
      <div
        className="cursor-pointer textc-primary pg-link"
        onClick={() => navigate("/patient/dashboard")}
        style={{ float: "left", width: "100%" }}
      >
        <i className="fas fa-chevron-left mr-2"></i> {t('dashboard')}
      </div>
      <div className="text-center pg-title mt-5">{t('patient_account_txt1')}</div>
      <div className="text-center pg-text__bold textc-primary">
        {t('patient_account_txt2')}
      </div>
      {gettingPatientInfo ? (
        <L_EditPractitioner />
      ) : (
        <div className="row p-5 justify-content-md-center account-form">
          <div className="col-md-6">
            <TextInput
              label={t('prac_register_first_name')}
              showLabel={true}
              required={true}
              id="f-name__addpatient"
              placeholder={t('pracand_fname_ph')}
              value={fName}
              error={fNameError}
              type="text"
              errorMessage={t('pracand_fname_error')}
              inputHandler={(e) => {
                setfName(e.target.value);
              }}
            />

            <TextInput
              label={t('pracand_lname')}
              showLabel={true}
              required={true}
              id="l-name__addpatient"
              placeholder={t('pracand_lname_ph')}
              value={lName}
              error={lNameError}
              errorMessage={t('pracand_lname_error')}
              inputHandler={(e) => {
                setlName(e.target.value);
              }}
            />

            <TextInput
              label={t('pracand_email')}
              showLabel={true}
              required={true}
              emailField={true}
              id="email-input__addpatient"
              placeholder={t('pracand_email_ph')}
              value={email}
              error={emailError}
              errorMessage={t('prac_reg_email_req')}
              inputHandler={(e) => {
                if (
                  e.target.value.length < 0 &&
                  !validateEmail(e.target.value)
                ) {
                  setEmailError(true);
                } else {
                  setEmailError(false);
                  setEmail(e.target.value);
                }
              }}
            />

            <div className="mt-4 my-2 dateVal">
              <div>
                <div className="d-inline-block pg-text__bold textc-primary">Date Of Birth
                </div>
                <span className="textc-primary fst-italic"> ({t('required')})</span>
              </div>
              <ReactDatePicker
                renderCustomHeader={({
                  date,
                  changeYear,
                  changeMonth
                }) => (
                  <div
                    style={{
                      margin: 10,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <select
                      value={date?.getFullYear()}
                      onChange={({ target: { value } }) => changeYear(value)}
                    >
                      {years.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>

                    <select
                      value={months[date.getMonth()]}
                      onChange={({ target: { value } }) =>
                        changeMonth(months.indexOf(value))
                      }
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                onChange={(date) => {
                  setAge(date);
                }}
                style={{ width: "100%" }}
                value={age}
                dateFormat="MM/dd/yyyy"
                onChangeRaw={() => { setAge("") }}
                selected={age}
                className={(_.isEmpty(ageError)) ? "dateFld" : "dateFld error"}
                placeholderText="Enter DOB MM/DD/YYYY"
                minDate={new Date("1900-01-01")}
                maxDate={new Date()}
                popperPlacement="botom"
                popperModifiers={{
                  flip: {
                    behavior: ["bottom"]
                  },
                  preventOverflow: {
                    enabled: false
                  },
                  hide: {
                    enabled: false
                  }
                }}
              />
              <div className="text-danger mx-2 my-1 transition-all" style={!(_.isEmpty(ageError)) ? { opacity: 1 } : { opacity: 0, minHeight: '16px' }} >
                {!(_.isEmpty(ageError)) && <div dangerouslySetInnerHTML={{ __html: `<i class="fas fa-info-circle"></i>` + ageError }}></div>}
              </div>
            </div>

            <PgSelect
              label={t('pracand_gender')}
              showLabel={true}
              required={true}
              optional={false}
              id="gender__addpatient"
              setSelectedItem={setGender}
              selectedItem={gender}
              placeholder={t('pracand_gender_ph')}
              options={["Male", "Female", "Intersex"]}
              error={gender !== "" ? "" : genderError}
            />
            <PgSelect
              label={t('Patient Skin Color')}
              showLabel={true}
              skinInput={true}
              required={true}
              optional={false}
              id="skincolor__addpatient"
              setSelectedItem={setSkinColor}
              selectedItem={skincolor}
              placeholder={t('Patient Skin Color')}
              options={["Dark", "Medium", "Light"]}
              error={skincolor !== "" ? "" : skinColorError}
            />

            {
              ((country !== "United States") && (country !== "Canada"))&&
              <PgSelect
                label="Country"
                showLabel={true}
                required={true}
                optional={false}
                id="country__addpatient"
                setSelectedItem={setCountry}
                selectedItem={country}
                placeholder="Enter Patient Country"
                options={countryList}
                error={countryError}
              />
            }

            <TextInput
              label={t('practitioner')}
              showLabel={true}
              id="l-name__addpatient"
              isDisable={true}
              value={selectedPractitioner.full_name}
              inputHandler={(e) => {
                setlName(e.target.value);
              }}
            />
            <button
              className="btn btn-outline px-5 my-3 w-100"
              onClick={() => setShowNewPasswordModal(true)}
            >
              {t('patient_account_txt3')}
            </button>
            {showNewPasswordModal && (
              <SetNewPasswordModal
                showModal={showNewPasswordModal}
                onCancel={() => setShowNewPasswordModal(false)}
                currentPractice={currentPractice}
                currentPracticeID={currentPracticeID}
                currentUserId={currentUserId}
                userType="Patient"
              />
            )}
            <button
              className="btn btn-primary text-light px-5 my-3 w-100"
              onClick={onDone}
            >
              {t('patient_account_txt4')}
            </button>
            {/* { reportStatus === "uploaded" && <div
          className="cursor-pointer textc-primary pg-link px-1"
          onClick={removePatientReport}
          style={{ marginTop: "20px" }}
        >
          Remove Reports
        </div> } */}
          </div>
        </div>
      )}
    </div>
  );
}
export default Account;
