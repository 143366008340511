import { ReactComponent as Cognitive } from '../../../assets/report/cognitive.svg';
import { ReactComponent as Digestive } from '../../../assets/report/digestive.svg';
import { ReactComponent as Emotions } from '../../../assets/report/emotions.svg';
import { ReactComponent as Hormone } from '../../../assets/report/hormone.svg';
import { ReactComponent as Energy } from '../../../assets/report/energy.svg';
import { ReactComponent as Immune } from '../../../assets/report/immune.svg';
import { ReactComponent as Joint } from '../../../assets/report/joint.svg';
import { ReactComponent as Sleep } from '../../../assets/report/sleep.svg';
import { ReactComponent as WeightEating } from '../../../assets/report/weight-eating.svg';

import vitamins_mineral from '../../../assets/vitamins_mineral_&_omega-3s.png';
import vitamins_mineral_white from '../../../assets/vitamins_mineral_&_omega-3s_white.png';

import detoxification from '../../../assets/detoxification.png';
import detoxification_white from '../../../assets/detoxification_white.png';

import weight_management from '../../../assets/weight_management.png';
import weight_management_white from '../../../assets/weight_management_white.png';

import cardiovascular_health from '../../../assets/cardiovascular_health.png';
import cardiovascular_health_white from '../../../assets/cardiovascular_health_white.png';

import gastrointestinal_health from '../../../assets/gastrointestinal_health.png';
import gastrointestinal_health_white from '../../../assets/gastrointestinal_health_white.png';

import cognitive_health_memory from '../../../assets/cognitive_health_&_memory.png';
import cognitive_health_memory_white from '../../../assets/cognitive_health_&_memory_white.png';

import immune_health from '../../../assets/immune_health.png';
import immune_health_white from '../../../assets/immune_health_white.png';

import energy_fitness from '../../../assets/energy_&_fitness.png';
import energy_fitness_white from '../../../assets/energy_&_fitness_white.png';

import glucose_metabolism from '../../../assets/glucose_metabolism.png';
import glucose_metabolism_white from '../../../assets/glucose_metabolism_white.png';






export const QuestionTypes = {
    NUMBER: 'number',
    HEIGHT: 'height',
    WEIGHT: 'weight',
    HOUR: 'physical_activity_hour_dropdown',
    RADIO: "radio",
    RADIO_VARIANT: "radio-variant",
    TETX: "text",
    MULTI_TEXT: "text_3",
    MULTITEXT: "multi-text",
    MULTIRADIO: "multi-radio",
    CHECKBOX: "checkbox",
    MULTI_INTERVAL_DROPTDOWN: "multi_interval_dropdown",
    INTERVAL_DROPTDOWN: "interval_dropdown"
}

export const reportTableData = {
    data: [
        {
            date_generated: '2023-10-01',
            msq_score: 85,
            current_status: 'Completed',
            report_id: 1,
        },
        {
            date_generated: '2023-10-02',
            msq_score: 78,
            current_status: 'In Progress',
            report_id: 2,
        },
        {
            date_generated: '2023-10-03',
            msq_score: 92,
            current_status: 'Completed',
            report_id: 3,
        },
        {
            date_generated: '2023-10-01',
            msq_score: 85,
            current_status: 'Completed',
            report_id: 1,
        },
        {
            date_generated: '2023-10-02',
            msq_score: 78,
            current_status: 'In Progress',
            report_id: 2,
        },
        {
            date_generated: '2023-10-03',
            msq_score: 92,
            current_status: 'Completed',
            report_id: 3,
        },
        {
            date_generated: '2023-10-04',
            msq_score: 67,
            current_status: 'In Progress',
            report_id: 4,
        },
        {
            date_generated: '2023-10-05',
            msq_score: 88,
            current_status: 'Completed',
            report_id: 5,
        },
        {
            date_generated: '2023-10-06',
            msq_score: 75,
            current_status: 'Completed',
            report_id: 6,
        },
        {
            date_generated: '2023-10-07',
            msq_score: 79,
            current_status: 'In Progress',
            report_id: 7,
        },
        {
            date_generated: '2023-10-08',
            msq_score: 90,
            current_status: 'Completed',
            report_id: 8,
        },
        {
            date_generated: '2023-10-09',
            msq_score: 82,
            current_status: 'Completed',
            report_id: 9,
        },
        {
            date_generated: '2023-10-10',
            msq_score: 76,
            current_status: 'In Progress',
            report_id: 10,
        },
        {
            date_generated: '2023-10-11',
            msq_score: 87,
            current_status: 'Completed',
            report_id: 11,
        },
        {
            date_generated: '2023-10-12',
            msq_score: 94,
            current_status: 'Completed',
            report_id: 12,
        },
        {
            date_generated: '2023-10-13',
            msq_score: 69,
            current_status: 'In Progress',
            report_id: 13,
        },
        {
            date_generated: '2023-10-14',
            msq_score: 83,
            current_status: 'Completed',
            report_id: 14,
        },
        {
            date_generated: '2023-10-15',
            msq_score: 91,
            current_status: 'Completed',
            report_id: 15,
        },
        {
            date_generated: '2023-10-16',
            msq_score: 72,
            current_status: 'In Progress',
            report_id: 16,
        },
        {
            date_generated: '2023-10-17',
            msq_score: 86,
            current_status: 'Completed',
            report_id: 17,
        },
        {
            date_generated: '2023-10-18',
            msq_score: 91,
            current_status: 'Completed',
            report_id: 18,
        },
        {
            date_generated: '2023-10-19',
            msq_score: 76,
            current_status: 'In Progress',
            report_id: 19,
        },
        {
            date_generated: '2023-10-20',
            msq_score: 89,
            current_status: 'Completed',
            report_id: 20,
        },
    ]
}

export const pg3_report_icons = {
    'Vitamins, Minerals & Omega-3s': vitamins_mineral,
    'Detoxification': detoxification,
    'Weight Management': weight_management,
    'Cardiovascular Health': cardiovascular_health,
    'Gastrointestinal Health': gastrointestinal_health,
    'Cognitive Health and Memory': cognitive_health_memory,
    'Immune Health': immune_health,
    'Energy & Fitness': energy_fitness,
    'Glucose Metabolism': glucose_metabolism,
};

export const pg3_report_white_icons = {
    'Vitamins, Minerals & Omega-3s': vitamins_mineral_white,
    'Detoxification': detoxification_white,
    'Weight Management': weight_management_white,
    'Cardiovascular Health': cardiovascular_health_white,
    'Gastrointestinal Health': gastrointestinal_health_white,
    'Cognitive Health and Memory': cognitive_health_memory_white,
    'Immune Health': immune_health_white,
    'Energy & Fitness': energy_fitness_white,
    'Glucose Metabolism': glucose_metabolism_white,
};


export const report_icons = {
    'cognitive': Cognitive,
    'cognitive function': Cognitive,
    'digestive': Digestive,
    'emotions': Emotions,
    'mental health & mood': Emotions,
    'hormone': Hormone,
    'hormones': Hormone,
    'energy': Energy,
    'energy & fatigue': Energy,
    'immune': Immune,
    'joint': Joint,
    'sleep': Sleep,
    'weight and eating': WeightEating,
    'weight / eating': WeightEating,
    'eating': WeightEating,
    'metabolic health': WeightEating,
    'musculoskeletal': Joint
};

export const report_msq_score_tt_text = `
    The Multi Symptom Questionnaire (MSQ) score is calculated based on responses.<br /><br />
    0-12: Good<br />
    13-37: Consider Action<br />
    38+: Elevated<br />
`;
export const report_gad_score_tt_text = ``;
export const report_pss4_score_tt_text = ``;
export const report_phq9_score_tt_text = ``;