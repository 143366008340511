import React, { useEffect, useState } from "react";
import PgTypeAhead from "../../PgTypeAhead";

function LabTestRow({ onInput, handleRemoveClick, index, inputValues, labData, labTests, selectedTestName, showSuggestionsFromParent}) {
    return (
        <div className="row">
            <div className="col-3 my-auto">
                <PgTypeAhead
                    label="Lab Test Name"
                    showLabel={false}
                    required={false}
                    optional={false}
                    textBoxName="test"
                    objectKey='name'
                    index={index}
                    id="test_"
                    onSelect={onInput}
                    selectedItem={selectedTestName(index)}
                    placeholder="Test Name"
                    options={labTests}
                    showSuggestionsFromParent={showSuggestionsFromParent}
                    error={labData.error_test}
                />
            </div>
            <div className="col-3 pe-lg-5">
                <TextInput
                    value={inputValues[index]["result"]}
                    placeholder="Result"
                    name="result"
                    inputHandler={(e) =>
                        onInput(index,
                            "result",
                            e.target.value
                        )
                    }
                    error={labData.error_result}
                />
            </div>
            <div className="col-3 pe-lg-5">
                <TextInput
                    inputHandler={(e) =>
                        onInput(index, "unit", e.target.value)
                    }
                    placeholder="Unit"
                    name="unit"
                    value={inputValues[index]["unit"]}
                />
            </div>
            <div className="col-3 d-flex justify-content-between">
                <div>
                    <TextInput
                        inputHandler={(e) =>
                            onInput(
                                index,
                                "reference_range",
                                e.target.value
                            )
                        }
                        placeholder="Reference Interval"
                        name="reference_range"
                        value={inputValues[index]["reference_range"]}
                    />
                </div>
                <div className="align-self-center">
                    {inputValues.length !== 1 && <i
                        className="fas fa-minus cursor-pointer textc-primary"
                        onClick={() => handleRemoveClick(index)}></i>}
                </div>
            </div>
        </div>
    );
};

export default LabTestRow;

function TextInput({
    type,
    name,
    inputHandler,
    placeholder,
    error,
    errorMessage,
    value,
    isDisable,
}) {
    const style = {
        padding: 15,
        width: "100%",
        outline: "none",
        border: error ? "1.2px solid #f00" : "1px solid #888888",
        borderRadius: "8px",
        height: 50,
        transition: "all .3s ease-in-out",
    };

    return (
        <div className="w-90">
            <input
                placeholder={placeholder}
                name={name}
                type={type}
                onInput={inputHandler}
                defaultValue={value}
                style={style}
                disabled={isDisable}
            />
            {/* <div
          className="text-danger mx-2 my-1 transition-all"
          style={error ? { opacity: 1 } : { opacity: 0 }}
        >
          <i className="fas fa-info-circle"></i> {errorMessage}
        </div> */}
        </div>
    );
}