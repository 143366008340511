// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy {
    list-style-type: '- ';
    list-style-position: inside;
    padding-left: 15px;
}

.privacy li {
    /* padding-left: -10em; */
    text-indent: -0.8em;
    /* padding-left: 1em; */
    /* text-indent: -1.5em; */
}

.privacy li:before {
    /* content: "-"; */
    /* padding-right: 0px; */
}`, "",{"version":3,"sources":["webpack://./src/styles/footer.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,wBAAwB;AAC5B","sourcesContent":[".privacy {\n    list-style-type: '- ';\n    list-style-position: inside;\n    padding-left: 15px;\n}\n\n.privacy li {\n    /* padding-left: -10em; */\n    text-indent: -0.8em;\n    /* padding-left: 1em; */\n    /* text-indent: -1.5em; */\n}\n\n.privacy li:before {\n    /* content: \"-\"; */\n    /* padding-right: 0px; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
