import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { parseUrl } from "query-string";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import HowToReadReport from "../../components/Report/HowToReadReport";
import PatientInfo from "../../components/Report/PatientInfo";
import ReportNavigation from "../../components/Report/ReportNavigation";
import SupplementSuggestion from "../../components/Report/SupplementSuggestion";
import {
  getPatientReport,
  getPDFDownloaded,
  updatePatientReport,
} from "../../store/actions/patientReport";
import { toggleLoading } from "../../store/actions/ui";
import TraitCategoryContainer from "../../components/Report/TraitCategory/TraitCategoryContainer";
import LifestyleSuggestions from "../../components/Report/Lifestyle/LifestyleSuggestions";
import { Loading } from "../../components/Loading";
import { Dropdown } from "react-bootstrap";
import PDFReportOptions from "../../components/Report/PDFReportOptions";
import { useNavigate, useLocation } from "react-router-dom";

function ReportSummary({ history }) {

  // redux state variable

  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const content = useSelector(state => state.patientReport.content);
  const loading = useSelector(state => state.patientReport.isGettingPatientDetail);
  const currentPatientId = useSelector(state => state.patientReport.currentPatientId);
  const currentReportId = useSelector(state => state.patientReport.currentReportId);
  const userType = useSelector(state => state.auth.userType);
  const psStatus = useSelector(state => state.patientReport.patientDetail.patient.ps_status);
  const location = useLocation();

  console.log(currentPatientId)

  const dispatch = useDispatch();

  // redux actions
  const updatePatientReportAction = (data) => dispatch(updatePatientReport(data));
  const toggleLoadingAction = (data) => dispatch(toggleLoading(data));
  const getPatientReportAction = (token, currentPracticeID, currentPractice, id) => dispatch(getPatientReport(token, currentPracticeID, currentPractice, id));
  const getPDFDownloadedAction = (token, currentPracticeID, currentPractice, data) => dispatch(getPDFDownloaded(token, currentPracticeID, currentPractice, data));


  const { t, i18n } = useTranslation();
  const patientTextColor = {
    color:
      userType === "Patient" ? "var(--pg_primary__p)" : "var(--pg_primary)",
  };
  const [showHowToRead, setShowHowToRead] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [showIframe, setshowIframe] = useState(false);
  const [pdfURl, setpdfURl] = useState("");
  const [viewType, setViewType] = useState("grid");
  const PG4ReportId = useSelector(state => state.pg4Report.reportData.report_id);
  const navigate = useNavigate();

  // for showing report via url
  useEffect(() => {
    const queries = parseUrl(window.location.href).query;
    if (queries.patient_id && queries.report_id) {
      updatePatientReportAction({
        currentPatientId: queries.patient_id,
        currentReportId: queries.report_id,
      });
      setShowLoading(false);
    } else setShowLoading(false);
  }, []);

  const redirectToDashboard = () => {
    // return location?.state?.fromPG4Report
    //   ? navigate(`${userType === "User" ? '/practice/report/new/' : '/patient/report/new/'}${PG4ReportId}`)
    //   : navigate(`${userType === "Patient" ? '/patient/dashboard' : '/practice/patients'}`)
    navigate(-1);
  };

  const showTabularView = () => {
    document.getElementById("trait-content").scrollIntoView();
    setViewType("table");
  };

  return (
    <div>
      <Helmet>
        <title>
          {t('puregenomics_1')} - DNA Report Summary
        </title>
      </Helmet>
      {showIframe && (
        <>
          <div className="d-none">
            <iframe
              src={pdfURl}
              id="printf"
              height="1000px"
              width="100%"
              type="application/pdf"
              overflow="auto"
            />
          </div>
        </>
      )}
      {showLoading ? (
        <Loading />
      ) : (
        <div className="no-print">
          <HowToReadReport t={t} show={showHowToRead} onHide={setShowHowToRead} />
          <div className="container-fluid">
            <div className="container-xxl px-0 my-4">
              <div
                className="pg-text pg-link mt-5"
                onClick={redirectToDashboard}
                style={patientTextColor}
              >
                {" "}
                <i className="fas fa-chevron-left"></i>{" "}
                {location?.state?.fromPG4Report ? 'View Report' : (userType === "Patient" ? t('dashboard') : t('patients'))}
              </div>
              <div className="row justify-content-between">
                <div className="col-12 col-lg-6">
                  <div className="pg-title py-3" style={patientTextColor}>
                    {t('pracrs_title')}
                  </div>
                  <div
                    className="py-1 pg-text cursor-pointer"
                    onClick={() => setShowHowToRead(true)}
                    style={patientTextColor}
                  >
                    <i className="fas fa-info-circle pe-2"></i>{" "}{t('pracrs_htryr_1')}
                  </div>
                </div>
                <div className="col-12 col-lg-3 pg-link mt-4">
                  <div className=" float-lg-end" style={patientTextColor}>
                    <PDFReportOptions
                      currentPractice={currentPractice}
                      currentPracticeID={currentPracticeID}
                      token={token}
                      userType={userType}
                      currentReportId={currentReportId}
                      currentPatientId={currentPatientId}
                      t={t}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*separating content for PDF */}

          {
            <div>
              {/* patient info card */}

              <PatientInfo t={t} patientId={currentPatientId} />

              {/*content below patient info card */}
              <div className="container-fluid" id="report_summary">
                <div className="container-xxl px-0 my-4">
                  {/* traits category */}
                  <TraitCategoryContainer
                    patientId={currentReportId}
                    viewType={viewType}
                    setViewType={setViewType}
                    psStatus={psStatus}
                  />

                  {/* report navigation section */}
                  {userType === "User" && (
                    <ReportNavigation
                      reportId={currentReportId}
                      patientId={currentReportId}
                      showGeneAnalysis={showTabularView}
                      t={t}
                      psStatus={psStatus}
                    />
                  )}

                  {/* supplement suggestion section */}
                  <SupplementSuggestion
                    patientId={currentPatientId}
                    reportId={currentReportId}
                  />

                  {/* Lifestyle suggestion section */}
                  <LifestyleSuggestions />
                </div>
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
}

export default ReportSummary;
