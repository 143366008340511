import React from "react";
import { Link } from 'react-router-dom'

const CACookiesNotice = () => {
  return (
    <div className='container-xxl my-5 px-md-1 px-0'>
      <div>
        <div className="pgs-heading textc-primary my-5">
          This cookie notice applies to all Atrium Innovations websites and mobile applications (“Atrium Innovations Sites”).
        </div>
        <div>
          <div className='my-5'>
            <div className="pgs-text textc-primary fw-bold">
              1. What are cookies?
            </div>
            <div className="ps-4 pgs-text mt-2">
              Cookies are text files that are placed on your computer by websites that you visit. Cookies are then sent back to the originating website on each subsequent visit, or to another website that recognises that cookie. They are used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the website. 
              <div className='mt-3'>
                We use the term "cookies" throughout this notice to also cover all similar technologies, such as web beacons SDK, log files, pixel tags.
              </div>
            </div>
          </div>
          <div className='my-5'>
            <div className="pgs-text textc-primary fw-bold">
              2. What types of cookies are used on Atrium Innovations Sites?
            </div>
            <div className="ps-4 pgs-text mt-2">                
              You may see what cookies are used on this website by clicking <a href="https://www.atrium-innovations.ca/en/cookie-notice" target="_blank" rel="noreferrer">here</a>.<br/>
              <br/>
              We use the following types of cookies on Atrium InnovationsSites:                
              <div className='my-3'>
                <table className='pg-table cookies-table'>
                  <thead>
                    <tr>
                      <th>Cookie categories</th>
                      <th>Lifespan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className='fw-bold'>Site functionality cookies</div>
                        <div className="ps-4">
                          These cookies allow you to navigate the site and use our features, such as registration, logging in and product favourites. If you disable these cookies certain parts of the website will not function for you, for example, adding items to your basket, proceeding to checkout. 
                        </div>
                      </td>
                      <td className='fw-bold'>As long as necessary</td>
                    </tr>
                    <tr>
                      <td>
                        <div className='fw-bold'>Site analytics cookies</div>
                        <div className="ps-4">
                          These cookies allow us to measure and analyse how you use our websites, apps and mobile platforms, to improve both its functionality and your experience.
                        </div>
                      </td>
                      <td className='fw-bold'>90 days</td>
                    </tr>
                    <tr>
                      <td>
                        <div className='fw-bold'>Customer preference cookies</div>
                        <div className="ps-4">
                          When you are browsing on our sites, these cookies will remember your preferences (like your language or location), and other information you choose to provide to us, so we can help tailor your experience and make it more relevant and personal to you.
                        </div>
                      </td>
                      <td className='fw-bold'>90 days</td>
                    </tr>
                    <tr>
                      <td>
                        <div className='fw-bold'>Advertising or targeting cookies</div>
                        <div className="ps-4">
                          These cookies are used to deliver ads relevant to you. They also limit the number of times that you see an ad and help us measure the effectiveness of our marketing campaigns. We may also use the information obtained via these cookies to serve you with advertising that may be of interest to you based on your past online behaviour. We may share this information with other parties, including our partners. 
                        </div>
                      </td>
                      <td className='fw-bold'>90 days</td>
                    </tr>
                    <tr>
                      <td>
                        <div className='fw-bold'>Social media cookies</div>
                        <div className="ps-4">
                          These cookies are used when you share information using a social media sharing button on a Atrium Innovations Site. The social network will record that you have done this. This information may be linked to targeting/advertising activities.
                        </div>
                      </td>
                      <td className='fw-bold'>90 days</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className='my-5'>
            <div className="pgs-text textc-primary fw-bold">
              3. Manage your cookie preferences
            </div>
            <div className="ps-4 pgs-text mt-2">
              You may access and change your cookie preferences at any time by clicking <a href="https://www.atrium-innovations.ca/en/cookie-notice" rel="noreferrer" target="_blank">here</a>.
              <br/>
              <br/>
              You do not need to allow cookies to use or navigate through many parts of Atrium Innovations Sites, although you may not have access to all the features on Atrium Innovations Sites if you do not accept cookies. You should ensure that your device settings reflect your cookies preferences. You can also set your browser to warn you before accepting cookies or you set it to refuse them.   
              <br />
              <br />
              Additionally, where available, you can opt-out of cookies by visiting the following sites: 
              <a href="http://www.aboutads.info/choices/#completed" rel="noreferrer" target="_blank"> http://www.aboutads.info/choices/#completed</a> or
              <a href="http://www.youronlinechoices.eu/" rel="noreferrer" target="_blank"> http://www.youronlinechoices.eu/</a>
            </div>
          </div>
          <div className='my-5'>
            <div className="pgs-text textc-primary fw-bold">
              4. Notice Update
            </div>
            <div className="ps-4 pgs-text mt-2">
              We reserve the right to make any changes and corrections to this Notice. Please refer to this page from time to time to review these and new additional information. We will place updates on Atrium Innovations Sites and, where appropriate, we will notify of any changes by email.
            </div>
          </div>
          <div className='my-5'>
            <div className="pgs-text textc-primary fw-bold">
              4. Privacy Policy
            </div>
            <div className="ps-4 pgs-text mt-2">
            For further information about how your data is used (including controller details), please see our Privacy Notice. 
              <Link to="/privacy-policy"> Privacy Notice</Link>.
            </div>
          </div>
        </div>
        <div className='pgs-heading__sub'>
          This Cookie Notice was last updated February 15, 2023.
        </div>
      </div>
    </div>
  );
}

export default CACookiesNotice;