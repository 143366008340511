import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getPatientWSData } from "../../store/actions/patientWelcome";
import { updateQuestionSession } from "../../store/actions/questions";
import qs, { parseUrl } from "query-string";
import { useTranslation } from "react-i18next";
import PatientTAC from "../../components/Patient/PatientTAC";
import { useNavigate } from "react-router-dom";
const PatientDashboard = ({ location }) => {

  const navigate = useNavigate();
  // redux state variable
  const userName = useSelector(state => state.auth.firstName || "userName");
  const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
  const currentPractice = useSelector(state => state.auth.currentPractice);
  const token = useSelector(state => state.auth.token);
  const geneticBy = useSelector(state => state.welcomeScreen.genetic_upload_by);
  const labBy = useSelector(state => state.welcomeScreen.lab_upload_by);
  const isInvited = useSelector(state => state.welcomeScreen.invite_questionnaire);
  const practiceName = useSelector(state => state.welcomeScreen.practice_name);
  const currentUserId = useSelector(state => state.auth.currentUserId);
  const goToPatientDasboard = useSelector(state => state.auth.goToPatientDasboard);
  const questionnaire_data = useSelector(state => state.welcomeScreen.questionnaire_data);

  // redux actions
  const dispatch = useDispatch();
  const getPatientWelcomeScreenData = (token, currentPracticeID, currentPractice) => dispatch(getPatientWSData(token, currentPracticeID, currentPractice));
  const updateQuestionSessionAction = (token, currentPracticeID, currentPractice, data) => dispatch(updateQuestionSession(token, currentPracticeID, currentPractice, data));

  useEffect(() => {
    let qp = parseUrl(window.location.href).query
    if (qp.scriptId) {
      const obj = qs.parse(window.location.search, { decode: false });
      return navigate("/patient/supplement_suggestions", {
        state: {
          path: "/supplement_suggestions",
          script: obj,
        }
      });
    }
    if (qp.orderId) {
      const obj = qs.parse(window.location.search, { decode: false });
      return navigate("/patient/order/" + obj.orderId, {
        state: {
          path: `/order/${obj.orderId}`,
          orderId: obj.orderId,
        }
      });
    }

    if (goToPatientDasboard) {
      navigate('/patient/dashboard');
    }
    else
      getPatientWelcomeScreenData(token, currentPracticeID, currentPractice);

  }, []);



  const handleGetStarted = () => {
    if (geneticBy) {
      navigate("/patient/snp");
    } else if (isInvited) {
      updateQuestionSessionAction(token, currentPracticeID, currentPractice, {
        patient_id: currentUserId,
        status: questionnaire_data ? questionnaire_data?.status : "no_data",
      });
      navigate("/patient/questionnaire");
    }
  };
  const { t, i18n } = useTranslation();



  return (
    <div className="container-xl py-5 px-md-2 px-3">
      <PatientTAC />
      <div className="pg-title text-color__p">{t('pracd_welcome')}, {userName}</div>
      <div className="row justify-content-center justify-content-lg-between my-4">
        <div className="col-12 col-lg-7">
          <p className="pg-text my-4 mt-lg-4 mt-0">
            <span dangerouslySetInnerHTML={{ __html: t('pat_guideline_1') }} />
          </p>
          <p className="pg-text my-4">
            <span dangerouslySetInnerHTML={{ __html: t('pat_guideline_2') }} />
          </p>
          <div className="pg-text">

            {(geneticBy || isInvited) ? (<div>
              {t('patient_welcome_text1')}
            </div>) : ""}
            <ol>
              {geneticBy && <li>{t('patient_welcome_text2')}</li>}
              {isInvited && <li>{t('patient_welcome_text3')}</li>}
            </ol>{" "}
            <br />
          </div>
          {(geneticBy || isInvited) ? (<p className="pg-text my-3">
            {t('patient_welcome_text4')}
            <br />
            <br />
            <br />
            {t('patient_welcome_text5')}
            <br />
            {/* — Dr. Alex Phoenix */}— {practiceName}
          </p>) : ""}
        </div>
        <div className="col-12 col-lg-4  text-center">
          <div className="my-4">
            {(geneticBy || isInvited) ? (<button
              type="button"
              className="btn btn-primary__p text-light px-5 w-100 mb-2 mt-2 "
              onClick={() => handleGetStarted()}
            >
              {t('patient_welcome_text6')}
            </button>) : ""}
            {" "}
            <br />
            <button
              type="button"
              className="btn btn-outline__p px-5 w-100 textc-primary mt-3 mt-lg-0"
              onClick={() => navigate("/patient/dashboard")}
            >
              {t('patient_welcome_text7')}
            </button>
            <button
              type="button"
              className="btn btn-outline__p px-5 w-100 textc-primary mt-3"
              onClick={() => navigate("/patient/dashboard")}
            >
              Go To PG4
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDashboard;