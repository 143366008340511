import { useNavigate } from "react-router-dom";
const ReportNavigation = ({ reportId, patientId, showGeneAnalysis, t, psStatus }) => {
  const navigate = useNavigate();
  return (
    <div className="row my-5 py-4">
      <div className="col-12 col-md-4 " style={{ borderRight: '1px solid #d6d6d6' }}>
        <div className="">
          <div className="pg-text__bold">{t('pracrs_line_6')}</div>
          <div className="pg-text" dangerouslySetInnerHTML={{ __html: t('pracrs_line_7') }} />

          <div
            className="pg-link"
            onClick={() =>
              // navigate(
              //   `/practice/patient/report/gene_analysis?id=${patientId}`
              // )
              showGeneAnalysis()
            }
          >
            {t('pracrs_btn_8')} <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>

      <div className="col-12 col-md-4" style={{ borderRight: '1px solid #d6d6d6' }}>
        <div className="">
          <div className="pg-text__bold">{t('diagrams')}</div>
          <div className="pg-text">
            {t('pracrs_line_8')}
          </div>
          <div
            className="pg-link"
            onClick={() =>
              navigate(`/practice/patient/report/diagram?id=${patientId}`)
            }
          >
            {t('pracrs_btn_9')} <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-4">
        <div className="">
          <div className="pg-text__bold">{t('pracrs_line_9')}</div>
          <div className="pg-text">
            {t('pracrs_line_10')}
          </div>
          <div
            className="pg-link"
            onClick={() =>
              navigate(
                `/practice/patient/report/supplemental_genotype?id=${patientId}`
              )
            }
          >
            {t('pracrs_btn_10')} <i className="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
      {(typeof (psStatus) !== 'undefined' && psStatus !== 'pending') &&
        <div className="col-12 col-md-4 my-md-3">
          <div className="">
            <div className="pg-text__bold">
              Polygenic Score
            </div>
            <div className="pg-text">
              View the Polygenic Score and
              Personalized Daily Intake Recommendation
            </div>
            <div
              className="pg-link"
              onClick={() =>
                navigate(
                  `/practice/patient/report/ps_list/${reportId}`
                )
              }
            >
              {/* {t('pracrs_btn_10')} */}
              {" View PGS/PIR "}
              <i className="fas fa-chevron-right"></i>
            </div>
          </div>
        </div>
      }

    </div>
  );
};

export default ReportNavigation;
