import React, { useState, useEffect } from "react";
import AboutPEImage_2 from "../../../assets/static/aboutpe_2.jfif";
import { useTranslation } from "react-i18next";

function AboutPEPatient({ history }) {
  const { t, i18n } = useTranslation();
  return (
    <div className="my-5">
      <div className="container-xxl px-0 px-md-4">
        <div className="row justify-content-lg-between justify-content-center my-lg-5 my-0">
          <div className="col-12 col-lg-6 px-0">
            <div className="pgs-text d-block d-lg-none mx-4 px-2 px-lg-0">
              Ready to Start Something Good?Because it's what's on the inside that counts, all Pure
              Encapsulations products are formulated with the highest-quality
              ingredients, backed by science, and FREE FROM:
            </div>
            <img
              src={AboutPEImage_2}
              alt="Supplement Bottle Image"
              className="w-100 py-4"
            />
          </div>
          <div className="col-11 col-lg-5">
            <div className="pgs-title my-3">
              Ready to Start Something Good?
            </div>
            <div className="pgs-text d-none d-lg-block">
              <span dangerouslySetInnerHTML={{ __html: t('about_pureencapsulation_patient') }} />
              {/* <div className="fw-bold text-secondary" style={{paddingTop:'220px',fontSize:14,lineHeight:'16px'}}>
                Food supplements should not be used as a substitute for a varied diet and a healthy lifestyle.
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPEPatient;
