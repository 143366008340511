import { Modal } from "react-bootstrap";

const DeleteRecommendationModal = ({ showModal, onCancel, onConfirm }) => {
    return (<Modal
        show={showModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={onCancel}
        contentClassName="border-radius p-2"
        className="delete-recommendation-confirmation-modal"
        centered
    >
        {/* <Modal.Header></Modal.Header> */}
        <Modal.Body>
            <b>Are you sure you want to delete the selected recommendations?</b>
        </Modal.Body>
        <Modal.Footer>
            <button className="pg4-btn pg4-btn_outline" onClick={onCancel}>Cancel</button>
            <button className="pg4-btn pg4-btn_primary" onClick={onConfirm}>COnfirm</button>
        </Modal.Footer>

    </Modal>)
}

export default DeleteRecommendationModal;