import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import '../../../../styles/PG4/pgcheckbox.scss'
import { updatePG4ReportData } from '../../../../store/actions/pg4report';
import { recommendations_cat_key_map } from '../../../../constant';

const schema = yup.object().shape({
    recommendationText: yup.string().required('Recommendation text is required'),
    focusAreas: yup.array().min(1, 'Please select at least one focus area').required(),
});

function AddRecommendation({
    report_id,
    show,
    focusAreas,
    onCancel,
    context = "diet",
    onCreateRecommendation
}) {
    const dispatch = useDispatch();
    const reportData = useSelector(state => state.pg4Report.reportData)
    const updatePG4ReportDataAction = (data) => dispatch(updatePG4ReportData(data))
    const { watch, register, handleSubmit, getValues, formState: { errors, isSubmitting } } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            recommendationText: '',
            focusAreas: []
        }
    });

    const onSubmit = async (data) => {
        try {
            const newRecommendation = {
                report_id,
                recommendation_category: context,
                focus_areas: data.focusAreas,
                recommendation_text: data.recommendationText,
            };
            const response = await onCreateRecommendation(newRecommendation);
            if (!response.error) {
                const current_category = recommendations_cat_key_map[context];
                const newReportData = {
                    ...reportData,
                    [current_category]: {
                        ...reportData[current_category],
                        recommendations: [{
                            // replace these three lines with server response 
                            ...response.payload.data
                        }, ...reportData[current_category]?.recommendations]
                    }
                }
                updatePG4ReportDataAction(newReportData)
                onCancel();
            }
        } catch (error) {
            console.error("Error creating recommendation:", error);
        }
    };

    const focusAreasWatch = watch('focusAreas', []);

    return (
        <Modal
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            onHide={onCancel}
            contentClassName="border-radius"
            backdrop="static"
            className="edit-practitioner-modal modal-dialog-scrollable"
            id="add-recommendation-modal"
        >
            <Modal.Header className="d-flex flex-column w-100 align-items-end py-2" >
                <div className="text-end">
                    <button className="pg4-btn bg-transparent fs-3" onClick={onCancel}>
                        <i className="fas fa-times"></i>
                        <span className='sr-only' aria-hidden="true">Close</span>
                    </button>
                </div>
                <div className="d-flex flex-wrap justify-content-between  w-100">
                    <div className="w-sm-100">
                        <h1 className="fs-4 pg4-text__subheading fw-bold text-capitalize text_primary">Add Recommendation</h1>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="d-flex flex-wrap align-items-center">
                        {
                            focusAreas?.map((fa, idx) => (
                                <div key={idx} className='me-3 d-flex  align-items-center'>
                                    <input
                                        type="checkbox"
                                        id={`focus-area-${idx}`}
                                        name="focusAreas"
                                        value={fa}
                                        className='me-1 pg-checkbox'
                                        {...register('focusAreas')}
                                        // style={{ width: 25, height: 25 }}
                                        aria-label={`Select ${fa} focus area`}
                                        aria-checked={Array.isArray(getValues('focusAreas')) ? getValues('focusAreas').includes(fa) : false}

                                    />
                                    <label
                                        htmlFor={`focus-area-${idx}`}
                                        className={`text-capitalize
                                        pill pill-${fa?.toLowerCase()}`}
                                        style={{ fontSize: 14, lineHeight: 1.2, fontWeight: 'unset' }}
                                    >{fa}</label>
                                </div>
                            ))
                        }
                    </div>
                    {errors?.focusAreas && <div className="text-danger my-2">{errors?.focusAreas?.message}</div>}
                    <textarea
                        className="w-100 patient_notes patient_notes_dl mt-3 border"
                        id="add-recommendation__text"
                        rows={5}
                        placeholder="Enter recommendation text"
                        {...register('recommendationText')}
                        aria-label="Enter recommendation text"
                    />
                    {errors?.recommendationText && <div className="text-danger">{errors?.recommendationText?.message}</div>}

                    <div className='text-center my-3'>
                        <button
                            type="submit"
                            className='pg4-btn pg4-btn_primary px-4'
                            disabled={isSubmitting}
                            aria-label="Submit recommendation"
                        >
                            {isSubmitting ? 'Creating...' : 'Create Recommendation'}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal >
    );
}

export default AddRecommendation;