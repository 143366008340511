import React, { useState, useEffect } from 'react'
import i_blue from '../assets/i_blue.png';
import _i from '../assets/i.png';
import Popup from 'reactjs-popup'

function PgGroupButton({ buttonNames, activeButton, onButtonClick }) {
    const style = {
        btnContainer: {
            margin: 0,
            padding: 0
        }
    }
    return (
        <div className="br d-flex pg-text mx-0 px-0  transition-all" style={style.btnContainer}>
            {
                buttonNames.map((btn, i) => (
                    <Popup
                        key={i}
                        on="hover"
                        trigger={(open) => (
                            <div
                                onClick={() => onButtonClick(btn)}
                                className="cp px-3 mx-0 transition-all"
                                style={{
                                    borderRadius: `${i === 0 ? "4px" : "0"} ${i === buttonNames?.length - 1 ? "4px" : "0"} ${i === buttonNames?.length - 1 ? "4px" : "0"} ${i === 0 ? "4px" : "0"}`,
                                    borderTop: '2px solid var(--pg_primary)',
                                    borderBottom: '2px solid var(--pg_primary)',
                                    borderLeft: '2px solid var(--pg_primary)', // comment this for more than 2 buttons
                                    // borderLeft: (i === 0) ? '2px solid var(--pg_primary)':"", // uncomment this for more than 2 buttons
                                    borderRight: '2px solid var(--pg_primary)',
                                    color: btn === activeButton ? "#fff" : "var(--pg_primary)",
                                    background: btn === activeButton ? "var(--pg_primary)" : ""
                                }}>{btn}
                            </div>
                        )}
                        position="top center"
                        closeOnDocumentClick
                        className="cp"
                    >
                        <div style={{ padding: '12px' }}>
                            {btn === "Show Actionable" && <>Show only traits with considerable actions <br /> or enhanced benefit</>}
                            {btn === "Show All" && <>Show all traits</>}
                        </div>
                    </Popup>))
            }
        </div>
    )
}

export default PgGroupButton;

const ActionButtonTooltip = ({ children }) => {
    return (
        <Popup
            on="hover"
            trigger={(open) => (
                <>{children}</>
            )}
            position="top center"
            closeOnDocumentClick
            className="cp"
        >
            <div style={{ padding: '12px' }}>
                Show traits with ‘Consider Action’ <br />
                or 'Enhanced Benefit’.
            </div>
        </Popup>

    )

}
