import React, { useState } from "react";
import down from "../../../assets/down.png";
import right from "../../../assets/right.png";
import NutritionDietItem from "./NutritionDietItem";

export default function NutritionDiet({
  title,
  description,
  lifestyleKey,
  userType
}) {
  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  const [showDetails, setShowDetails] = useState(true);
  return (
    <div>
      <div className="container-fluid">
        <div
          onClick={() => setShowDetails(!showDetails)}
          className="row pt-4 pb-1 px-0 cursor-pointer"
          style={{ borderTop: "0.4px solid grey" }}
        >
          <div className="col-lg-3 col-11 mb-3 pg-heading" style={patientTextColor}>{title}</div>
          <div className="col-lg-1 col-1 mb-3 pg-text order-lg-last text-end">
            {showDetails ? (
              <img
                src={down}
                style={{ cursor: "pointer" }}
                onClick={() => setShowDetails(!showDetails)}
              />
            ) : (
              <img
                src={right}
                style={{ cursor: "pointer" }}
                onClick={() => setShowDetails(!showDetails)}
              />
            )}
          </div>
          <div className="col-lg-8 col-12 mb-3">
            {
              !showDetails &&
              <div dangerouslySetInnerHTML={{ __html: (description?.substring(0, 180) + "...") }} />
            }
          </div>
        </div>
        {showDetails && (
          <NutritionDietItem lifestyleKey={lifestyleKey} />
        )}
      </div>
    </div>
  );
}