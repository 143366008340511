import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import BlockUi from "react-block-ui";
import * as moment from "moment";

function PatientSupplement() {
// redux state variable
	const currentPracticeID = useSelector(state => state.auth.currentPracticeID);
	const currentPractice = useSelector(state => state.auth.currentPractice);
	const currentUserId = useSelector(state => state.auth.currentUserId);
	const token = useSelector(state => state.auth.token);
	const loading = useSelector(state => state.practiceDashboard.loading);
	const supplement_recommendations = useSelector(state => state.practiceDashboard.supplement_recommendations);
	const appToken = useSelector(state => state.auth.appToken);



	return (
		<div className="pg-card p-3 px-4" style={{ minHeight: 390, backgroundColor: '#2470c2' }} >
      <div>
        <div className="pg-text text-white text-center my-2 mt-4 pt-pg-title">
          Step 2
        </div>
        <div className="pg-heading text-white text-center my-2 mt-3 pt-pg-heading">
			Upload your genetic data to puregenomics
        </div>
        <div className="pg-text text-white text-center my-2 mt-4 pt-pg-content">
			Upload your 23andMe<sup>&reg;</sup> or Ancestry<sup>&reg;</sup> data.
        </div>
        <div className="mt-4 pt-1 w-100 text-center">
          <button
            className="btn btn-light px-5 mx-auto my-4 step-1-btn"

            id="view-patient-btn__dashboard"
          >
            Upload your data
            </button>
        </div>
      </div>
    </div>
	);
}
export default PatientSupplement;
