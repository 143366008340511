import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function LabReviewUpdateSuccess({
  toPatientList,
  toUploadPage,
  onCancel,
  showModal,
  modalText,
}) {
  const { t, i18n } = useTranslation();
  const userType = useSelector(state => state.auth.userType);
  return (
    <Modal
      show={showModal}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onCancel}
      contentClassName="border-radius"
    >
      <Modal.Header>
        {" "}
        <div className="pg-heading">{t("patient_thx_text1")}</div>
      </Modal.Header>
      <Modal.Body>
        <div className="pg-text ">
          {
            (userType == "User") ? t("Thank you for uploading your patient's lab results. A more personalized report is being created based on this information. You will receive an email when the report is ready or check back here anytime.") : t("Thank you for uploading your lab results. A more personalized report is being created based on this information. You will receive an email when the report is ready or check back here anytime.")
          }
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        {/* <button className="btn bg-none text-dark px-5" onClick={onCancel}>
          Cancel
        </button> */}
        <div>
          <button
            className="btn btn-sm btn-outline text-light px-4 mx-1"
            onClick={toPatientList}
            title={t("txt_goto_patient_list")}
          >
           {t(modalText)}
          </button>
        </div>
        <div>
          <button
            className="btn btn-sm btn-outline text-light px-4"
            onClick={toUploadPage}
            title={t("txt_goto_lab_upload")}
          >
             {t("txt_lab_upload")}  
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
export default LabReviewUpdateSuccess;
