import React from "react";
import { connect } from "react-redux";
import Popup from "reactjs-popup";
import '../../../styles/main.css'

const PolygenicScore = ({ data, userType, t }) => {
  const patientTextColor = { color: userType === "Patient" ? "var(--pg_primary__p)" : 'var(--pg_primary)' }
  return (
    <div className="container-prs">
      <hr className="my-5" />
      <div className="my-5">
        <div className="pg-heading my-2" style={patientTextColor}> Polygenic Score </div>
        <div className="pd-2">
          {data?.ps_score ? (
            <div className='info-fx'>
              <h1 className='pg-text__bold'>Your polygenic score</h1>
              <div className="">
                <span className="info-logo">
                  <i className="fas fa-info-circle fa-circle-info info-icon-blue i-icon"></i>
                  <div className="popup-content ps-box">
                    <h1 className="pg-sub-heading">ABOUT YOUR SCORE</h1>
                    <p>{data.ps_about}</p>
                    <svg data-testid="arrow" className="popup-arrow" viewBox="0 0 32 16"><path d="M16 0l16 16H0z" fill="currentcolor"></path></svg>
                  </div>
                </span>
              </div>
              <h1 className="pg-sub-heading">{data?.ps_score}</h1>
            </div>
          )
            : (<h1 className="pg-sub-heading"></h1>)}
          <h1 className='pg-text'> {data.ps_description} </h1>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({


});

const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(PolygenicScore);
