import { useState } from "react";
import { useSelector } from "react-redux";
import L_MSQProgressLoader from "../../LoadingEffectComponent/PG4/L_MSQProgressLoader";

function MSQProgress({ onSelectSections }) {

    const [showSections, setshowSections] = useState(false);
    const sections = useSelector(state => state.pg4?.q_sections || [])
    const currentSection = useSelector(state => state.pg4.q_current_section);
    const progress = ((currentSection?.section_id - sections[0]?.id) / (sections?.length)) * 100;
    const value = progress.toFixed(0);
    const isLoading = useSelector(state => state.pg4?.questionsLoading)

    return (

        <div className="p-3 position-relative bg_secondary">
            {isLoading
                ? <L_MSQProgressLoader />
                : <div>
                    <div className="pgs-text text_primary">{value ? value : 0}% Complete</div>
                    <div className="msq-progress my-3 overflow-hidden" style={{ '--progress-width': value + '%' }} data-progress={value + '%'}>
                    </div>
                    <div className="d-flex justify-content-between d-lg-none pt-2 pe-2 cp" onClick={() => setshowSections(!showSections)}>
                        <div className="pg4-text fw-bold">
                            {currentSection?.section_name}
                        </div>
                        <div className="my-auto" style={{ transition: 'all .3s ease', transform: `rotate(${showSections ? '0' : '180'}deg)` }}>
                            <i className="fas fa-chevron-up"></i>
                        </div>
                    </div>
                    {showSections && <div className={`pg4-section bg_secondary ${showSections ? 'show' : ''}`}>
                        {sections.map((section, i) => {
                            return <div
                                key={i}
                                onClick={onSelectSections ? () => onSelectSections(section) : undefined}
                                className={`msq-section__item ${section.id <= currentSection?.section_id ? 'bg_tertiary' : ''}`} >
                                <div className="me-2">{section.name}</div>
                                <div className="checkbox-wrapper-18 my-auto">
                                    <div className="round">
                                        <input type="checkbox" id="checkbox-18" checked={section.id < currentSection?.section_id} />
                                        <label for="checkbox-18"></label>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>}
                </div>}
        </div>);
}

export default MSQProgress;