import { useState, useEffect } from "react";
import warningIcon from "../../../assets/warning.png";
import TraitCardDropDown from "./TraitCardDropDown";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { pg3_report_icons, pg3_report_white_icons } from "../../Patient/PG4/constant";


const TraitCard = ({ traitData, index }) => {
  const { t, i18n } = useTranslation();

  const [onHoveredBGStyle, setonHoveredBGStyle] = useState("#fff");
  const [onHoveredTextStyle, setonHoveredTextStyle] = useState("#000");
  const [showPopup, setShowPopup] = useState(index == 0);
  const [onHovered, setOnHovered] = useState(index == 0);
  useEffect(() => {
    if (index == 0) {
      setonHoveredBGStyle(traitData.color);
      setonHoveredTextStyle("#fff");
    }
  }, [])

  return (
    <div className="w-100 position-relative ">
      <div
        onClick={() => setShowPopup(!showPopup)}
        className="border-radius position-relative cursor-pointer"
        style={{
          transition: "all .3s ease-in-out",
          background: onHoveredBGStyle,
          color: `${onHoveredTextStyle} `,
        }}
        onMouseEnter={(e) => {
          setonHoveredBGStyle(traitData.color);
          setonHoveredTextStyle("#fff");
          setOnHovered(true);
        }}
        onMouseLeave={(e) => {
          setonHoveredBGStyle("#fff");
          setonHoveredTextStyle("#000");
          setOnHovered(false);
          setShowPopup(false);
        }}
      >
        <hr className="my-0" />
        <div
          className="py-2 px-2 position-relative "
          style={{ color: `${onHoveredTextStyle} ` }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <div className="d-inline-block">
                <img
                  src={onHovered ? pg3_report_white_icons[traitData.title] : pg3_report_icons[traitData.title]}
                  alt={traitData.title}
                  style={{ height: 25, width: 25 }}
                />
                <span
                  className="pg-text ms-2"
                  style={{ color: `${onHoveredTextStyle} ` }}
                >
                  {traitData.title}
                </span>
              </div>
              <div>
                <div style={{ color: `${onHoveredTextStyle} ` }}>
                  <div
                    className="ms-4 p-2 text-lowercase"
                    style={{
                      fontSize: "12px",
                      color: onHovered ? "#fff" : "#888",
                      fontWeight: 400,
                    }}
                  >
                    {traitData.traitsLength} {t('traits')}
                  </div>
                </div>
                {onHovered && showPopup && (
                  <TraitCardDropDown
                    traits={traitData.traits}
                    color={traitData.color}
                    positionTop={78}
                    traitCat={traitData.title}
                  />
                )}
              </div>
            </div>
            <div>
              {traitData.considerableTraits > 0 && (
                <span>
                  {traitData.considerableTraits}{" "}
                  <img src={warningIcon} alt="" className="mb-1" />
                  {/* <i className="fas fa-info-circle text-warning "></i> */}
                </span>
              )}
              <i
                className="fas fa-chevron-right transition-all mb-n2 mx-2 mt-4"
                style={{
                  transform: showPopup ? "rotate(90deg)" : "rotate(0deg)",
                }}
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TraitCard;
