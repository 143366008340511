// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --primary: #08184a;
  --prac-primary: #14328c;
  --pat-primary: #2470c2;
  --secondary: #f2f4f5;
  --tertiary: #dde7f0;
  --border-color: rgb(170 181 214);
  --border-radius: 12px;
  --box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

body {
  --primary: var(--prac-primary);
}

body.patient {
  --primary: var(--pat-primary);
}

.bg_primary {
  background: var(--primary);
}

.text_primary {
  color: var(--primary);
}

.text_secondary {
  color: var(--secondary);
}

.bg_secondary {
  background: var(--secondary);
}

.bg_tertiary {
  background: var(--tertiary);
}`, "",{"version":3,"sources":["webpack://./src/styles/PG4/variable.scss"],"names":[],"mappings":"AAIA;EACI,kBAAA;EACA,uBAAA;EACA,sBAAA;EACA,oBAAA;EACA,mBAAA;EACA,gCAAA;EACA,qBAAA;EACA,0FAAA;AAHJ;;AAMA;EACI,8BAAA;AAHJ;;AAMA;EACI,6BAAA;AAHJ;;AAQA;EACI,0BAAA;AALJ;;AAQA;EACI,qBAAA;AALJ;;AAQA;EACI,uBAAA;AALJ;;AASA;EACI,4BAAA;AANJ;;AASA;EACI,2BAAA;AANJ","sourcesContent":["// $primary-color: #14328c;\n// $body-bg: #f2f4f5;\n// @import '~bootstrap/scss/bootstrap';\n\n:root {\n    --primary: #08184a;\n    --prac-primary: #14328c;\n    --pat-primary: #2470c2;\n    --secondary: #f2f4f5;\n    --tertiary: #dde7f0;\n    --border-color: rgb(170 181 214);\n    --border-radius: 12px;\n    --box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;\n}\n\nbody {\n    --primary: var(--prac-primary);\n}\n\nbody.patient {\n    --primary: var(--pat-primary);\n}\n\n\n\n.bg_primary {\n    background: var(--primary);\n}\n\n.text_primary {\n    color: var(--primary);\n}\n\n.text_secondary {\n    color: var(--secondary);\n}\n\n\n.bg_secondary {\n    background: var(--secondary);\n}\n\n.bg_tertiary {\n    background: var(--tertiary);\n}\n\n$primary: var(--primary);\n$pracprimary: #14328c;\n$patprimary: #2470c2;\n$secondary: var(--secondary);\n$tertiary: var(--tertiary);\n$border-radius: var(--border-radius);\n$shadow: var(--box-shadow);\n$border-color: var(--border-color); // .btn {\n//     color: $secondary !important;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
