import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../components/Loading'
import RequestLogTable from '../../components/Patient/GDPR/RequestLogTable';
import { gdprManageDataRequest, getPatientGDPRRequestLog, getPractitionerMadeRequestStatus, setPractitionerMadeRequestStatus } from '../../store/actions/patientDashboard'
import { __logout } from "../../store/actions/auth";
import { clearCookie } from '../../methods';
import _ from 'lodash';


function ManageDataUK() {
    const [showActionPrompt, setshowActionPrompt] = useState(false);
    const [actionPromptMessage, setactionPromptMessage] = useState("");
    const [currentActionType, setCurrentActionType] = useState(null);
    const [logData, setlogData] = useState([]);
    const [gettingLogData, setGettingLogData] = useState(false);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const currentPractice = useSelector((state) => state.auth.currentPractice);
    const currentPracticeID = useSelector((state) => state.auth.currentPracticeID);
    const gdprRequest = (requestType) => dispatch(gdprManageDataRequest(token, currentPracticeID, currentPractice, requestType))
    const gdprRequestLog = () => dispatch(getPatientGDPRRequestLog(null, token, currentPracticeID, currentPractice,))

    const { t } = useTranslation();

    const gdprRequestFn = (message, actionType) => {
        setactionPromptMessage(message);
        setCurrentActionType(actionType);
        setshowActionPrompt(true)
    }


    const gdprRequestAPIFn = () => {
        gdprRequest(currentActionType).then(response => {
            setshowActionPrompt(false);
            if (!response.error) {
                console.log('response-->', response.payload)
                if (response.payload.logout) {
                    toast.success(_.startCase(currentActionType), 'is successfully.')
                    dispatch(__logout(token, currentPractice, currentPracticeID)).then(
                        (response) => {
                            clearCookie();
                            localStorage.clear();
                            window.location.href = `${window.location.origin}/users/login`;
                        }
                    );
                }
                else {
                    __gdprRequestLogTable();
                    toast.success('Request sent successfully.')
                }
            }
        })
    }

    const __gdprRequestLogTable = () => {
        gdprRequestLog().then(response => {
            if (!response.error) {
                setlogData(response.payload);
            }
        })
    }

    useEffect(() => {
        __gdprRequestLogTable();
    }, []);


    return <div className=''>
        {showActionPrompt && <ActionPrompt
            show={showActionPrompt}
            onReject={() => setshowActionPrompt(false)}
            message={actionPromptMessage}
            onAccept={gdprRequestAPIFn}
        />}

        {gettingLogData
            ? <Loading />
            : <div className="container-xxl textc-primary__p py-4 my-5">
                <Link className='text-decoration-none textc-primary__p' to="/patient/dashboard">
                    <i className="fas fa-chevron-left "></i> Dashboard
                </Link>
                <div className="row justify-content-center">
                    <div className="col-12 col-md-12 col-lg-10 col-xl-8">
                        <div className="text-center pg-heading  textc-primary__p mt-2">
                            Manage Data
                        </div>
                        <div className='textc-primary__p fw-bold my-3 mb-5'>
                            This page facilitates GDPR related data management requests. Please click on one of the options below to manage your data.
                        </div>
                        <div className="row my-3 mt-5 mt-md-3">
                            <div className="col-12 col-md-6 my-auto">
                                <button
                                    onClick={() => gdprRequestFn(
                                        'This request will inactivate your account. Do you wish to proceed?',
                                        'inactivate_account')}
                                    className='btn btn-primary__p w-100'
                                >Inactivate My Account</button>
                            </div>
                            <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                                This request will inactivate your Puregenomics account and stop further processing of any data.
                            </div>
                        </div>
                        <div className="row my-3 mt-5 mt-md-3">
                            <div className="col-12 col-md-6 my-auto">
                                <button
                                    onClick={() => gdprRequestFn(
                                        'This request will provide you a CSV file of your data. It will be machine readable but not visually formatted. You will receive an email when the report is ready. Do you wish to proceed?',
                                        'request_data')}
                                    className='btn btn-primary__p w-100'
                                >Request Puregenomics Record</button>
                            </div>
                            <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                                This will provide you with a CSV (text only) file with your Puregenomics record.
                                If you prefer a visually formatted report please download a copy of your
                                Puregenomics Report from the Dashboard.
                            </div>
                        </div>
                        <div className="row my-3 mt-5 mt-md-3">
                            <div className="col-12 col-md-6 my-auto">
                                <button
                                    onClick={() => gdprRequestFn(
                                        'This action will delete your account and all of your data in the PureGenomics system. Neither you nor your practitioner will have any access to the data once this action is complete. If you wish to retain a copy of your report please click the “Back” button and from the Dashboard click View Report and from the top right of the page Download the report. If you are ready to delete your data and account then please click the “Confirm” button.',
                                        'delete_data'
                                    )}
                                    className='btn btn-primary__p w-100'>Delete My Puregenomics Record</button>
                            </div>
                            <div className="col-12 col-md-6 fw-bold mt-2 mt-md-0">
                                This request will notify your practitioner that you are requesting deletion of your PureGenomics Record from the PureGenomics system. This request is not reversible, you will have 30 days to download your report from the Report Summary page or to request a download of your data in machine readable format. Once you confirm, after 30 days all your data and the account will be deleted. This process cannot be reversed.
                            </div>
                        </div>
                        <div className="mt-5">
                            <div className='text-center pg-heading textc-primary__p mb-4'>
                                Request Log
                            </div>
                            <RequestLogTable
                                logData={logData}
                                error={error}
                                loading={gettingLogData}
                            />
                        </div>
                    </div>
                </div>
            </div>}
    </div>;
}

export default ManageDataUK;


export const ActionPrompt = ({
    show, onAccept, onReject,
    message,
    acceptLabel = 'Confirm',
    rejectLabel = 'Go Back',
    persistant = 'static'
}) => {
    return (
        <Modal
            centered
            backdrop={persistant}
            show={show}
            onHide={onReject} bsPrefix="border-radius modal" contentClassName="border-radius" style={{ borderRadius: 8 }}>
            <div className="p-3">
                <div className="pg-text__bold">{message}</div>
                <div className="d-flex justify-content-end mt-4">
                    <button className="btn bg-sky-blue px-4 mx-1" onClick={onReject}>{rejectLabel}</button>
                    <button className="btn bgc-primary text-light px-5" onClick={onAccept}>{acceptLabel}</button>
                </div>
            </div>
        </Modal>)
}
