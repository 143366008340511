import { createAction } from "redux-api-middleware";
const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;

export const ADD_PATIENT_REQUEST = "ADD_PATIENT_REQUEST";
export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS";
export const ADD_PATIENT_FAILURE = "ADD_PATIENT_FAILURE";

export const GET_PRACTICE_SETTING_REQUEST = "GET_PRACTICE_SETTING_REQUEST"
export const GET_PRACTICE_SETTING_SUCCESS = "GET_PRACTICE_SETTING_SUCCESS"
export const GET_PRACTICE_SETTING_FAILURE = "GET_PRACTICE_SETTING_FAILURE"

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE"

export const PATIENT_INVITATION_REQUEST = "PATIENT_INVITATION_REQUEST";
export const PATIENT_INVITATION_SUCCESS = "PATIENT_INVITATION_SUCCESS";
export const PATIENT_INVITATION_FAILURE = "PATIENT_INVITATION_FAILURE";

export const BATCH_FUNCTION_REQUEST = "BATCH_FUNCTION_REQUEST";
export const BATCH_FUNCTION_SUCCESS = "BATCH_FUNCTION_SUCCESS";
export const BATCH_FUNCTION_FAILURE = "BATCH_FUNCTION_FAILURE";

export const addPatient = (token, currentPracticeID, currentPractice, data) => {

    const protocol = process.env.REACT_APP_PROTOCOL;
    const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patients`,
        body: JSON.stringify(data),
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            ADD_PATIENT_REQUEST,
            ADD_PATIENT_SUCCESS,
            ADD_PATIENT_FAILURE,
        ],
    });
};

export const getPracticeSetting = (token, currentPracticeID, currentPractice) => {

    const protocol = process.env.REACT_APP_PROTOCOL;
    const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/patients/new`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            GET_PRACTICE_SETTING_REQUEST,
            GET_PRACTICE_SETTING_SUCCESS,
            GET_PRACTICE_SETTING_FAILURE,
        ],
    });
};

export const getCountries = (token, currentPracticeID, currentPractice) => {

    const protocol = process.env.REACT_APP_PROTOCOL;
    const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;

    return createAction({
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/fetch_countries`,
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token, 'Current-Practice': currentPracticeID },
        types: [
            GET_COUNTRIES_REQUEST,
            GET_COUNTRIES_SUCCESS,
            GET_COUNTRIES_FAILURE,
        ],
    });
};

export const createPatient = (data, url) => {

    const protocol = process.env.REACT_APP_PROTOCOL;
    const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_DOMAIN;
    return createAction({
        endpoint: `${PG3_BASE_URL}/${url}`,
        body: JSON.stringify(data),
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        types: [
            PATIENT_INVITATION_REQUEST,
            PATIENT_INVITATION_SUCCESS,
            PATIENT_INVITATION_FAILURE
        ],
    });
};



export const getPatient = (token, id) => {
    return createAction({
        endpoint: `${baseUrl}/admin/practitioners/${id}`,
        method: "GET",
        headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
        types: [
            BATCH_FUNCTION_REQUEST,
            BATCH_FUNCTION_SUCCESS,
            BATCH_FUNCTION_FAILURE,
        ],
    });
};

export const editPatient = (token, id, data) => {
    return createAction({
        endpoint: `${baseUrl}/admin/practitioners/${id}`,
        body: JSON.stringify(data),
        method: "PATCH",
        headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
        types: [
            BATCH_FUNCTION_REQUEST,
            BATCH_FUNCTION_SUCCESS,
            BATCH_FUNCTION_FAILURE,
        ],
    });
};

export const resetPassword = (token, ids) => {
    const data = {
        ids
    };
    return createAction({
        endpoint: `${baseUrl}/admin/patient/reset_password`,
        body: JSON.stringify(data),
        method: "PATCH",
        headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
        types: [
            BATCH_FUNCTION_REQUEST,
            BATCH_FUNCTION_SUCCESS,
            BATCH_FUNCTION_FAILURE,
        ],
    });
};

export const resetLockout = (token, ids) => {
    const data = {
        ids
    };
    return createAction({
        endpoint: `${baseUrl}/admin/patient/reset_lockout`,
        body: JSON.stringify(data),
        method: "PATCH",
        headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
        types: [
            BATCH_FUNCTION_REQUEST,
            BATCH_FUNCTION_SUCCESS,
            BATCH_FUNCTION_FAILURE,
        ],
    });
};

export const archivePatients = (token, ids) => {
    const data = {
        ids
    };
    return createAction({
        endpoint: `${baseUrl}/admin/patient/archive`,
        body: JSON.stringify(data),
        method: "PATCH",
        headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
        types: [
            BATCH_FUNCTION_REQUEST,
            BATCH_FUNCTION_SUCCESS,
            BATCH_FUNCTION_FAILURE,
        ],
    });
};

export const restorePatients = (token, ids) => {
    const data = {
        ids
    };
    return createAction({
        endpoint: `${baseUrl}/admin/patient/restore`,
        body: JSON.stringify(data),
        method: "PATCH",
        headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
        types: [
            BATCH_FUNCTION_REQUEST,
            BATCH_FUNCTION_SUCCESS,
            BATCH_FUNCTION_FAILURE,
        ],
    });
};


export const verfiyPasswordToken = (token) => {

    return createAction({
        endpoint: baseUrl + `/registration_check`,
        method: "POST",
        body: JSON.stringify({ token }),
        headers: { "Content-Type": "application/json" },
        types: [
            BATCH_FUNCTION_REQUEST,
            BATCH_FUNCTION_SUCCESS,
            BATCH_FUNCTION_FAILURE,
        ],
    });
};
