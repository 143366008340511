import { ColorsCircle } from "../ColorsCircle";

export const FARecommendationItem = ({
    focusAreas, data
}) => {
    return (<div className="rounded p-2 p-lg-3 bg_tertiary my-2">
        <div className="row">
            <div className="col-10">
                <div>
                    <ColorsCircle focusAreas={focusAreas} data={data?.associated_focus_areas} />
                </div>
                <div dangerouslySetInnerHTML={{ __html: data?.recommendation }} />
            </div>
        </div>
    </div >);
}