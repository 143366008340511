// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-typing {
  margin-bottom: 30px;
  width: 85px;
  justify-content: space-around;
  display: flex;
  margin-left: 30px;
  position: relative;
  bottom: -65px;
}
.jump1,
.jump2,
.jump3,
.jump4,
.jump5 {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: gray;
}

.jump1 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.3s;
}

.jump2 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.4s;
}

.jump3 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.5s;
}

.jump4 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.6s;
}

.jump5 {
  animation: typing 0.7s linear infinite;
  animation-delay: 0.7s;
}

@keyframes typing {
  0% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(0px);
  }

  35% {
    transform: translateY(15px);
  }

  45% {
    transform: translateY(0px);
  }

  60% {
    transform: translateY(-15px);
  }

  75% {
    background-color: white;
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/chatTyper.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,WAAW;EACX,6BAA6B;EAC7B,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;AACf;AACA;;;;;EAKE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;EACtC,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;EACtC,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;EACtC,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;EACtC,qBAAqB;AACvB;;AAEA;EACE,sCAAsC;EACtC,qBAAqB;AACvB;;AAEA;EACE;IACE,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,4BAA4B;EAC9B;;EAEA;IACE,uBAAuB;IACvB,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;EAC5B;AACF","sourcesContent":[".is-typing {\n  margin-bottom: 30px;\n  width: 85px;\n  justify-content: space-around;\n  display: flex;\n  margin-left: 30px;\n  position: relative;\n  bottom: -65px;\n}\n.jump1,\n.jump2,\n.jump3,\n.jump4,\n.jump5 {\n  width: 10px;\n  height: 10px;\n  border-radius: 100%;\n  background-color: gray;\n}\n\n.jump1 {\n  animation: typing 0.7s linear infinite;\n  animation-delay: 0.3s;\n}\n\n.jump2 {\n  animation: typing 0.7s linear infinite;\n  animation-delay: 0.4s;\n}\n\n.jump3 {\n  animation: typing 0.7s linear infinite;\n  animation-delay: 0.5s;\n}\n\n.jump4 {\n  animation: typing 0.7s linear infinite;\n  animation-delay: 0.6s;\n}\n\n.jump5 {\n  animation: typing 0.7s linear infinite;\n  animation-delay: 0.7s;\n}\n\n@keyframes typing {\n  0% {\n    transform: translateY(0px);\n  }\n\n  25% {\n    transform: translateY(0px);\n  }\n\n  35% {\n    transform: translateY(15px);\n  }\n\n  45% {\n    transform: translateY(0px);\n  }\n\n  60% {\n    transform: translateY(-15px);\n  }\n\n  75% {\n    background-color: white;\n    transform: translateY(0px);\n  }\n\n  100% {\n    transform: translateY(0px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
