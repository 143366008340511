const HourSelect = ({ question, onInput, errors, options }) => {
    const { start, end, unit, interval } = question.options;
    const renderOptions = () => {
        const optionsArray = [];
        for (let i = start; i <= end; i += interval) {
            optionsArray.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }
        return optionsArray;
    };

    return (
        <div className="pg4-card">
            <div className="pg-heading__sub textc-primary__p">
                {question?.question_text}
            </div>
            <div className="pg-text">{question?.placeholder_en}</div>
            <div className="d-flex my-2">
                <select
                    style={{ width: "80px" }}
                    className="msq-input"
                    onChange={(e) =>
                        onInput({ [question.label]: Number(e.target.value) })
                    }
                >
                    {renderOptions()}
                </select> <span className="pg4-text my-auto ms-1">{unit}</span>
            </div>
            {errors[question.label] && (
                <div className="text-danger my-2">
                    <span>
                        <i className="fas fa-info-circle me-1"></i>
                        {errors[question.label]}
                    </span>
                </div>
            )}
        </div>
    );
}

export default HourSelect;