// MSQPage.jsx
import * as Yup from 'yup';
import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import _ from "lodash";

import ScrollToTopFAB from "../../components/ScrollToTopFAB";
import BottomNav from "../../components/Patient/PG4/BottomNav";
import MSQProgress from "../../components/Patient/PG4/MSQProgress";
import MSQSections from "../../components/Patient/PG4/MSQSections";
import MSQQuestions from "../../components/Patient/PG4/MSQQuestions";
import { getMSQSessionDetails, getMSQQuestions, postMSQAnswers, getMSQAnswers, updateSections, updateAnswers, getQuestionAndAnswer, clearMSQState, completeQHook, updateErrors, clearAnswers } from "../../store/actions/pg4dashboard";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ButtonWithLoader from '../../components/ButtonWithLoader';


const MSQuestionnaires = ({ history }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [inputValues, setInputValues] = useState({});
    const sessionNO = location?.state?.session_id;
    const questionnaireID = location?.state?.questionnaire_id;
    const [sessionDetails, setsessionDetails] = useState(null);
    const [questionsData, setQuestionsData] = useState(null);
    // const [isLoadingQuestions, setisLoadingQuestions] = useState(true);
    const isLoadingQuestions = useSelector(state => state.pg4.questionsLoading);
    const isLoadingAnswers = useSelector(state => state.pg4.answersLoading);
    const isSavingAnswers = useSelector(state => state.pg4.saveResponseLoading);
    const questions = useSelector(state => state.pg4.questions);
    const answers = useSelector(state => state.pg4.questions);


    const [errorFields, seterrorFields] = useState({});
    const [canGoNext, setcanGoNext] = useState(!false);



    // redux states
    const patientID = useSelector(state => state.auth.currentUserId);
    const token = useSelector(state => state.auth.token);
    const userGender = useSelector(state => state.auth.gender);
    const practices = useSelector((state) => state.auth.practices);
    const userCountry = practices[0]?.country
    const currentPractice = useSelector(state => state.auth.currentPractice);
    const currentSection = useSelector(state => state.pg4.q_current_section);
    const currentSectionAnswers = useSelector(state => state.pg4.q_answers);
    const currentSectionErrors = useSelector(state => state.pg4.q_errors);
    const allSection = useSelector(state => state.pg4.q_questions);

    // redux actions
    const dispatch = useDispatch();
    // const getQSessionDetails = (data) => dispatch(getMSQSessionDetails(data));
    const getQuestions = (data) => dispatch(getMSQQuestions(data));
    const postAnwers = (data) => dispatch(postMSQAnswers(data));
    const getAnswers = (data) => dispatch(getMSQAnswers(data));
    const updateAnswersAction = (data) => dispatch(updateAnswers(data));
    const updateSectionsAction = (data) => dispatch(updateSections(data));
    const updateErrorsAction = (data) => dispatch(updateErrors(data));

    const clearMSQStateAction = (data) => dispatch(clearMSQState());
    const clearAnswersAction = (data) => dispatch(clearAnswers());
    const completeQHookAction = () => dispatch(completeQHook({ token, session_id: sessionNO, currentPractice }))

    // functionality
    const _getQuestions = async () => await getQuestions({ sessionNO, patientID, questionnaireID, userGender, userCountry })
    const _getAnswer = async () => await getAnswers({ sessionNO, patientID, })
    const _getQuestionAnswer = async () => {
        _getQuestions().then(async q_response => {
            if (!q_response.error) {
                await _getAnswer();
            }
        })
    }

    useEffect(() => {
        console.log(location, 'routed state');
        _getQuestionAnswer();
    }, []);

    const handleNextSection = async () => {
        if (validate()) {
            postAllAnswers().then(async response => {
                if (!response.error) {
                    clearAnswersAction();
                    if (currentSection?.next_section_id === -1) {
                        const response = await completeQHookAction();
                        if (!response.error) {
                            if (response.payload.pg4_report_id) {
                                navigate('/patient/msq/status?report_id=' + response.payload.pg4_report_id);
                            } else {
                                navigate('/patient/dashboard', { replace: true, state: { r_id: 123 } });
                                toast.success('Questionnaire Completed!');
                            }
                        }
                        else {
                            toast.error('Something went wrong!');
                        }
                    }
                    if (currentSection?.next_section_id !== -1) {
                        updateSectionsAction(allSection[currentSection?.next_section_id]);
                        window.scroll({ top: 200 });
                    }
                }
            });

        }
    };

    const handleOnResponse = (e) => { updateAnswersAction(e) };


    const postAllAnswers = () => {
        console.log('currentSectionAnswers', { currentSectionAnswers }, _.isEmpty(currentSectionAnswers))
        if (!_.isEmpty(currentSectionAnswers)) {
            let data = {
                questionnaire_session_id: sessionNO,
                questionnaire_id: questionnaireID,
                patient_id: patientID,
                section_id: currentSection?.section_id,
                answers: Object.values(currentSectionAnswers).map(answer => {
                    return {
                        ...answer,
                        section_id: currentSection?.section_id,
                    }
                })
            }
            console.log('answer pld:', data);
            return postAnwers(data)
        } else return Promise.resolve({ error: false })
        // return Promise.reject()
    }


    const validate = (currentQuestion) => {
        let valid = true, isScrolled = false;
        const _currentSectionErrors = { ...currentSectionErrors };
        Object.keys(currentSectionErrors).forEach(question => {
            if (currentSectionAnswers?.[question]) _currentSectionErrors[question] = !true;
            else {
                console.log(document.getElementById(question));
                if (!isScrolled) {
                    document.getElementById(question)?.scrollIntoView({ behavior: 'smooth' });
                    isScrolled = true
                }
                _currentSectionErrors[question] = true;
                valid = false;
            }
        })
        console.log({ currentSectionErrors, currentSectionAnswers });
        updateErrorsAction(_currentSectionErrors);
        return valid;
    }


    useEffect(() => {
        return () => clearMSQStateAction()
    }, []);

    const onPrevClick = () => {
        if (currentSection.previous_section_id !== -1) {
            window.scroll({ top: 100 });
            updateSectionsAction(allSection[currentSection?.previous_section_id]);
        }
    }

    const onReturn = () => {
        navigate('/patient/dashboard', { replace: true });
        postAllAnswers().then(response => {
            if (response.error) {
                toast.error('Unable to save response!')
            }
            clearAnswersAction();
        })
    }

    return (<div className="container py-4" >
        <div className='d-none d-lg-block'>
            <ScrollToTopFAB />
        </div>
        <div onClick={onReturn} className="pg4-btn pg4-btn_link d-lg-none text_primary">
            <i className='fas fa-chevron-left me-2'></i>
            Return to Dashboard
        </div>
        <div className="d-flex justify-content-between flex-column flex-lg-row" id="stt-ref">
            <div className="pg-title my-3 textc-primary__p w-75">
                {location?.state?.heading || 'Multi-Symptom Questionnaire'}
            </div>
            <div className="my-auto d-none d-lg-block">
                <button className="pg4-btn pg4-btn_outline px-4"
                    disabled={isSavingAnswers || isLoadingAnswers || isLoadingQuestions || questionsData?.previous_section_id === "-1"}
                    onClick={onReturn}>Return to Dashboard</button>
            </div>
        </div>
        {location?.state?.card_type === "questionnaire_msq" && <MSQProgress value={questionsData?.section_progress} />}
        <div className="msq-container my-4" >
            <div className="row">
                <div className="col d-none d-lg-block">
                    <MSQSections
                        sections={sessionDetails}
                        isLoading={isLoadingAnswers}
                        questionsData={questionsData}
                    />

                </div>
                <div className="col-12 col-lg-8 col-xl-9">
                    <MSQQuestions
                        questionsData={questionsData}
                        onInput={handleOnResponse}
                        inputValues={inputValues}
                        isLoading={isLoadingAnswers || isLoadingQuestions}
                    />
                </div>
                <div className="col-12 col-lg-4 col-xl-3" />
                <div className="col-12 col-lg-8 col-xl-9 my-3 d-none d-lg-block" >
                    <div className='d-flex justify-content-end'>
                         {/*{(questionsData?.previous_section_id !== "-1") && <button
                            className="btn btn-primary__p px-5"
                            disabled={isLoadingQuestions || isLoadingAnswers || currentSection?.previous_section_id === -1}
                            onClick={onPrevClick}
                        >Previous Section</button>} */}
                        <ButtonWithLoader
                            spinnerClass="text_secondary"
                            loading={isSavingAnswers} className="btn btn-primary__p px-5"
                            onClick={handleNextSection}
                            disabled={isSavingAnswers || isLoadingQuestions || isLoadingAnswers || !canGoNext}
                        >{isSavingAnswers ? 'Saving...' : (currentSection?.next_section_id !== -1 ? 'Next Section' : 'Complete')}
                        </ButtonWithLoader>
                    </div>
                </div>
            </div>
        </div>
        <div className="d-lg-none">
            <BottomNav
                onNext={handleNextSection}
            // onPrev={onPrevClick}
            />
        </div>
    </div >);
}

export default MSQuestionnaires;





const extractQuestionId = (questions) => {
    const questionIds = [];

    function extractIds(ques) {
        if (ques.question_id && !ques.question_id.startsWith('GRP_')) {
            questionIds.push(ques.question_id);
        }

        if (ques.sub_questions) {
            ques.sub_questions?.forEach(subQuestion => extractIds(subQuestion));
        }
    }

    questions?.forEach(question => extractIds(question));

    return questionIds;
}



/**
 * 
 * const onPrevClick = () => {
        if (currentSection.previous_section_id !== -1) {
            window.scroll({ top: 100 });
            updateSectionsAction(allSection[currentSection?.previous_section_id]);
            loadErrorFields();
        }
    }


    const getValidationSchemaInstance = (config) => {
    let fieldConfig = {};

    return Yup.object().shape({
        // ...fieldConfig
        height: Yup.number().required('This is required field'),
        weight: Yup.number().required('This is required field')
    });
}
 */