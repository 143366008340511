import React from "react";
import { connect } from "react-redux";
import FilePicker from "../../Patient/FilePicker";

const AfterSNPFilesSelected = ({
  snpData,
  removeSelectedFile,
  onStartUpload,
  addAnotherFile,
  t,
  language
}) => {
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6 col-lg-4 py-5">
        <div className="pg-heading">
          {t('patient_afsnp_txt1')}
        </div>
        <div className="pg-text my-5">
          <hr />
          <div className="d-flex justify-content-between my-3 px-1">
            <div className="pg-text">
              <i className="fas fa-file-alt me-3 text-secondary" />
              {snpData.file.name}
            </div>
            <i
              className="fa fa-times-circle text-secondary my-auto"
              aria-hidden="true"
              onClick={() => removeSelectedFile()}
            ></i>
          </div>
          <hr />
        </div>
        <div className="my-5">
          <FilePicker
            onChange={addAnotherFile}
            label="Choose Another File..."
            type={2}
            acceptFiles={language == "en_gb"? ".txt,.vcf,.zip" : ".txt,.zip"}
          />
          <span style={onlyAccepts}>
             {t('patient_afsnp_txt2')}
          </span>
        </div>
        <div className="my-4">
          <button
            className="btn btn-primary w-100"
            onClick={onStartUpload}
          >
            {t('pracpl_btn_7')}
          </button>
        </div>
      </div>
    </div>
  );
};


export default AfterSNPFilesSelected;

const onlyAccepts = {
  marginTop: 30,
  color: "#888888",
  fontFamily: "Mulish",
  fontSize: 12,
  fontWeight: 400,
  lineHeight: "24px",
  textAlign: "left",
};
