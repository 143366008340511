import React from "react";
import { useTranslation } from "react-i18next";

export default function Questionnaire() {
  const { t, i18n } = useTranslation();
  return (
    <div className="pq-card p-4 px-5" style={{ backgroundColor: '#f6f6f6 !important', background: '#f6f6f6 !important', height: '390 !important' }}>
      <div className="pd-pg-heading text-center">{ t('questionnaire') }</div>
      <div className=" justify-content-between">
        <span dangerouslySetInnerHTML={{ __html: t('patient_ques1') }} />
      </div>
    </div>
  );
}
