import { useEffect, useState } from "react";

const DateInput = ({ question, onInput, inputValues }) => {
    const [dob, setdob] = useState({ date: null, month: null, year: null });
    const [error, seterror] = useState("");

    useEffect(() => {
        console.log(dob);
        seterror("");
        let year = new Date().getFullYear();
        if (dob.year && (dob.year > year || dob.year < 1900)) seterror("Enter a valid year!")
        if (!error && dob.date && dob.month && dob.year) {
            onInput(prevValues => ({ ...prevValues, [question.type]: `${dob.date}-${dob.month}-${dob.year}` }))
        }
    }, [dob]);
    return (<div className="pg4-card">
        <div className="pg4-text__subheading textc-primary__p">
            Please share your date of birth
        </div>
        <div className="d-flex justify-content-start mt-3">
            <input
                min={1}
                max={31}
                type="number"
                placeholder="DD"
                value={dob.date}
                style={{ width: 100, borderRadius: 8, border: '2px solid var(--pg_primary__p)' }}
                className="me-2 p-1"
                onInput={(e) => {
                    let value = e.target.value;
                    if ((value > 0 && value < 32) || value === "")
                        setdob({ date: e.target.value })
                    else seterror("Enter valid Date")
                }}
            />
            <input
                min={1}
                max={12}
                type="number"
                placeholder="MM"
                style={{ width: 100, borderRadius: 8, border: '2px solid var(--pg_primary__p)' }}
                className="me-2 p-1"
                value={dob.month}
                onInput={(e) => {
                    let value = e.target.value;
                    if ((value > 0 && value < 13) || value === "")
                        setdob({ month: e.target.value })
                    else seterror("Enter valid Month")
                }}
            />
            <input
                min={1900}
                max={2023}
                type="number"
                placeholder="YYYY"
                value={dob.year}
                style={{ width: 100, borderRadius: 8, border: '2px solid var(--pg_primary__p)' }}
                className="me-2 p-1"
                onInput={(e) => setdob({ year: e.target.value })}
            />
        </div>
        {error && <div className="text-danger my-2">
            <span>
                <i className="fas fa-info-circle me-1"></i>
                {error}
            </span>
        </div>}
    </div>);
}

export default DateInput;