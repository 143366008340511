import _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";

const RadioSelection = ({ question, onInput }) => {
    const answers = useSelector(state => state.pg4.q_answers);
    const errors = useSelector(state => state.pg4.q_errors);

    return (<div className="pg4-card" id={question.question_id}>
        <div className="pg-heading__sub textc-primary__p">
            {question?.question_text}
        </div>
        <div className="pg-text">
            {question?.placeholder}
        </div>

        <div className="d-flex mt-3 flex-wrap">
            {_.isArray(question?.choices) && question?.choices.map((choice, index) => (
                <div
                    key={choice + index}
                    className={`cursor-pointer me-2 my-2 msq-radio ${choice == answers?.[String(question.question_id)]?.answer[0] ? 'msq-radio__selected' : ''}`}
                    onClick={() => {
                        onInput({
                            [question.question_id]: {
                                question_id: question.question_id,
                                answer: [choice]
                            }
                        });
                    }}
                >
                    {choice}
                </div>
            ))}
        </div>
        <div className="text-danger transition-all" style={{ opacity: errors[question?.question_id] ? 1 : 0 }}>
            <i className="fas fa-info-circle"></i> This field is required
        </div>
    </div>);
}

export default RadioSelection;